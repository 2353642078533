import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { getBasename } from '../App';
import { MainLayout } from '../components/Layout';
import PageSpinner from '../components/PageSpinner';
import withLocale from "../hocs/withLocale";
import { IApplicationState } from '../store/rootReducer';
import { userFetchFail, userFetchStart, userFetchSuccess } from "../store/user/actions";
import { IUserData, IUserRequestFail, IUserRequestStart, IUserRequestSuccess, IUserState } from '../store/user/types';
import { ROUTES } from './routes';
import { i18n } from '../locales';
import PrefixedRoute from './PrefixedRoute';
import { translatedRoute } from '../utils/routing';
import NotFoundPage from '../pages/NotFoundPage';

const LoginPage = React.lazy(() => import('../pages/Login'));
const UserProfilePage = React.lazy(() => import('../pages/UserProfilePage'));
const MySettingsPage = React.lazy(() => import('../pages/MySettingsPage'));
const CreatePick = React.lazy(() => import('../pages/CreatePickPage'));
const UserConfirmSubscriptionPage = React.lazy(() => import('../pages/UserConfirmSubscriptionPage'));
const UserPasswordResetChangePasswordPage = React.lazy(() => import('../pages/UserPasswordResetChangePasswordPage'));
const UserVerifyPage = React.lazy(() => import('../pages/UserVerifyPage'));
const UserVerifyNewEmailPage = React.lazy(() => import('../pages/UserVerifyNewEmailPage'));
const PicksAllPage = React.lazy(() => import('../pages/PicksAllPage'));
const PickShowPage = React.lazy(() => import('../pages/PickShowPage'));
const PickEditPage = React.lazy(() => import('../pages/PickEditPage'));
const OperatorsBonusesPage = React.lazy(() => import('../pages/OperatorsBonusesPage'));
const MatchPage = React.lazy(() => import('../pages/MatchPage'));
const MatchcenterPage = React.lazy(() => import('../pages/MatchcenterPage'));
const RedirectOperatorPage = React.lazy(() => import('../pages/RedirectOperatorPage'));
const CampaignsPage = React.lazy(() => import('../pages/CampaignsPage'));
const CampaignPage = React.lazy(() => import('../pages/CampaignPage'));
const ArticlesPage = React.lazy(() => import('../pages/ArticlesPage'));
const ArticlePage = React.lazy(() => import('../pages/ArticlePage'));
const PageShowPage = React.lazy(() => import('../pages/PageShowPage'));
const OperatorReviewPage = React.lazy(() => import('../pages/OperatorReviewPage'));

const MainPage = React.lazy(() => import('../pages/Main'));

interface IProps {
  breakpoint?: string;
  user: IUserState;
}

interface IDispatchProps {
  loginSuccess: typeof userFetchSuccess;
  loginStart: typeof userFetchStart;
  loginFail: typeof userFetchFail;
}

type Props = IProps & IDispatchProps;

class RootContainer extends React.Component<Props> {
  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <Switch>
          <PrefixedRoute>
            <React.Suspense fallback={<PageSpinner />}>
              <Switch>
                {translatedRoute({ pathKey: ROUTES.GENERAL_REDIRECT_OPERATOR, component: RedirectOperatorPage })}
                <Route>
                  <MainLayout>
                    <Switch>
                      <Route exact path={`/${i18n.language}${ROUTES.GENERAL_ORIGIN}`} component={MainPage} />
                      {translatedRoute({ pathKey: ROUTES.GENERAL_LOGIN, component: LoginPage })}

                      {translatedRoute({ pathKey: ROUTES.GENERAL_USER_SETTINGS, authRequired: true, component: MySettingsPage })}
                      {translatedRoute({ pathKey: ROUTES.GENERAL_USER_PROFILE, component: UserProfilePage })}
                      {translatedRoute({ pathKey: ROUTES.GENERAL_BONUSES, component: OperatorsBonusesPage })}
                      {translatedRoute({ pathKey: ROUTES.GENERAL_CAMPAIGNS, component: CampaignsPage })}
                      {translatedRoute({ pathKey: ROUTES.GENERAL_CAMPAIGN, component: CampaignPage })}
                      {translatedRoute({ pathKey: ROUTES.GENERAL_USER_CONFIRM_SUBSCRIPTION, component: UserConfirmSubscriptionPage })}
                      {translatedRoute({ pathKey: ROUTES.GENERAL_ARTICLES, component: ArticlesPage })}
                      {translatedRoute({ pathKey: ROUTES.GENERAL_ARTICLE, component: ArticlePage })}
                      {translatedRoute({ pathKey: ROUTES.GENERAL_OPERATOR_REVIEW, component: OperatorReviewPage })}
                      {translatedRoute({ pathKey: ROUTES.GENERAL_USER_PASSWORD_RESET_CHANGE_PASSWORD, component: UserPasswordResetChangePasswordPage })}
                      {translatedRoute({ pathKey: ROUTES.GENERAL_USER_VERIFY, component: UserVerifyPage })}
                      {translatedRoute({ pathKey: ROUTES.GENERAL_USER_VERIFY_NEW_EMAIL, component: UserVerifyNewEmailPage })}
                      {translatedRoute({ pathKey: ROUTES.GENERAL_PICKS_ALL, component: PicksAllPage })}
                      {translatedRoute({ pathKey: ROUTES.GENERAL_PICK_SHOW, component: PickShowPage })}
                      {translatedRoute({ pathKey: ROUTES.GENERAL_MATCHCENTER_EVENT, component: MatchPage })}
                      {translatedRoute({ pathKey: ROUTES.GENERAL_PICK_EDIT, authRequired: true, component: PickEditPage })}
                      {translatedRoute({ pathKey: ROUTES.CREATE_PICK, authRequired: true, component: CreatePick })}
                      {translatedRoute({ pathKey: ROUTES.GENERAL_MATCHCENTER, component: MatchcenterPage })}

                      <Route exact path={`/${i18n.language}/:slug`} component={PageShowPage} />
                      <Route component={NotFoundPage} />
                    </Switch>
                  </MainLayout>
                </Route>
              </Switch>
            </React.Suspense>
          </PrefixedRoute>
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  const { user } = state;
  return {
    user,
  };
};

const mapDispatchToProps = (dispatch: (arg0: IUserRequestSuccess | IUserRequestStart | IUserRequestFail) => any) => ({
  loginSuccess: (userData: IUserData) => dispatch(userFetchSuccess(userData)),
  loginStart: () => dispatch(userFetchStart()),
  loginFail: () => dispatch(userFetchFail()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocale(RootContainer));
