import { setupI18n } from '@lingui/core';

// all locales
import * as enMessages from './en/messages.js';
import * as daMessages from './da/messages.js';
import * as fiMessages from './fi/messages.js';
import * as svMessages from './sv/messages.js';

import AppConfig from '../config/AppConfig';
import Cookies from 'js-cookie';

// Register datepicker locales.
import { registerLocale } from  'react-datepicker';
import {
  enGB as enDateFns,
  da as daDateFns,
  fi as fiDateFns,
  sv as svDateFns,
} from 'date-fns/locale';
registerLocale('en', enDateFns);
registerLocale('da', daDateFns);
registerLocale('fi', fiDateFns);
registerLocale('sv', svDateFns);

// constant for using outside of React components
export const i18n = setupI18n({
  language: AppConfig.defaultLanguage, //TODO: change this line with GeoLocation based language
  catalogs: {
    en: enMessages,
    da: daMessages,
    fi: fiMessages,
    sv: svMessages,
  },
});

export const setLocale = (locale: string, setLanguageFunctionCallback: (locale: any) => void) => {
  i18n.activate(locale);
  localStorage.setItem('userLocale', locale);
  Cookies.set('userLocale', locale)
  setLanguageFunctionCallback(locale);
}
