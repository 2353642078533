import API from '../api';
import { IUserData } from '../store/user/types';
import { call, put, select } from 'redux-saga/effects';
import { ApiResponse } from 'apisauce';
import { userFetchFail, userFetchSuccess, userLogoutSuccess } from '../store/user/actions';
import { closeAllMenus, setLanguage } from '../store/clientSettings/actions';
import toastr from 'toastr';
import { i18n } from '../locales';
import { sendFCMtoken } from './firebaseCM';
import { setLocale } from '../locales';
import { getLanguages } from '../store/clientSettings/selectors';
import { ILanguage } from '../store/clientSettings/types';

export function* getUserData(api: typeof API) {

  let userInfoResult: ApiResponse<{ user?: IUserData, permKeys: string[] }> = yield call(api.getUserInfo);
  if (userInfoResult.ok && userInfoResult.data.user) {
    const { user, permKeys } = userInfoResult.data;
    yield put(userFetchSuccess(user, permKeys));

    const languages: ILanguage[] = yield select(getLanguages);
    let userLanguage = languages.filter(l => l.id === user.languageId)[0];
    if (userLanguage && i18n.language !== userLanguage.iso) {
      setLocale(userLanguage.iso, () => { });
      yield put(setLanguage(userLanguage.iso));
    }

    yield call(sendFCMtoken, api);
  } else {
    yield put(userFetchFail());
  }
}

export function* logout(api: typeof API) {
  let resp: ApiResponse<any> = yield call(api.logout);

  if (resp.ok) {
    yield put(closeAllMenus());
    yield put(userLogoutSuccess());
  } else {
    toastr.error(i18n._('Failed to perform the action'));
  }
}

export function* initUserLanguage() {
  let initLocale = localStorage.getItem('userLocale');
  if (initLocale) {
    setLocale(initLocale, () => { });
    yield put(setLanguage(initLocale));
  }
}