export default {
  API_ACCOUNT_LOGIN: '/api/user/login',
  API_ACCOUNT_LOGOUT: '/api/user/logout',
  API_ADMIN_PICKS: '/api/admin/picks',
  API_ARTICLE: '/api/article',
  API_ARTICLES: '/api/articles',
  API_BETVALUE: '/api/picks/betvalue',
  API_CAMPAIGN: '/api/campaign',
  API_CAMPAIGNS: '/api/campaigns',
  API_COMPETITIONS_OPTIONS: '/api/competitions/options',
  API_COUNTRIES_DATA: '/api/countries',
  API_CSRF_COOKIE: '/api/csrf-cookie',
  API_EVENT_OPTIONS: '/api/events/options',
  API_MAIN_PAGE: '/api/main-page',
  API_MARKETS: '/api/markets',
  API_MARKET_COMPETITORS: '/api/market-competitors',
  API_MASTER_PAGE_DATA: '/api/masterpage',
  API_MATCH_VOTE: '/api/matchcenter/match/vote',
  API_MATCHCENTER_COMPETITION_EVENTS: '/api/matchcenter/competition-events',
  API_MATCHCENTER_COMPETITIONS: '/api/matchcenter/competitions',
  API_MATCHCENTER_EVENT: '/api/matchcenter/event',
  API_MATCHCENTER_FIXTURES_EVENTS: '/api/matchcenter/fixtures-events',
  API_MATCHCENTER_LINEUPS: '/api/matchcenter/lineups',
  API_MACTHCENTER_MATCH_BETTING_PICKS_TAB: '/api/matchcenter/match/betting-picks',
  API_MATCHCENTER_MATCH_COMMENTARIES: '/api/matchcenter/commentaries',
  API_MATCHCENTER_MATCH_DATA: '/api/matchcenter/match-data',
  API_MATCHCENTER_PREV_MEETINGS: '/api/matchcenter/prev-meetings',
  API_MATCHCENTER_STATISTICS: '/api/matchcenter/statistics',
  API_MATCHCENTER_TEAMNEWS: '/api/matchcenter/team-news',
  API_MATCHCENTER_TRENDS: '/api/matchcenter/trends',
  API_ODDS: '/api/odds',
  API_OPERATOR_REVIEW: '/api/operator/review',
  API_OPERATORS_BONUSES: '/api/operators/bonuses',
  API_OPERATORS_OPTIONS: '/api/operators/options',
  API_OPERATORS_REDIRECT_OPERATOR_DATA: '/api/operators/redirect-operator-data',
  API_PAGE: '/api/page',
  API_PICK_EDIT: '/api/pick/edit',
  API_PICK_SHOW: '/api/pick/show',
  API_PICKS: '/api/picks',
  API_PICKS_ALL_PAGE: '/api/picks/all-page',
  API_POSTS: '/api/posts',
  API_POSTS_UPLOAD_IMAGE: '/api/posts/upload-image',
  API_REGIONS_OPTIONS: '/api/regions/options',
  API_SEARCH: '/api/search',
  API_SPORTS_GET_LINKED: '/api/sports/get-linked',
  API_SPORTS_OPTIONS: '/api/sports/options',
  API_STANDINGS_RELATED: '/api/standings/related-stats',
  API_THREADS: '/api/threads',
  API_THREADS_CHANGE_FOLLOW: '/api/threads/change-follow',
  API_THREADS_FOLLOW_STATUS: '/api/threads/follow-status',
  API_USER_CHANGE_PASSWORD: '/api/user/change-password',
  API_USER_DELETE_AVATAR: '/api/user/delete-avatar',
  API_USER_FCM_TOKEN: '/api/user/fcm-token',
  API_USER_FOLLOW: '/api/user/follow',
  API_USER_INFO: '/api/user/info',
  API_USER_LINK_SOCIAL: '/api/user/link-social',
  API_USER_MY_OPERATORS: '/api/user/my-operators',
  API_USER_NEWSLETTER_SUBSCRIBE: '/api/user/newsletter-subscribe',
  API_USER_NEWSLETTER_SUBSCRIBE_CONFIRM: '/api/user/newsletter-subscribe-confirm',
  API_USER_NEWSLETTER_SUBSCRIBE_REQUEST: '/api/user/newsletter-subscribe-request',
  API_USER_NOTIFICATION_SETTINGS: '/api/user/notification-settings',
  API_USER_PASSWORD_RESET_CHANGE_PASSWORD: '/api/user/password-reset/change-password',
  API_USER_PASSWORD_RESET_REQUEST: '/api/user/password-reset/request',
  API_USER_PICK_STATS: '/api/user/pick-statistics',
  API_USER_PROFILE: '/api/user/profile',
  API_USER_REGISTER: '/api/user/register',
  API_USER_REQUEST_NEW_EMAIL: '/api/user/request-new-email',
  API_USER_SETTINGS: '/api/user/settings',
  API_USER_SOCIAL_LOGIN: '/api/user/social-login',
  API_USER_UPLOAD_AVATAR: '/api/user/upload-avatar',
  API_USER_USER_PROFILE: '/api/user/user-profile',
  API_USER_VERIFY: '/api/user/verify',
  API_USER_VERIFY_NEW_EMAIL: '/api/user/verify-new-email',
  API_USERS_OPTIONS: '/api/users/options',
  API_WEB_NOTIFICATIONS: '/api/web-notifications',
  API_WEB_NOTIFICATIONS_NUM_OF_UNREAD: '/api/web-notifications/num-of-unread',
  API_WEB_NOTIFICATIONS_RESET_UNREAD: '/api/web-notifications/reset-unread',

  // Sports.
  FOOTBALL_ID: 1,
  HANDBALL_ID: 2,
  TENNIS_ID: 3,
  ICE_HOCKEY_ID: 4,
  HOCKEY_ID: 24,
  BASKETBALL_ID: 5,
  BANDY_ID: 14,
  AM_FOOTBALL_ID: 7,
  RUGBY_ID: 12,
  CYCLING_ID: 8,
  BASEBALL_ID: 6,
  ESPORTS_ID: 67,
  GOLF_ID: 9,
  DARTS_ID: 50,
  FUTSAL_ID: 51,
  CRICKET_ID: 46,
  BOXING_ID: 19,

  // Market types.
  MT_1X2_ID: 1,  // 1X2
  MT_OU_ID: 2,  // Over/Under
  MT_AH_ID: 3,  // Asian Handicap
  MT_EH_ID: 4,  // Euro Handicap
  MT_DC_ID: 5,  // Double Chance
  MT_DNB_ID: 6,  // Draw No Bet
  MT_BTS_ID: 7,  // Both Teams to Score
  MT_HT_FT_ID: 8,  // Half Time/Full Time
  MT_O_E_ID: 9,  // Odd/Even
  MT_CS_ID: 10,  // Correct Score
  MT_ANY_ID: 11,  // Anytime Goalscorer
  MT_1ST_ID: 12,  // First Goalscorer
  MT_LAST_ID: 13,  // Last Goalscorer
  MT_12_ID: 14,  // 12
  MT_HNB_ID: 38,  // Home No Bet
  MT_ANB_ID: 39,  // Away No Bet
  MARKET_TYPES_WITH_LINES: [2, 3, 4],
  MARKET_TYPES_WITH_PLAYER: [11, 12, 13],

  // Outcomes.
  OUTCOME_1: '1',
  OUTCOME_X: 'X',
  OUTCOME_2: '2',
  OUTCOME_OVER: 'Over',
  OUTCOME_UNDER: 'Under',
  OUTCOME_EXACT: 'Exact',
  OUTCOME_ODD: 'Odd',
  OUTCOME_EVEN: 'Even',
  OUTCOME_1X: '1X',
  OUTCOME_X2: 'X2',
  OUTCOME_12: '12',
  OUTCOME_YES: 'Yes',
  OUTCOME_NO: 'No',
  OUTCOME_1_X: '1/X',
  OUTCOME_2_X: '2/X',
  OUTCOME_X_1: 'X/1',
  OUTCOME_X_2: 'X/2',
  OUTCOME_X_X: 'X/X',
  OUTCOME_1_1: '1/1',
  OUTCOME_1_2: '1/2',
  OUTCOME_2_1: '2/1',
  OUTCOME_2_2: '2/2',

  // Periods.
  P_FT_ID: 1,  // Full Time
  P_1H_ID: 2,
  P_2H_ID: 3,
  P_1P_ID: 5,
  P_2P_ID: 6,
  P_3P_ID: 7,
  P_1Q_ID: 8,
  P_2Q_ID: 9,
  P_3Q_ID: 10,
  P_4Q_ID: 11,
  P_1S_ID: 12,
  P_2S_ID: 13,
  P_3S_ID: 14,
  P_4S_ID: 15,
  P_5S_ID: 16,
  P_FE_ID: 120,  // Full Event

  ODDS_FORMAT_DECIMAL: 'decimal',
  ODDS_FORMAT_FRACTIONAL: 'fractional',
  WEATHER_FORECAST_CELSIUS: 'celsius',
  WEATHER_FORECAST_FAHRENHEIT: 'fahrenheit',

  AVATAR_CROP_SIZE: 128,

  // Date Formats
  serverDateFormat: 'YYYY-MM-DD',
  serverDateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
  displayDateFormat: 'DD/MM-YYYY',
  displayDateHeadingFormat: 'dddd, MMMM D, YYYY',  // e.g. 'Monday, July 3, 2020'
  displayDateTimeFormat: 'DD/MM-YYYY HH:mm',
  displayTimeFormat: 'HH:mm',
  datetimeFieldDateTimeFormat: 'YYYY-MM-DDTHH:mm',
  datetimeWithWeekDay3LettersFormat: 'ddd D, YYYY HH:mm',
  datetimeWithMonth3LettersFormat: 'D MMM, YYYY HH:mm',
  displayMonthAndYearFormat: 'MMM YYYY',
  displayDayMonthYearWithSlashes: 'DD/MM/YYYY'
}

export enum Sports {
  Football = 1,
  Handball = 2,
  Tennis = 3,
  IceHockey = 4,
  Basketball = 5,
  Baseball = 6,
  AmericanFootball = 7,
  Cycling = 8,
  Golf = 9,
  Athletics = 10,
  Motorsports = 11,
  Rugby = 12,
  Floorball = 13,
  Bandy = 14,
  BeachVolley = 15,
  HorseRacing = 16,
  Archery = 17,
  Badminton = 18,
  Boxing = 19,
  Canoeing = 20,
  Diving = 21,
  Equestrian = 22,
  Fencing = 23,
  Hockey = 24,
  Gymnastics = 25,
  Judo = 26,
  ModernPentathlon = 27,
  Rowing = 28,
  Sailing = 29,
  Shooting = 30,
  Swimming = 31,
  SynchronisedSwimming = 32,
  TableTennis = 33,
  Taekwondo = 34,
  Triathlon = 35,
  Volleyball = 36,
  WaterPolo = 37,
  WeightLifting = 38,
  Wrestling = 39,
  TrackCycling = 40,
  MountainBike = 41,
  Softball = 42,
  BMX = 43,
  Snooker = 45,
  Cricket = 46,
  AustralianFootball = 47,
  MixedMartialArts = 48,
  Speedway = 49,
  Darts = 50,
  Futsal = 51,
  Alpine = 52,
  Biathlon = 53,
  Bobsleigh = 54,
  CrossCountrySkiing = 55,
  Curling = 56,
  FigureSkating = 57,
  FreestyleSkiing = 58,
  Luge = 59,
  NordicCombined = 60,
  ShortTrackSpeedSkating = 61,
  Skeleton = 62,
  SkiJumping = 63,
  Snowboard = 64,
  SpeedSkating = 65,
  ReferenceSportEnetpulse = 66,
  eSports = 67,
  Entertainment = 68,
  Politics = 69,
  Chess = 70,
  Trotting = 71,
  Beachsoccer = 72,
  Motocross = 73,
  Olympics = 74,
  Squash = 75,
  CycleCross = 76,
  Kickboxing = 77,
  CounterStrike = 78,
  Dota2 = 79,
  Fighting = 80,
  Overwatch = 82,
}

export enum LinkArea {
  MC = 'mc',
  TV_GUIDE = 'tv_guide',
}

export enum PickType {
  TYPE_1X2 = '1X2',
  TYPE_OU = 'OU',
  TYPE_AH = 'AH',
  TYPE_EH = 'EH',
  TYPE_DC = 'DC',
  TYPE_DNB = 'DNB',
  TYPE_BTS = 'BTS',
  TYPE_HT_FT = 'HT/FT',
  TYPE_OE = 'O/E',
  TYPE_CS = 'CS',
  TYPE_E_W = 'E/W',
  TYPE_ANY = 'ANY',
  TYPE_1ST = '1ST',
  TYPE_LAST = 'LAST',
  TYPE_12 = '12',
  TYPE_OTHER = 'Other',
  TYPE_INFO = 'Info',
  TYPE_HNB = 'HNB',
  TYPE_ANB = 'ANB',
}

export enum Permissions {
  MESSAGES_MANAGE = 'messages_manage',
  PICKS_MANAGE = 'picks_manage',
}
