import API from '../api';
import { call, put } from 'redux-saga/effects';
import { ApiResponse } from 'apisauce';
import { masterDataFail, masterDataSuccess } from "../store/clientSettings/actions";
import { IFooterItem, ISocialLink, ILanguage } from "../store/clientSettings/types";

export function* getMasterData(api: typeof API) {

  let masterPageResponse: ApiResponse<{ languageId: number, countryId: number, footerItems: IFooterItem, socialLinks: ISocialLink, languages: ILanguage, gamblingAddictionLink: string | null }> = yield call(api.getMasterPageData);

  if (masterPageResponse.ok) {
    yield put(masterDataSuccess(
      masterPageResponse.data.languageId,
      masterPageResponse.data.countryId,
      masterPageResponse.data.footerItems,
      masterPageResponse.data.socialLinks,
      masterPageResponse.data.languages,
      masterPageResponse.data.gamblingAddictionLink
    ))
  } else {
    yield put(masterDataFail());
  }
}
