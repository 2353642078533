import React from 'react';
import classnames from 'classnames';
import { OperatorLogoType, getOperatorLogoSrc } from '../utils/operator';
import { IOperator } from "../utils/types";
import RedirectOperatorLink from './RedirectOperatorLink';

interface IProps {
  className?: string;
  operator: IOperator;
  logoType?: OperatorLogoType,
  onClick?: () => void;
  noLink?: boolean;
  imageClassName?: string,
}

const OperatorLogo = (props: IProps) => {
  const { operator, onClick, noLink, imageClassName } = props;
  if (!operator || !operator.name) {
    return null;
  }

  const bgColor = operator.bgColor !== null ? operator.bgColor : '#fff';

  const src = getOperatorLogoSrc(operator, props.logoType);

  const className = classnames([
    'text-center overflow-hidden',
    onClick && 'can-click',
    props.className,
  ]);
  const style = {backgroundColor: bgColor};
  const inner = src ? <img className={imageClassName ?? "d-block h-100 mx-auto"} src={src} alt={operator.name} /> : operator.name;

  if (!noLink && !onClick && operator.active) {
    return (
      <RedirectOperatorLink className={className} operator={operator} style={style}>
        {inner}
      </RedirectOperatorLink>
    );
  }

  return (
    <span className={className} title={operator.name} onClick={onClick} style={style}>
      {inner}
    </span>
  );
};

export default OperatorLogo;
