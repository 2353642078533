import Football from './Football.svg';
import Handball from './Handball.svg';
import Alpine from './Skiing.svg';
import Tennis from './Tennis.svg';
import IceHockey from './Icehockey.svg';
import Basketball from './Basketball.svg';
import Cycling from './Cycling.svg';
import Golf from './Golf.svg';
import Athletics from './Athletics.svg';
import Motorsports from './Motorsport.svg';
import Rugby from './Rugby.svg';
import Floorball from './Floorball.svg';
import Bandy from './Bandy.svg';
import BeachVolley from './Beach Volleyball.svg';
import HorseRacing from './Trotting.svg';
import Badminton from './Badminton.svg';
import Boxing from './Boxing.svg';
import Diving from './Swimming.svg';
import Hockey from './Icehockey.svg';
import Gymnastics from './Athletics.svg';
import Sailing from './Sailing.svg';
import Swimming from './Swimming.svg';
import SynchronisedSwimming from './Swimming.svg';
import TableTennis from './TableTennis.svg';
import Volleyball from './Volleyball.svg';
import WaterPolo from './Waterpolo.svg';
import Wrestling from './MMA.svg';
import TrackCycling from './Cycling.svg';
import MountainBike from './Cycling.svg';
import BMX from './Cycling.svg';
import Snooker from './Snooker.svg';
import Cricket from './Cricket.svg';
import AustralianFootball from './Australian football.svg';
import AmericanFootball from './American football.svg';
import Darts from './Dart.svg';
import Futsal from './Futsal.svg';
import Biathlon from './Skiing.svg';
import CrossCountrySkiing from './Cross country skiing.svg';
import Curling from './Curling.svg';
import FreestyleSkiing from './Skiing.svg';
import SkiJumping from './Skiing.svg';
import Entertainment from './Entertainment.svg';
import Politics from './Politics.svg';
import Chess from './Chess.svg';
import Trotting from './Trotting.svg';
import Beachsoccer from './Football.svg';
import Motocross from './Motorsport.svg';
import Olympics from './Olympics.svg';
import Squash from './Squash.svg';
import CycleCross from './Cycling.svg';
import Kickboxing from './MMA.svg';
import Fighting from './MMA.svg';

export default {
  Football,
  Handball,
  Alpine,
  Tennis,
  IceHockey,
  Basketball,
  Cycling,
  AmericanFootball,
  Golf,
  Athletics,
  Motorsports,
  Rugby,
  Floorball,
  Bandy,
  BeachVolley,
  HorseRacing,
  Badminton,
  Boxing,
  Diving,
  Hockey,
  Gymnastics,
  Sailing,
  Swimming,
  SynchronisedSwimming,
  TableTennis,
  Volleyball,
  WaterPolo,
  Wrestling,
  TrackCycling,
  MountainBike,
  BMX,
  Snooker,
  Cricket,
  AustralianFootball,
  Darts,
  Futsal,
  Biathlon,
  CrossCountrySkiing,
  Curling,
  FreestyleSkiing,
  SkiJumping,
  Entertainment,
  Politics,
  Chess,
  Trotting,
  Beachsoccer,
  Motocross,
  Olympics,
  Squash,
  CycleCross,
  Kickboxing,
  Fighting,
}