import { ApiResponse } from 'apisauce';
import { call, put, select } from 'redux-saga/effects';
import API from '../api';
import { countriesDataSuccess } from "../store/clientSettings/actions";
import { ICountryData } from "../store/clientSettings/types";
import { getCountries } from '../store/clientSettings/selectors';
import { isEmptyArray } from 'formik';

export function* getCountriesData(api: typeof API) {
  let countries: ICountryData[] = yield select(getCountries);

  if (isEmptyArray(countries)) {

    let countriesDataResponse: ApiResponse<ICountryData[]> = yield call(api.getCountriesData);

    if (countriesDataResponse.ok) {
      yield put(countriesDataSuccess(countriesDataResponse.data));
    } else {
      // error handler should be here
    }
  }
}
