import React from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import Notifications from './Notifications';
import bn from '../utils/bemnames';

const bem = bn.create('notif-popover');

interface IProps {
  isOpen: boolean;
  toggle: () => void;
}

const NotificationPopover: React.FC<IProps> = ({ isOpen, toggle }) => {
  return (
    <>
      <Popover
        popperClassName={bem.e('popover')}
        placement="bottom"
        isOpen={isOpen}
        toggle={toggle}
        target="NotificationPopover"
        innerClassName="h-100"
        fade={false}
      >
        <PopoverBody className="d-flex flex-column h-100">
          <Notifications toggle={toggle} />
        </PopoverBody>
      </Popover>
      {isOpen && (
        <div className={bem.e('overlay')} onClick={toggle} />
      )}
    </>
  );
};

export default NotificationPopover;
