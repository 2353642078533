import { Action } from 'redux';

export interface ISwState {
  swUpdated: boolean;
  swRegistration: ServiceWorkerRegistration;
}

export enum SwTypes {
  SW_UPDATED = 'SW_UPDATED',
  RESET_SW_UPDATED = 'RESET_SW_UPDATED',
}

export interface ISwUpdated extends Action {
  type: SwTypes.SW_UPDATED,
  payload: ServiceWorkerRegistration,
}

export interface IResetSwUpdated extends Action {
  type: SwTypes.RESET_SW_UPDATED,
}

export type SwActions =
  | ISwUpdated
  | IResetSwUpdated;
