import { ApiResponse } from 'apisauce';
import { i18n } from "../locales";
import camelcaseKeys from 'camelcase-keys';
import store from '../store';

export const formParamsList = (params: { [x: string]: any; }) => {
  let paramsList = '';
  Object.keys(params).map(key => {
    if (paramsList !== '') paramsList += `&`;
    const value = params[key];
    if (Array.isArray(value)) {
      let array: any[] = value;
      for (let i = 0; i < array.length; i++) {
        if (i !== 0) paramsList += `&`;
        paramsList += `${key}[]=${array[i]}`;
      }
    } else {
      paramsList += `${key}=${params[key]}`;
    }
    return paramsList;
  });
  return paramsList;
};

export const formFormData = (item: { [x: string]: string | Blob; }): FormData => {
  let formData = new FormData();
  Object.keys(item).map(key => {
    const value = item[key];
    if (value !== null && value !== undefined) {
      if (Array.isArray(value)) {
        for (let element of value) {
          formData.append(key + '[]', element);
        }
      } else {
        formData.append(key, (typeof value == 'boolean') ? (value ? '1' : '0') : value);
      }
    }
    return null;
  });
  return formData;
};

export const generalDataConfig = () => {
  const headers: any = {
    'Accept-Language': `${i18n.language},en-US;q=0.7,en;q=0.3`,
  };

  // Add the 'X-Country-Id' and 'X-Language-Id' headers. It is used for Varnish caching.
  if (store) {
    const { languageId, countryId } = store.getState().clientSettings;
    if (languageId) {
      headers['X-Language-Id'] = languageId;
    }
    if (countryId) {
      headers['X-Country-Id'] = countryId;
    }
  }

  return {
    headers,
  };
};

export const formDataConfig = () => ({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    'Accept-Language': `${i18n.language},en-US;q=0.7,en;q=0.3`,
  },
});

export const jsonDataConfig = () => ({
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': `${i18n.language},en-US;q=0.7,en;q=0.3`,
  },
});

export const getErrorsFromApiResp = (response: ApiResponse<any>, schema) => {
  const errors: { [field: string]: string } = {};
  if (!response.ok) {
    const { data } = response;
    if (data && data.errors) {
      for (const field in data.errors) {
        if (data.errors.hasOwnProperty(field)) {
          const fieldLabel = (schema && schema.fields[field] && schema.fields[field]._label)
            ? schema.fields[field]._label
            : field;
          for (const apiError of data.errors[field]) {
            switch (apiError) {
              case 'validation.unique':
                errors[field] = i18n._('{field} should be unique', { field: fieldLabel });
                break;

              case 'validation.password':
                errors[field] = i18n._('Wrong password');
                break;

              case 'email not verified':
                errors[field] = i18n._('Email was not verified');
                break;

              case 'wrong password':
                errors[field] = i18n._('Email or password is incorrect');
                break;
            }
          }
        }
      }
    }
  }

  return errors;
};

export const camelcaseResponce = <T>(responce: ApiResponse<T>): ApiResponse<T> => {
  let camelcaseData
  if (Array.isArray(responce.data)) {
    camelcaseData = responce.data.map(item => camelcaseKeys(item, { deep: true }));
  } else {
    camelcaseData = camelcaseKeys(responce.data as any, { deep: true });
  }
  responce.data = camelcaseData;
  return responce;
}

export const convertModelToFormData = (model: any, form: FormData = null, namespace = ''): FormData => {
  let formData = form || new FormData();

  for (let propertyName in model) {
    if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;
    let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
    if (model[propertyName] instanceof Date)
      formData.append(formKey, model[propertyName].toISOString());
    else if (Array.isArray(model[propertyName])) {
      model[propertyName].forEach((element, index) => {
        const tempFormKey = `${formKey}[${index}]`;
        //convertModelToFormData(element, formData, tempFormKey); //uncomment to explore arrays deeply
        formData.append(tempFormKey, model[propertyName][index].toString());
      });
    }
    else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File))
      convertModelToFormData(model[propertyName], formData, formKey);
    else if (model[propertyName] instanceof File) {
      formData.append(formKey, model[propertyName]);
    } else {
      formData.append(formKey, model[propertyName].toString());
    }
  }
  return formData;
}
