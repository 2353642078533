import React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store/rootReducer';
import bn from '../../utils/bemnames';
import SideMenu from './SideMenu';
import { toggleBurgerMenu } from '../../store/clientSettings/actions';
import { Nav, NavItem, NavLink as BSnavLink } from 'reactstrap';
import { i18n } from '../../locales';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '../../router/routes';
import { getMatchcenterUrl } from '../../utils/event';
import { IUserState } from "../../store/user/types";
import withLocale from "../../hocs/withLocale";

const bem = bn.create('side-menu');

interface IDispatchProps {
  user: IUserState,
  menuOpen: boolean,
  toggleBurgerMenu: typeof toggleBurgerMenu;
}

const languageId2BettingTipsPageLink = [];
languageId2BettingTipsPageLink[1] = "/betting-tips"; // English
languageId2BettingTipsPageLink[2] = "/spilforslag"; // Danish

class BurgerMenu extends React.Component<IDispatchProps> {

  render() {
    const { menuOpen } = this.props;
    return (
      <SideMenu menuOpen={menuOpen} toggleMenu={this.props.toggleBurgerMenu}>
        <Nav>
          <NavItem className={bem.e('nav-item can-click')}>
            <BSnavLink
              id={`navItem-betting-tips`}
              tag={NavLink}
              activeClassName="active"
              to={this.props.user.userData?.languageId ? languageId2BettingTipsPageLink[this.props.user.userData?.languageId] : languageId2BettingTipsPageLink[1]}
              onClick={() => this.props.toggleBurgerMenu()}
              exact={true}
            >
              <span className="">{i18n._('Betting Tips')}</span>
            </BSnavLink>
          </NavItem>
          <NavItem className={bem.e('nav-item can-click')}>
            <BSnavLink
              id={`navItem-matches&events`}
              tag={NavLink}
              activeClassName="active"
              to={getMatchcenterUrl()}
              onClick={() => this.props.toggleBurgerMenu()}
              exact={true}
            >
              <span className="">{i18n._('Matches & Events')}</span>
            </BSnavLink>
          </NavItem>
          <NavItem className={bem.e('nav-item can-click')}>
            <BSnavLink
              id={`navItem-tipsters`}
              tag={NavLink}
              activeClassName="active"
              to={ROUTES.GENERAL_TIPSTERS}
              onClick={() => this.props.toggleBurgerMenu()}
              exact={true}
            >
              <span className="">{i18n._('Tipsters')}</span>
            </BSnavLink>
          </NavItem>
          <NavItem className={bem.e('nav-item can-click')}>
            <BSnavLink
              id={`navItem-bonuses`}
              tag={NavLink}
              activeClassName="active"
              to={ROUTES.GENERAL_BONUSES}
              onClick={() => this.props.toggleBurgerMenu()}
              exact={true}
            >
              <span className="">{i18n._('Available Bonuses')}</span>
            </BSnavLink>
          </NavItem>
          <div className={bem.e('divider')} />
        </Nav>
        <Nav>
          <NavItem className={bem.e('nav-item can-click')}>
            <BSnavLink
              id={`navItem-campaigns`}
              tag={NavLink}
              activeClassName="active"
              to={"/campaigns"}
              onClick={() => this.props.toggleBurgerMenu()}
              exact={true}
            >
              <span className="">{i18n._('Available Campaigns')}</span>
            </BSnavLink>
          </NavItem>
          <div className={bem.e('divider')} />
          <NavItem className={bem.e('nav-item can-click')}>
            <BSnavLink
              id={`navItem-articles`}
              tag={NavLink}
              activeClassName="active"
              to={ROUTES.GENERAL_ARTICLES}
              onClick={() => this.props.toggleBurgerMenu()}
              exact={true}
            >
              <span className="">{i18n._('Articles')}</span>
            </BSnavLink>
          </NavItem>
        </Nav>
      </SideMenu>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  const { user, clientSettings } = state;
  return {
    user,
    menuOpen: clientSettings.burgerMenuOpen,
  };
};

const mapDispatchToProps = (dispatch: (arg0: any) => any) => {
  return {
    toggleBurgerMenu: () => dispatch(toggleBurgerMenu()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocale(BurgerMenu));
