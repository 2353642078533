export default {
  defaultLanguage: 'en',
  languages: [
    'da',
    'en',
    'se',
    'fi'
  ],
  recaptchaSiteKey: '6LcyX8AZAAAAAAXZCQLUggPYgg_HDC_chX2wcGxR',
  facebookClientId: '261119844729450',
  googleClientId: '730699203859-jvn9jqsaedfi5c8i51shu99h3qq1ao29.apps.googleusercontent.com',
  appleClientId: 'app.betxpert.service-id',
};
