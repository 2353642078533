import { ActionCreator } from 'redux';
import {
  UserActionTypes as action,
  IUserRequestFail,
  IUserRequestStart,
  IUserData,
  IUserRequestSuccess,
  IUserLogout,
  IUserRegisterSuccess,
  IUserLogoutSuccess,
  IUserNewsletterSubscribed,
  IUserSetFCMToken,
  IUserFCMTokenSaved,
  ISetNumOfUnreadWebNotif,
} from './types';

export const userFetchStart: ActionCreator<IUserRequestStart> = () => ({
  type: action.USER_REQUEST_START,
});

export const userFetchSuccess: ActionCreator<IUserRequestSuccess> = (userData: IUserData, userPermissions: string[]) => ({
  type: action.USER_REQUEST_SUCCESS,
  payload: { userData, userPermissions },
});

export const userFetchFail: ActionCreator<IUserRequestFail> = () => ({
  type: action.USER_REQUEST_FAIL,
});

export const userLogout: ActionCreator<IUserLogout> = () => ({
  type: action.USER_LOGOUT,
});
export const userLogoutSuccess: ActionCreator<IUserLogoutSuccess> = () => ({
  type: action.USER_LOGOUT_SUCCESS,
});

export const userRegisterSuccess: ActionCreator<IUserRegisterSuccess> = () => ({
  type: action.USER_REGISTER_SUCCESS,
});

export const userSetFCMToken: ActionCreator<IUserSetFCMToken> = (token: string) => ({
  type: action.USER_SET_FCM_TOKEN,
  payload: { token },
});

export const userFCMTokenSaved: ActionCreator<IUserFCMTokenSaved> = (success: boolean) => ({
  type: action.USER_FCM_TOKEN_SAVED,
  payload: { success },
});

export const userNewsletterSubscribed: ActionCreator<IUserNewsletterSubscribed> = () => ({
  type: action.USER_NEWSLETTER_SUBSCRIBED,
});

export const setNumOfUnreadWebNotif: ActionCreator<ISetNumOfUnreadWebNotif> = (num: number) => ({
  type: action.SET_NUM_OF_UNREAD_WEB_NOTIF,
  payload: { num },
});
