import React from 'react';
import { i18n } from "../locales";
import { IOperator } from '../utils/types';
import { getRedirectUrl } from '../utils/operator';

interface IProps {
  operator: IOperator;
  operatorUrlSlug?: string;
  className?: string;
  title?: string;
  style?: object;
  children: any;
}

const RedirectOperatorLink: React.FC<IProps> = ({ operator, operatorUrlSlug, children, title, ...restProps }) => {
  return (
    <a
      href={getRedirectUrl(operator.slug, operatorUrlSlug)}
      target="_blank"
      rel="noopener noreferrer nofollow"
      title={title || i18n._('Visit {operator}', { operator: operator.name })}
      {...restProps}
    >
      {children}
    </a>
  );
};

export default RedirectOperatorLink;
