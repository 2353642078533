import { translatePath } from './routing';
import { IOperator } from "../utils/types";

export enum OperatorLogoType {
  Square,
  Normal,
  Wide,
  Large,
}

export function getOperatorUrl(slug: string, tab?: string) : string {
  return translatePath('/operators') + '/' + slug + (tab ? '/' + tab : '');
}

export function getRedirectUrl(operatorSlug: string, operatorUrlSlug?: string) : string {
  return translatePath('/redirect/operator') + '/' + operatorSlug + (operatorUrlSlug ? `/${operatorUrlSlug}` : '');
}

export function getOperatorLogoSrc(operator: IOperator, logoType?: OperatorLogoType) {
  let size;
  switch (logoType) {
    case OperatorLogoType.Square:
      size = '90x90';
      break;

    case OperatorLogoType.Wide:
      size = '160x40';
      break;

    case OperatorLogoType.Large:
      size = '200x100';
      break;

    default:
      size = '100x50';
      break;
  }

  return (operator.slug && operator.logoTs)
    ? `/uploads/operator/${size}/${operator.slug}.png?v=${operator.logoTs}`
    : null;
}