import { combineReducers, Reducer } from 'redux';
//reducers
import userReducer from './user/reducer';
import clientSettingsReducer from './clientSettings/reducer';
import pickCreateReducer from './pickCreation/reducer';
import matchCenterReducer from './matchcenter/reducer';
import swReducer from './sw/reducer';
//types
import { IUserState } from './user/types';
import { IClientSettingsState } from './clientSettings/types';
import { IPickCreateState } from './pickCreation/types';
import { IMatchCenterState } from './matchcenter/types';
import { ISwState } from './sw/types';

export interface IApplicationState {
  user: IUserState;
  clientSettings: IClientSettingsState;
  pickCreate: IPickCreateState;
  matchCenter: IMatchCenterState;
  sw: ISwState;
}

const appReducer: Reducer<IApplicationState> = combineReducers<
  IApplicationState
>({
  user: userReducer,
  clientSettings: clientSettingsReducer,
  pickCreate: pickCreateReducer,
  matchCenter: matchCenterReducer,
  sw: swReducer,
});

export default appReducer;