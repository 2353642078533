import { Reducer } from 'redux';
import { SwActions, SwTypes as actions, ISwState } from './types';

const initialState: ISwState = {
  swUpdated: false,
  swRegistration: null,
};

const pickCreateReducer: Reducer<ISwState> = (
  state: ISwState = initialState,
  action
) => {
  switch ((action as SwActions).type) {
    case actions.SW_UPDATED:
      return {
        swUpdated: true,
        swRegistration: action.payload,
      };

    case actions.RESET_SW_UPDATED:
      return {
        swUpdated: false,
        swRegistration: null,
      };

    default:
      return state;
  }
};

export default pickCreateReducer;

