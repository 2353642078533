import React from 'react';
import { connect } from 'react-redux';
import LoginForm from '../components/LoginForm';
import { IApplicationState } from '../store/rootReducer';
import { Redirect } from "react-router-dom";
import { ROUTES } from "../router/routes";
import MainContainer from '../components/MainContainer';

interface IProps {
  isLoggedIn: boolean;
}

class LoginPage extends React.Component<IProps> {
  render = () => {
    if (this.props.isLoggedIn) {
      return <Redirect to={ROUTES.GENERAL_ORIGIN} />;
    }

    return (
      <MainContainer>
        <LoginForm />
      </MainContainer>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  const { user } = state;
  return {
    isLoggedIn: user.isLoggedIn,
  };
};

export default connect(
  mapStateToProps
)(LoginPage);
