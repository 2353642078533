import { i18n } from '../../locales';
import React from 'react';
import classnames from 'classnames';
import { MdAddCircleOutline, MdMenu, MdSearch, MdClose } from 'react-icons/md';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Container, Nav, Navbar, NavItem, NavLink as BSNavLink } from 'reactstrap';
import betXpertLogo from '../../assets/img/logo.svg';
import userIcon from '../../assets/img/user.svg';
import userIconRed from '../../assets/img/userRed.svg';
import { ROUTES } from '../../router/routes';
import { IApplicationState } from '../../store/rootReducer';
import { userLogout, setNumOfUnreadWebNotif } from '../../store/user/actions';
import { toggleLoginModal, closeAllMenus, toggleBurgerMenu, toggleUserMenu, toggleSearchMenu } from '../../store/clientSettings/actions';
import { IUserState } from '../../store/user/types';
//styles
import bn from '../../utils/bemnames';
import api from '../../api';
import { ApiResponse } from 'apisauce';
import NotificationBell from '../NotificationBell';
import NotificationPopover from '../NotificationPopover';
import { t } from '@lingui/macro';
import { translatePath } from '../../utils/routing';

const bem = bn.create('header');

interface IHeaderState {
  isOpenNotificationPopover: boolean;
}

interface IDispatchProps {
  user: IUserState;
  modalOpen: boolean;
  burgerMenuOpen: boolean;
  userMenuOpen: boolean;
  searchMenuOpen: boolean;
  loginModalOpen: boolean;
  isNativeApp: boolean;
  userLogout: typeof userLogout;
  toggleLoginModal: typeof toggleLoginModal;
  headerCloseBtnClicked: typeof closeAllMenus;
  toggleBurgerMenu: typeof toggleBurgerMenu;
  toggleUserMenu: typeof toggleUserMenu;
  toggleSearchMenu: typeof toggleSearchMenu;
  setNumOfUnreadWebNotif: typeof setNumOfUnreadWebNotif;
}

class Header extends React.Component<IDispatchProps, IHeaderState> {
  public readonly state = {
    isOpenNotificationPopover: false,
  };

  private updNumOfUnreadNotifTimeout: any;

  componentDidMount() {
    this.updateNumOfUnreadNotif();
  }

  componentWillUnmount() {
    clearTimeout(this.updNumOfUnreadNotifTimeout);
  }

  updateNumOfUnreadNotif = () => {
    this.updNumOfUnreadNotifTimeout = setTimeout(async () => {
      if (this.props.user.isLoggedIn && !this.state.isOpenNotificationPopover) {
        const resp: ApiResponse<any> = await api.getNumOfUnreadWebNotif();
        if (resp.ok && !this.state.isOpenNotificationPopover) {
          this.props.setNumOfUnreadWebNotif(resp.data.numOfUnread);
        }
      }

      this.updateNumOfUnreadNotif();
    }, 60000); // once per min
  };

  handleBurgerMenuControlButton = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.toggleBurgerMenu();
  };

  handleUserMenuControlButton = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.toggleUserMenu();
  };

  handleSearchMenuControlButton = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.toggleSearchMenu();
  };

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });
    this.props.setNumOfUnreadWebNotif(0);
    api.webNotifResetUnread();
  };

  render = () => {
    const { toggleLoginModal, isNativeApp } = this.props;
    const { isOpenNotificationPopover } = this.state;

    const numOfUnreadNotif = this.props.user.userData?.numOfUnreadWebNotif || 0;
    const { isLoggedIn } = this.props.user;

    return (
      <Navbar dark expand sticky={'top'} className={bem.b()}>
        <Container fluid="lg">
          <Nav navbar className="mr-2">
            <NavItem className="d-inline-flex">
              <NavLink to={ROUTES.GENERAL_ORIGIN}>
                <img src={betXpertLogo} alt={betXpertLogo} />
              </NavLink>
            </NavItem>
          </Nav>
          <Nav navbar className={bem.e('nav-right')}>
            <NavItem className="d-inline-flex">
              <BSNavLink tag={NavLink} to={translatePath(ROUTES.CREATE_PICK)} activeClassName="none" id="CreatePick" className="position-relative">
                <MdAddCircleOutline
                  size={24}
                  className={bem.e('nav-button')}
                />
                <span className={bem.e('nav-button-text', 'text-white can-click hidden-md hidden-sm hidden-xs')}>{i18n._(t`Create Pick`)}</span>
              </BSNavLink>
            </NavItem>
            {!isNativeApp &&
              <NavItem className="d-inline-flex">
                <BSNavLink id="NotificationPopover">
                  {isOpenNotificationPopover ? (
                    <MdClose size={24} className={bem.e('nav-button-close can-click')} onClick={this.props.headerCloseBtnClicked} />
                  ) : (
                    <NotificationBell
                      numOfUnread={numOfUnreadNotif}
                      onClick={!isLoggedIn ? toggleLoginModal : undefined}
                    />
                  )}
                </BSNavLink>
                {isLoggedIn && (
                  <NotificationPopover
                    isOpen={isOpenNotificationPopover}
                    toggle={this.toggleNotificationPopover}
                  />
                )}
              </NavItem>
            }
            {!isNativeApp &&
              <NavItem className="d-inline-flex">
                <BSNavLink>
                  {this.props.searchMenuOpen ?
                    <MdClose size={24} className={bem.e('nav-button-close can-click text-white')} onClick={this.props.headerCloseBtnClicked} />
                    : <span>
                      <MdSearch size={24}
                                className={bem.e('nav-button can-click')}
                                onClick={this.handleSearchMenuControlButton}
                      />
                    </span>
                  }
                </BSNavLink>
              </NavItem>
            }
            <NavItem className="d-inline-flex">
              <BSNavLink>
                {this.props.user.isLoggedIn ?
                  this.props.userMenuOpen ?
                    <MdClose size={24} className={bem.e('nav-button-close can-click')} onClick={this.props.headerCloseBtnClicked} />
                    :
                    <img
                      onClick={this.handleUserMenuControlButton}
                      src={this.props.user.userData.avatarPath || userIcon}
                      alt={userIcon}
                      className={classnames([
                        bem.e('nav-button can-click') + ' ' + bem.e('avatar'),
                        this.props.user.userData.avatarPath && 'border border-white',
                      ])}
                    />
                  : this.props.loginModalOpen ?
                    <MdClose size={24} className={bem.e('nav-button-close can-click')} onClick={this.props.headerCloseBtnClicked} />
                    : <img
                      onClick={toggleLoginModal}
                      src={userIconRed}
                      alt={userIconRed}
                      className={bem.e('nav-button can-click')}
                    />
                }
              </BSNavLink>
            </NavItem>
            <NavItem className="d-inline-flex">
              <BSNavLink>
                {this.props.burgerMenuOpen ?
                  <MdClose size={24} className={bem.e('nav-button-close can-click text-white')} onClick={this.props.headerCloseBtnClicked} />
                  : <span>
                    <MdMenu size={24}
                      className={bem.e('nav-button can-click')}
                      onClick={this.handleBurgerMenuControlButton}
                    />
                  </span>
                }
              </BSNavLink>
            </NavItem>
          </Nav>
        </Container>
      </Navbar>)
  }
}

const mapStateToProps = (state: IApplicationState) => {
  const { user, clientSettings } = state;
  return {
    user,
    modalOpen: clientSettings.modalOpen,
    loginModalOpen: clientSettings.loginModalOpen,
    burgerMenuOpen: clientSettings.burgerMenuOpen,
    userMenuOpen: clientSettings.userMenuOpen,
    searchMenuOpen: clientSettings.searchMenuOpen,
    isNativeApp: clientSettings.isNativeApp,
  };
};

const mapDispatchToProps = (dispatch: (arg0: any) => any) => {
  return {
    userLogout: () => dispatch(userLogout()),
    toggleLoginModal: () => dispatch(toggleLoginModal()),
    headerCloseBtnClicked: () => dispatch(closeAllMenus()),
    toggleUserMenu: () => dispatch(toggleUserMenu()),
    toggleBurgerMenu: () => dispatch(toggleBurgerMenu()),
    toggleSearchMenu: () => dispatch(toggleSearchMenu()),
    setNumOfUnreadWebNotif: (num: number) => dispatch(setNumOfUnreadWebNotif(num)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
