import { ActionCreator } from 'redux';
import {
  ClientSettingsActionTypes as action,
  ILanguageSet,
  IMasterPageFail,
  IMasterPageRequest,
  IMasterPageSuccess,
  IFooterItem,
  ISocialLink,
  ICountriesDataRequest,
  ICountriesDataSuccess,
  ICountryData,
  IToggleLoginModal,
  ICloseMenus,
  ISetTimeZone,
  ISetOddsFormat,
  IToggleBurgerMenu,
  IToggleUserMenu,
  IToggleSearchMenu,
  ILanguage,
  ISetIsNative,
  INativeCommandResponce,
  INativeCommandResponceData,
  IReloadBanner,
} from './types';
import { IOperator } from '../../utils/types';

export const setLanguage: ActionCreator<ILanguageSet> = (locale: string) => ({
  type: action.LANGUAGE_SET,
  payload: { locale }
});

export const masterDataRequest: ActionCreator<IMasterPageRequest> = () => ({
  type: action.MASTER_DATA_REQUEST
});

export const masterDataSuccess: ActionCreator<IMasterPageSuccess> = ( languageId: number, countryId: number, footerItems: IFooterItem[], socialLinks: ISocialLink[], languages: ILanguage[], gamblingAddictionLink: string | null) => {
  return ({
    type: action.MASTER_DATA_SUCCESS,
    payload: { languageId, countryId, footerItems, socialLinks, languages, gamblingAddictionLink }
  })
};

export const masterDataFail: ActionCreator<IMasterPageFail> = () => ({
  type: action.MASTER_DATA_FAIL
});

export const countriesDataRequest: ActionCreator<ICountriesDataRequest> = () => ({
  type: action.COUNTRIES_DATA_REQUEST
});

export const countriesDataSuccess: ActionCreator<ICountriesDataSuccess> = (countriesData: ICountryData[]) => {
  return ({
    type: action.COUNTRIES_DATA_SUCCESS,
    payload: { countriesData }
  })
};

export const toggleLoginModal: ActionCreator<IToggleLoginModal> = () => ({
  type: action.TOGGLE_LOGIN_MODAL,
});

export const toggleBurgerMenu: ActionCreator<IToggleBurgerMenu> = () => ({
  type: action.TOGGLE_BURGER_MENU,
});

export const toggleUserMenu: ActionCreator<IToggleUserMenu> = () => ({
  type: action.TOGGLE_USER_MENU,
});

export const toggleSearchMenu: ActionCreator<IToggleSearchMenu> = () => ({
  type: action.TOGGLE_SEARCH_MENU,
});

export const closeAllMenus: ActionCreator<ICloseMenus> = () => ({
  type: action.CLOSE_MENUS,
});

export const setTimeZone: ActionCreator<ISetTimeZone> = (timeZone: string) => ({
  type: action.SET_TIME_ZONE,
  payload: { timeZone },
});

export const setOddsFormat: ActionCreator<ISetOddsFormat> = (oddsFormat: string) => ({
  type: action.SET_ODDS_FORMAT,
  payload: { oddsFormat },
});

export const setIsNative: ActionCreator<ISetIsNative> = () => ({
  type: action.SET_IS_NATIVE_APP,
});

export const nativeActionResponce: ActionCreator<INativeCommandResponce> = (responce: INativeCommandResponceData) => ({
  type: action.NATIVE_COMMAND,
  payload: { responce },
});

export const reloadBanner: ActionCreator<IReloadBanner> = (operator?: IOperator) => ({
  type: action.RELOAD_BANNER,
  payload: { operator },
});
