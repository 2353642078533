import {IUserData} from "../store/user/types";
import { ITournamentStage } from '../store/pickCreation/types';
import { EventStatus } from '../utils/event';
import { PickStatus } from '../utils/pick';
import { ITipsterStatsRow } from '../components/TipsterStats';

export interface IPaginatedResp<Type> {
  data: Type[];
  total: number;
  currentPage: number;
  lastPage: number;
  perPage: number;
}

export interface ISuccessResp {
  success: boolean;
}
export type Gender = 'm' | 'f';

export interface IOperator {
  id: number;
  name: string;
  slug: string;
  active: boolean;
  showReview: boolean;
  showOdds: boolean;
  showInToplist: boolean;
  logoTs: number;
  providerId: number | null;
  rating: number;
  bgColor: string | null;
  priority: number;
}

export interface IOperatorUrl {
  id: number,
  operatorId: number,
  name: string,
  slug: string,
  url: string,
  type: string,
  active: boolean,
  articleUse: boolean,
  countryId: number,
}

export interface ICampaign {
  id: number,
  operatorId: number,
  operatorUrlId: number,
  eventId: number | null,
  competitionId: number | null,
  startTime: string | null,
  endTime: string | null,
  newCustomers: boolean,
  type: string,
  active: boolean,
  countryId: number | null,
  data: {
    campaignId: number,
    languageId: number,
    name: string,
    slug: string
    content: string,
    contentData?: IContentData,
    summaryTerms: string,
    teaser?: string
  },
  operator: IOperator,
  operatorUrl: IOperatorUrl,
}

export interface IContentData {
  id2Image: IImage[];
  id2Operator: IOperator[];
  id2OperatorUrl: IOperatorUrl[];
  id2Article: IArticle[];
  currentPicks?: IPick[];
  tipsterStats?: ITipsterStatsRow[];
}

export interface ISport {
  id: number;
  name: string;
  trName: string;
  trSlug?: string;
}

export interface IMarket {
  id: number;
  objectType: string;
  objectId: number;
  marketTypeId: number;
  periodId: number;
  line: number;
  avgOddsDiff?: number;
}

export interface IMarketCompetitor {
  position: number;
  competitor: ICompetitor;
}

export interface ICompetitor {
  id: number;
  trName: string;
  gender: Gender;
  competitorType: string;
}

export interface IOddsClickData {
  marketTypeId: number;
  periodId: number;
  line: number;
  odds: number;
  operatorId: number;
  outcomeName: string; // can be e.g. '1', 'X', 'Y. Konoplyanka'
}

export interface IArticle {
  id: number,
  languageId: number,
  title: string,
  slug: string,
  status: string,
  content: string,
  contentData: IContentData,
  teaser: string,
  operatorId?: number,
  operatorUrlId?: number,
  publishTime: string,
  factbox?: string,
  imageId: number,
  image?: IImage,
  operator?: IOperator,
  operatorUrl?: IOperatorUrl,
  articleAuthors?: IArticleAuthor[],
  metaDescription: string | null,
  metaTitle: string | null,
  numOfPosts?: number;
  threadClosed?: boolean;
}

export interface IImage {
  id: number,
  year: number,
  month: number,
  name: string,
  width: number,
  height: number,
  ts: number,
  alt?: string,
}

export interface IArticleAuthor {
  articleId: number,
  userId: number,
  user: IUserData
}

export interface IPage {
  id: number,
  languageId: number,
  title: string,
  slug: string,
  status: string,
  content: string,
  contentData: IContentData,
  publishTime: string,
  factbox?: string,
  imageId: number,
  metaDescription: string | null,
  metaTitle: string | null,
  showAuthor: boolean,
  image?: IImage,
  pageAuthors?: IPageAuthor[],
}

export interface IPageAuthor {
  pageId: number,
  userId: number,
  user: IUserData
}

export interface IThread {
  id: number;
  entityType: ThreadEntityType;
  entityId: number;
  status: ThreadStatus;
  numOfPosts: number;
  entity?: any;
}

export enum ThreadEntityType {
  ARTICLE = 'article',
  EVENT = 'event',
  PICK = 'pick',
}

export enum ThreadStatus {
  ONLINE = 'online',
  CLOSED = 'closed',
}

export interface IWebNotification {
  id: number;
  entityType: WebNotifEntityType;
  entity: any;
  type: WebNotifType;
  createdAt: string;
}

export enum WebNotifEntityType {
  THREAD = 'thread',
  CAMPAIGN = 'campaign',
}

export enum WebNotifType {
  NEW = 'new',
  UPDATE = 'update',
  COMMENT = 'comment',
  MENTION = 'mention',
}

export interface IPost {
  id: number;
  text: string;
  createdAt: string;
}

export interface IAuditTrail {
  id: number;
  userId: number;
  entityType: string;
  entityId: number;
  attr: string | null;
  value: string | null;
  createdAt: string;
  user: IUserData;
}

export interface IOperatorReview {
  id: number,
  operatorId: number,
  languageId: number,
  title: string | null,
  positives: string | null,
  negatives: string | null,
  content: string | null,
  operator: IOperator
}

export interface IOperatorBonus {
  id: number,
  operatorId: number,
  operatorUrlId: number,
  value: number | null,
  currencyId: number,
  type: string,
  minBonusValue: number | null,
  maxBonusValue: number | null,
  percentage: number | null,
  rolloverType: string,
  rolloverTimes: number | null,
  minimumOdds: number | null,
  tcLink: string | null,
  newCustomers: boolean,
  active: boolean,
  countryId: number | null,
  operator: IOperator,
  operatorUrl: IOperatorUrl,
  origData: IOperatorBonusData,
  currency: ICurrency
}

export interface IOperatorBonusData {
  operatorBonusId: number,
  languageId: number,
  specificTerms: string | null,
  headline: string | null,
  description: string | null,
  bullets: string | null,
  content: string | null,
  ctaTextOverride: string | null,
  compliance: string | null,
  revenueRequirements: string | null,
}

export interface ICurrency {
  id: number,
  name: string,
}

export interface ICarouselItem {
  id: number;
  entityType: string;
  imageHash: string | null;
  entity: any;
  numOfPosts: number;
}

export interface IPick {
  id: number;
  userId: number;
  time: string;
  odds: number;
  status: PickStatus;
  sportId: number | null;
  sportName: string | null;
  competitionName: string;
  origLanguageId: number,
  imageHash: string;
  pickData: {
    title: string;
    slug: string;
  };
  operator: IOperator,
}

export interface IEvent {
  id: number;
  trName: string;
  trSlug: string;
  startTime: string;
  status: EventStatus,
  tournamentStage: ITournamentStage;
}

export interface IPostImage {
  id: number;
  ext: string;
  width: number;
  height: number;
}
