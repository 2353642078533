import React from 'react';
import classnames from 'classnames';
import { withRouter } from 'react-router';
import { RouteComponentProps, StaticContext } from 'react-router';
import { Container } from 'reactstrap';
import PageHeader from './PageHeader';
import Skyscraper from './banners/Skyscraper';

interface IProps {
  headerLabel?: string;
  goBackClick?: () => void;
  noSkyscraper?: boolean;
  children: any;
}

type Props = IProps & RouteComponentProps<any, StaticContext, {}>

class MainContainer extends React.Component<Props> {
  render() {
    const { headerLabel, goBackClick, noSkyscraper, children } = this.props;

    return (
      <div className="overflow-hidden">
        <Container
          className={classnames([
            'mb-3 position-relative',
            !headerLabel && 'pt-3',
          ])}
        >
          {headerLabel && (
            <PageHeader
              className="full-width-wrp"
              headerLabel={headerLabel}
              goBackClick={goBackClick || (() => { this.props.history.goBack() })}
            />
          )}
          <div className="mw-920px mx-auto">
            {children}
          </div>
          {!noSkyscraper && (
            <Skyscraper />
          )}
        </Container>
      </div>
    );
  }
}

export default withRouter(MainContainer);
