import * as React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../store/rootReducer';

interface IDispatchProps {
  locale: string;
}

export interface withThemeProp {
  locale: string;
}

const withLocale = (WrappedComponent: any) =>
  class extends React.Component<IDispatchProps, withThemeProp> {
    public readonly state: withThemeProp = {
      locale: this.props.locale,
    };
    componentDidUpdate = (oldProps: { locale: string; }) => {
      if (
        this.props.locale !== oldProps.locale
      ) {
        this.setState({ locale: this.props.locale });
      }
    };
    render() {
      return <WrappedComponent {...this.props} locale={this.state.locale} />;
    }
  };

const mapStateToProps = (state: IApplicationState) => {
  const { locale } = state.clientSettings;

  return {
    locale,
  };
};

export default (wrapedComponent: any) =>
  connect(
    mapStateToProps,
    {},
  )(withLocale(wrapedComponent));