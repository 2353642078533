import React from 'react';
import { i18n } from "../locales";
import { Alert } from "reactstrap";

interface IProps {
  text?: string,
}

const NoDataWarning: React.FC<IProps> = ({text}) => {
  return (
    <Alert className="mb-0" color="warning">{text ?? i18n._('No data')}</Alert>
  )
}

export default NoDataWarning;
