import React from 'react';
import { MdNotificationsActive, MdNotificationsNone } from 'react-icons/md';
import bn from '../utils/bemnames';

const bem = bn.create('notification-bell');

interface IProps {
  numOfUnread: number;
  onClick: () => void;
}

class NotificationBell extends React.Component<IProps> {
  render() {
    const { numOfUnread, onClick } = this.props;

    const MdIcon = numOfUnread ? MdNotificationsActive : MdNotificationsNone;

    return (
      <div className={bem.e('wrp', 'd-inline-block position-relative can-click text-white')} onClick={onClick}>
        <MdIcon
          size={24}
          className={numOfUnread ? 'animated swing infinite' : ''}
        />
        {numOfUnread > 0 && (
          <span className={bem.e('cnt', 'bg-primary font-weight-bold text-center d-block position-absolute')}>
            {numOfUnread}
          </span>
        )}
      </div>
    );
  }
}

export default NotificationBell;
