import React from 'react';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import { IWebNotification } from '../utils/types';
import { ApiResponse } from 'apisauce';
import api from '../api';
import { i18n } from '../locales';
import LoadingSpinner from './LoadingSpinner';
import FailedToLoadData from './FailedToLoadData';
import { Alert } from 'reactstrap';
import logoX from '../assets/img/logo/logo.svg';
import { getEntityUrl, getEntityImageSrc, getNotifDescription, getEntityTitle } from '../utils/notification';
import FormatTime from './FormatTime';
import bn from '../utils/bemnames';

const bem = bn.create('notifications');
const ITEMS_PER_PORTION = 20;

interface IProps {
  toggle: () => void;
}

interface IState {
  loading: boolean;
  failed: boolean;
  notifications: IWebNotification[];
  noMore: boolean;
}

class Notifications extends React.Component<IProps> {
  public readonly state: IState = {
    loading: true,
    failed: false,
    notifications: [],
    noMore: false,
  };
  private scrollContRef = React.createRef<HTMLDivElement>();
  private getNewNotifTimeout: any;

  componentDidMount() {
    this.loadNotifications();
    this.getNewNotifications();
  }

  componentWillUnmount() {
    clearTimeout(this.getNewNotifTimeout);
  }

  loadNotifications = async () => {
    this.setState({
      loading: true,
      failed: false,
    });

    // Fetch data.
    const resp: ApiResponse<IWebNotification[]> = await api.getWebNotifications() as ApiResponse<IWebNotification[]>;
    if (resp.ok) {
      this.setState({
        notifications: resp.data,
        loading: false,
        failed: false,
        noMore: (resp.data.length < ITEMS_PER_PORTION),
      });
    } else {
      this.setState({
        loading: false,
        failed: true,
      });
    }
  };

  getNewNotifications = async () => {
    this.getNewNotifTimeout = setTimeout(async () => {
      const resp: ApiResponse<IWebNotification[]> = await api.getWebNotifications({
        lastId: this.state.notifications[0]?.id,
        new: 1,
      }) as ApiResponse<IWebNotification[]>;
      if (resp.ok && resp.data.length) {
        this.setState((state: IState) => ({
          notifications: resp.data.concat(state.notifications),
        }));
      }

      this.getNewNotifications();
    }, 60000); // once per min
  };

  handleDeleteClick = async (notification: IWebNotification) => {
    const resp = await api.delWebNotification(notification.id);
    if (resp.ok) {
      this.setState((state: IState) => ({
        notifications: state.notifications.filter(n => n !== notification),
      }));
    } else {
      toastr.error(i18n._('Failed to delete the notification'));
    }
  };

  handleScroll = async () => {
    // Check state.
    const { loading, failed, noMore, notifications } = this.state;
    if (loading || failed || noMore || !notifications.length) {
      return;
    }

    // Check scroll position.
    const cont = this.scrollContRef.current;
    if (cont.scrollTop + cont.clientHeight > cont.scrollHeight - 500) {
      // Load more.
      this.setState({
        loading: true,
      });
      const resp: ApiResponse<IWebNotification[]> = await api.getWebNotifications({
        lastId: notifications[notifications.length - 1].id,
      }) as ApiResponse<IWebNotification[]>;
      if (resp.ok) {
        this.setState((state: IState) => ({
          loading: false,
          notifications: resp.data.length ? state.notifications.concat(resp.data) : state.notifications,
          noMore: (resp.data.length < ITEMS_PER_PORTION),
        }));
      } else {
        this.setState({
          loading: false,
        });
        toastr.error(i18n._('Failed to load more notifications'));
      }
    }
  };

  render() {
    const { loading, failed, notifications } = this.state;

    return (
      <>
        <div>
          <div className="text-444 font-weight-bold">
            {i18n._('Notifications')}
          </div>
          <hr className="my-2" />
          {!loading && failed && (
            <FailedToLoadData className="my-0" />
          )}
          {!loading && !failed && !notifications.length && (
            <Alert className="mb-0" color="warning">{i18n._('There are no notifications')}</Alert>
          )}
        </div>
        <div
          ref={this.scrollContRef}
          className="mb-0 full-width-wrp overflow-auto flex-grow-1"
          onScroll={this.handleScroll}
        >
          {!failed && notifications.length > 0 && (
            <ul className="non-striped-list mb-0 f-size-12px line-h-1-33 font-weight-medium">
              {notifications.map(notification => {
                return (
                  <li key={notification.id} className="p-0">
                    <Link
                      className={bem.e('link', 'd-flex align-items-center text-dark')}
                      to={getEntityUrl(notification)}
                      onClick={this.props.toggle}
                    >
                      <div
                        className={bem.e('img', 'flex-shrink-0')}
                        style={{
                          backgroundImage: 'url("' + (getEntityImageSrc(notification) || logoX) + '")',
                        }}
                      />
                      <div className="pl-2 flex-grow-1 overflow-hidden">
                        <div className="d-flex">
                          <div className="flex-grow-1 text-truncate">
                            {getNotifDescription(notification)}
                          </div>
                          <div
                            className="pl-2 text-muted can-click"
                            title={i18n._('Delete this notification')}
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.handleDeleteClick(notification);
                            }}
                          >
                            ⨉
                          </div>
                        </div>
                        <div className="font-weight-bold text-truncate">
                          {getEntityTitle(notification)}
                        </div>
                        <div className="text-muted">
                          <FormatTime time={notification.createdAt} />
                        </div>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
          {loading && (
            <LoadingSpinner />
          )}
        </div>
      </>
    );
  }
}

export default Notifications;
