import React from 'react';
import BackBtn from './BackBtn';
import { Row, Col } from 'reactstrap';
import bn from '../utils/bemnames';

const bem = bn.create('page-header');

interface IProps {
  className?: string;
  goBackClick: () => void;
  headerLabel?: string;
}

const PageHeader = ({ className, goBackClick, headerLabel }: IProps) => (
  <div className={bem.b(className)}>
    <Row className="align-items-center">
      <Col><BackBtn onClick={goBackClick} /></Col>
      <Col xs="auto" className={bem.e('header-label', 'text-center')}>{headerLabel}</Col>
      <Col></Col>
    </Row>
  </div>
);

export default PageHeader;