import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { IApplicationState } from '../store/rootReducer';
import { Modal, ModalBody, Nav, NavItem, NavLink, TabContent, TabPane, Alert } from 'reactstrap';
import { toggleLoginModal } from '../store/clientSettings/actions';
import { IToggleLoginModal } from '../store/clientSettings/types';
import { i18n } from '../locales';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';

interface IProps {
  loginModalOpen: boolean;
  toggleLoginModal: typeof toggleLoginModal;
  registerSuccess: boolean;
}

class LoginModal extends React.Component<IProps, {}> {
  public readonly state = {
    activeTab: 'login'
  };

  setActiveTab = (activeTab: string) => {
    this.setState({
      activeTab
    });
  };

  render() {
    const { loginModalOpen, toggleLoginModal, registerSuccess } = this.props;
    const { activeTab } = this.state;

    return (
      <Modal isOpen={loginModalOpen} toggle={toggleLoginModal}>
        <ModalBody>
          <Nav tabs justified className="mb-3 mt-n3 mt-sm-0">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'login' })}
                onClick={() => { this.setActiveTab('login') }}
              >
                {i18n._('Login')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'register' })}
                onClick={() => { this.setActiveTab('register') }}
              >
                {i18n._('Register')}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="login">
              <LoginForm isModal />
            </TabPane>
            <TabPane tabId="register">
              {registerSuccess ? (
                <Alert color="success">
                  {i18n._('You have successfully registered.')}
                  {' '}
                  {i18n._("Please verify your account by clicking the link we've sent to your email.")}
                </Alert>
              ) : (
                  <RegisterForm />
                )}
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  const { user, clientSettings } = state;
  return {
    loginModalOpen: clientSettings.loginModalOpen,
    registerSuccess: user.registerSuccess,
  };
};

const mapDispatchToProps = (dispatch: (arg0: IToggleLoginModal) => any) => {
  return {
    toggleLoginModal: () => dispatch(toggleLoginModal()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginModal);
