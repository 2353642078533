import { Action } from 'redux';

export interface IUserState {
  userData?: IUserData;
  userPermissions: string[],
  userLoading: boolean;
  userFail: boolean;
  isLoggedIn: boolean;
  registerSuccess: boolean;
  userFCMToken?: string;
  userFCMTokenSaved?: boolean;
}
export interface IUserData {
  id: number,
  email?: string,
  isAdmin: boolean,
  username?: string, // deleted users don't have username
  languageId: number,
  countryId: number,
  slug?: string, // deleted users don't have slug
  isExpert: boolean,
  newsletter: boolean,
  numOfUnreadWebNotif: number,
  avatarPath: string | null,
  numOfPosts?: number,
  banned: boolean,
}

export type Group = { name: string };

export enum UserActionTypes {
  USER_REQUEST_START = 'USER_REQUEST_START',
  USER_REQUEST_FAIL = 'USER_REQUEST_FAIL',
  USER_REQUEST_SUCCESS = 'USER_REQUEST_SUCCESS',
  USER_LOGOUT = 'USER_LOGOUT',
  USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS',
  USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS',
  USER_SET_FCM_TOKEN = 'USER_SET_FCM_TOKEN',
  USER_FCM_TOKEN_SAVED = 'USER_FCM_TOKEN_SAVED',
  USER_NEWSLETTER_SUBSCRIBED = 'USER_NEWSLETTER_SUBSCRIBED',
  SET_NUM_OF_UNREAD_WEB_NOTIF = 'SET_NUM_OF_UNREAD_WEB_NOTIF',
}

export interface IUserRequestStart extends Action {
  type: UserActionTypes.USER_REQUEST_START;
}

export interface IUserRequestFail extends Action {
  type: UserActionTypes.USER_REQUEST_FAIL;
}

export interface IUserLogout extends Action {
  type: UserActionTypes.USER_LOGOUT;
}

export interface IUserLogoutSuccess extends Action {
  type: UserActionTypes.USER_LOGOUT_SUCCESS;
}

export interface IUserRequestSuccess extends Action {
  type: UserActionTypes.USER_REQUEST_SUCCESS;
  payload: {
    userData: IUserData;
    userPermissions: string[];
  }
}

export interface IUserRegisterSuccess extends Action {
  type: UserActionTypes.USER_REGISTER_SUCCESS;
}

export interface IUserSetFCMToken extends Action {
  type: UserActionTypes.USER_SET_FCM_TOKEN;
  payload: { token: string };
}
export interface IUserFCMTokenSaved extends Action {
  type: UserActionTypes.USER_FCM_TOKEN_SAVED;
  payload: { success: boolean };
}

export interface IUserNewsletterSubscribed extends Action {
  type: UserActionTypes.USER_NEWSLETTER_SUBSCRIBED;
}

export interface ISetNumOfUnreadWebNotif extends Action {
  type: UserActionTypes.SET_NUM_OF_UNREAD_WEB_NOTIF;
  payload: {
    num: number;
  };
}

export type UserActions =
  | IUserRequestStart
  | IUserRequestSuccess
  | IUserLogout
  | IUserLogoutSuccess
  | IUserRegisterSuccess
  | IUserSetFCMToken
  | IUserFCMTokenSaved
  | IUserNewsletterSubscribed
  | ISetNumOfUnreadWebNotif
  | IUserRequestFail;
