
export function getDecimalSep(locale: string) {
  return (locale === 'en') ? '.' : ',';
}

export function formatDecimalNum(num: number, locale: string, decimals = 2) {
  const formattedNum = num.toFixed(decimals);

  return (getDecimalSep(locale) === ',') ? formattedNum.replace('.', ',') : formattedNum;
}
