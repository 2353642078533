import React from 'react';
import { i18n } from '../locales';
import { Container, Row, Col } from 'reactstrap';
import Typography, { tagMap } from '../components/Typography';
import { useSelector } from 'react-redux';
import { getIsNative } from '../store/clientSettings/selectors';

const NotFoundPage = () => {
  const isNativeApp = useSelector(getIsNative);
  return (
    <Container style={{ paddingTop: "300px", paddingBottom: "300px" }}>
      <Row>
        <Col></Col>
        <Col xs="auto">
          <Typography className={'text'} type={tagMap.h1}>{isNativeApp ? i18n._('Screen is missing or not implemented yet') : i18n._('404 | Page not found')}</Typography>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  )
};

export default NotFoundPage;