import { Reducer } from 'redux';
import { MatchCenterActions, MatchCenterActionTypes as actions, IMatchCenterState } from './types';

const initialState: IMatchCenterState = {
  sports: null,
  loading: false,
  failed: false,
};

const matchCenterReducer: Reducer<IMatchCenterState> = (
  state: IMatchCenterState = initialState,
  action
) => {
  switch ((action as MatchCenterActions).type) {
    case actions.MATCHCENTER_DATA_SUCCESS:
      return {
        ...state,
        sports: action.payload,
        loading: false,
      };
    case actions.MATCHCENTER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        failed: false,
      };
    case actions.MATCHCENTER_DATA_FAIL:
      return {
        ...state,
        loading: false,
        failed: true,
      };
    default:
      return state;
  }
};

export default matchCenterReducer;
