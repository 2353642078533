import React, { Component } from 'react';
import { IApplicationState } from "../../store/rootReducer";
import { connect } from "react-redux";
import withLocale from "../../hocs/withLocale";
import logo from '../../assets/img/logo.svg';
import facebookIcon from '../../assets/img/facebook.svg';
import youtubeIcon from '../../assets/img/youtube.svg';
import twitterIcon from '../../assets/img/twitter.svg';
import icon18 from '../../assets/img/18.svg';
import gambleIcon from '../../assets/img/gamble.svg';
import { isEmptyArray } from "formik";
import { IFooterItem, ISocialLink } from "../../store/clientSettings/types";
import { Container, Row, Col } from "reactstrap";
import { ROUTES } from "../../router/routes";
import bn from "../../utils/bemnames";
import { i18n } from '../../locales';
import SettingsButton from "./SettingsButton";

interface IProps {
  footerItems: IFooterItem[];
  socialLinks: ISocialLink[]
}

const bem = bn.create('footer');

const renderFooterLinks = (footerItems: IFooterItem[]) => (
  <Row>
    {!isEmptyArray(footerItems) && footerItems.map((footerItem, i) => (
      <Col key={`item-${i}`} xs={6} lg={6} className={bem.e('links-wrapper')}>
        <a className={bem.e('link')} target={footerItem.target} href={footerItem.trUrl} title={footerItem.title ? footerItem.title : ''}>{footerItem.trName}</a>
      </Col>
    ))}
  </Row>
);

const getSocialLinkUrlByName = (name: string, socialLinks: ISocialLink[]) => (
  Object.values(socialLinks).find(link => link.name === name)?.value
);

class Footer extends Component<IProps> {
  render() {
    const year = new Date().getFullYear();
    const { footerItems, socialLinks } = this.props;
    return (
      <footer className={bem.e('wrapper')}>
        <Container>
          <div className="pb-3 d-flex">
            <a href={ROUTES.GENERAL_ORIGIN}>
              <img src={logo} alt={logo} />
            </a>
            <div className="ml-auto">
              <SettingsButton />
            </div>
          </div>
          <Row>
            <Col lg={4} md={12}>
              <p className="mb-0">
                {i18n._('BetXpert was founded in 2001, and has since provided our readers with free betting tips written by our highly specialized expert panel. Our goal is to help our users beat the bookmakers through responsible valuebetting.')}
              </p>
              <div className="d-block d-lg-none">
                <hr className="divider" />
                {renderFooterLinks(footerItems)}
              </div>
              <hr className="divider" />
              <div className="text-center">
                <span className={bem.e('social-media')}><a href={getSocialLinkUrlByName('facebook_url', socialLinks)}><img src={facebookIcon} alt={facebookIcon} /></a></span>
                <span className={bem.e('social-media')}><a href={getSocialLinkUrlByName('twitter_url', socialLinks)}><img src={twitterIcon} alt={twitterIcon} /></a></span>
                <span className={bem.e('social-media')}><a href={getSocialLinkUrlByName('youtube_url', socialLinks)}><img src={youtubeIcon} alt={youtubeIcon} /></a></span>
              </div>
              <hr className="divider" />
              <div className="text-center pt-2">
                <p className="mb-0">{i18n._('We take Gambling Seriously')}</p>
              </div>
              <div className="text-center pt-3 pb-2">
                <span className="pr-3"><img src={icon18} alt={icon18} /></span>
                <span><img src={gambleIcon} alt={gambleIcon} /></span>
              </div>
              <hr className="divider" />
              <div className="text-center">
                <p className="mb-0">© 2001-{year} {i18n._('BetXpert • Responsible Gaming')}</p>
              </div>
            </Col>
            <Col lg={8} className="d-none d-lg-block">
              {renderFooterLinks(footerItems)}
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  const { clientSettings } = state;
  const { footerItems, socialLinks } = clientSettings;
  return {
    footerItems,
    socialLinks
  };
};

export default connect(
  mapStateToProps,
)(withLocale(Footer));
