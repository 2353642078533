import React from 'react';
import classnames from 'classnames';
import { Spinner } from 'reactstrap';

interface IProps {
  className?: string;
}

const LoadingSpinner: React.FC<IProps> = (props) => (
  <div className={classnames(['text-center', props.className])}>
    <Spinner color="primary" />
  </div>
);

export default LoadingSpinner;
