import { Reducer } from 'redux';
import { CreatePickActions, PickCreateTypes as actions, IPickCreateState } from './types';

const initialState: IPickCreateState = {
  step1: null,
  step2: null,
  step3: null,
};

const pickCreateReducer: Reducer<IPickCreateState> = (
  state: IPickCreateState = initialState,
  action
) => {
  switch ((action as CreatePickActions).type) {
    case actions.STEP1_COMPLETE:
      return {
        ...state,
        step1: action.payload,
        step2: null,
      };
    case actions.STEP2_COMPLETE:
      return {
        ...state,
        step2: action.payload,
      };
    case actions.STEP3_COMPLETE:
      return {
        ...state,
        step3: action.payload,
      };
    case actions.STEP1_REDIRECT:
      return {
        ...state,
        redirect: action.payload,
      };
    case actions.PICK_CREATE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case actions.PICK_CREATE_SUCCESS:
      return {
        ...state,
        step1: null,
        step2: null,
        step3: null,
        error: null,
        pickSlug: action.payload,
      }
    case actions.PICK_CREATE_FINISH:
      return {
        ...state,
        step1: null,
        step2: null,
        step3: null,
        error: null,
        pickSlug: null,
      }
    default:
      return state;
  }
};

export default pickCreateReducer;
