import { translatePath } from './routing';
import { Permissions } from "../config/Constants";
import { IUserState } from "../store/user/types";

export function getUserUrl(slug?: string) : string {
  return slug ? translatePath('/user') + '/' + slug : '';
}

export function canEditPick(user: IUserState, pickUserId: number, pickLangId: number) {
  return (user.isLoggedIn && ((user?.userData?.id === pickUserId && user?.userData?.languageId === pickLangId) || user?.userPermissions?.includes(Permissions.PICKS_MANAGE)));
}
