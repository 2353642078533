import { call, put } from "redux-saga/effects";
import api from '../api';
import { getUserData, initUserLanguage } from "./account";
import { masterDataRequest } from "../store/clientSettings/actions";
import { initFCM } from "./firebaseCM";
import { getCountriesData } from "./generalData";

export function* startup() {
  yield call(initUserLanguage);
  yield put(masterDataRequest());
  yield call(getUserData, api);
  yield call(initFCM, api);
  yield call(getCountriesData, api);

}
