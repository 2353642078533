import React from 'react';
import { i18n } from '../locales';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Form, FormGroup, Label, Input, FormFeedback, Button } from 'reactstrap';
import api from '../api';
import { ApiResponse } from 'apisauce';
import { Link } from 'react-router-dom';
import { ROUTES } from '../router/routes';
import { toggleLoginModal } from '../store/clientSettings/actions';
import toastr from 'toastr';
import BackBtn from './BackBtn';

const schema = yup.object({
  name: yup.string().required().label(i18n._('Email or Username')),
});

interface IProps {
  toggleLoginModal?: typeof toggleLoginModal;
  onBackClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
}

class ForgotPasswordForm extends React.Component<IProps> {
  public readonly state = {
    submittedName: '',
    resent: false,
  };

  handleSubmit = async (values, { setErrors }) => {
    const resp: ApiResponse<any> = await api.passwordResetRequest(values);
    if (resp.ok) {
      this.setState({
        submittedName: values.name,
      });
    } else {
      setErrors({
        name: i18n._('User with such email or username does not exist'),
      });
    }
  };

  handleResendClick = async () => {
    const resp: ApiResponse<any> = await api.passwordResetRequest({
      name: this.state.submittedName,
    });
    if (resp.ok) {
      this.setState({
        resent: true,
      });
      toastr.success(i18n._('Email was successfully resent'));
    }
  };

  render() {
    const { submittedName, resent } = this.state;

    return (
      <div className="mx-auto mw-500px">
        <div className="mb-3">
          <BackBtn onClick={this.props.onBackClick} />
        </div>
        {submittedName ? (
          <React.Fragment>
            <div className="h2">{i18n._('Check your email')}</div>
            <p className="text-muted">
              {i18n._("If you can't find our message, it might be in your spam/junk. The email expires in 24 hours.")}
            </p>
            {!resent && (
              <Button color="primary" block onClick={this.handleResendClick}>
                {i18n._('Resend')}
              </Button>
            )}
            <Link
              className="btn btn-outline-primary btn-block mt-3"
              to={ROUTES.GENERAL_ORIGIN}
              onClick={this.props.toggleLoginModal}
            >
              {i18n._('Return home')}
            </Link>
          </React.Fragment>
        ) : (
            <React.Fragment>
              <div className="h2">{i18n._('Forgot your password?')}</div>
              <p className="text-muted">
                {i18n._('Don’t worry. Resetting your password is easy. Just tell us your email address or username.')}
              </p>
              <Formik
                validationSchema={schema}
                onSubmit={this.handleSubmit}
                initialValues={{
                  name: '',
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <FormGroup>
                        <Label for="name">{i18n._('Email or Username')}</Label>
                        <Input
                          type="text"
                          id="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={touched.name && !!errors.name}
                        />
                        <FormFeedback>{errors.name}</FormFeedback>
                      </FormGroup>
                      <Button type="submit" color="primary" block>{i18n._('Continue')}</Button>
                    </Form>
                  )}
              </Formik>
            </React.Fragment>
          )}
      </div>
    );
  }
}

export default ForgotPasswordForm;
