import { i18n } from '../locales';
import { t } from '@lingui/macro';
import { ApiResponse } from 'apisauce';
import { call, put, select } from 'redux-saga/effects';
import * as firebase from 'firebase/app';
import 'firebase/firebase-messaging';
import 'firebase/analytics';
import { firebaseConfig, messagingVapidPublicKey } from '../firebaseConfig.json';

import toastr from 'toastr';
import API from '../api';
import { userFCMTokenSaved, userSetFCMToken } from '../store/user/actions';
import { getUserFCMtoken, getUserLoggedIn } from '../store/user/selectors';


export function* initFCM(api: typeof API) {

  const firebaseApp = firebase.initializeApp(firebaseConfig);
  firebaseApp.analytics();

  let firebaseMessaging = null;

  if (firebase.messaging.isSupported()) {
    try {
      // Retrieve Firebase Messaging object.
      firebaseMessaging = firebaseApp.messaging();

      let token = yield firebaseMessaging.getToken({ vapidKey: messagingVapidPublicKey });
      if (token) {
        yield put(userSetFCMToken(token));

        yield call(sendFCMtoken, api);
      }

    } catch (e) {
      toastr.error(i18n._(t`Unable init FCM`));
    }

    // foreground message handler
    if (firebaseMessaging) {
      firebaseMessaging.onMessage((payload) => {
        toastr.info(payload.notification.body, payload.notification.title);
      });
    }
  }


}

export function* sendFCMtoken(api: typeof API) {

  let token = yield select(getUserFCMtoken);

  let userLoggedIn: boolean = yield select(getUserLoggedIn);
  if (userLoggedIn && token) {
    let tokenResponse: ApiResponse<any> = yield call(api.postFCMtoken, { token });

    if (tokenResponse.ok) {
      yield put(userFCMTokenSaved(true));
    } else {
      yield put(userFCMTokenSaved(false));
    }
  }
}
