import { i18n } from '../locales';
import { IWebNotification, WebNotifEntityType, WebNotifType, ICampaign } from './types';
import { getThreadEntityUrl, getThreadEntityImageSrc, getThreadEntityTitle } from './discussion';
import { getCampaignUrl } from './campaign';
import { getOperatorLogoSrc, OperatorLogoType } from "./operator";

export function getEntityUrl(notification: IWebNotification) : string {
  const { entity } = notification;
  if (entity) {
    switch (notification.entityType) {
      case WebNotifEntityType.THREAD:
        return getThreadEntityUrl(entity);

      case WebNotifEntityType.CAMPAIGN:
        const campaign: ICampaign = entity;
        return getCampaignUrl(campaign.data?.slug);
    }
  }

  return '';
}

export function getEntityImageSrc(notification: IWebNotification) : string {
  const { entity } = notification;
  if (entity) {
    switch (notification.entityType) {
      case WebNotifEntityType.THREAD:
        return getThreadEntityImageSrc(entity);

      case WebNotifEntityType.CAMPAIGN:
        const campaign: ICampaign = entity;
        return getOperatorLogoSrc(campaign.operator, OperatorLogoType.Square);
    }
  }

  return '';
}

export function getEntityTitle(notification: IWebNotification) : string {
  const { entity } = notification;
  if (entity) {
    switch (notification.entityType) {
      case WebNotifEntityType.THREAD:
        return getThreadEntityTitle(entity);

      case WebNotifEntityType.CAMPAIGN:
        const campaign: ICampaign = entity;
        return i18n._('{campaignTitle}. Get it now', { campaignTitle: campaign.data?.name });
    }
  }

  return '';
}

export function getNotifDescription(notification: IWebNotification) : string {
  switch (notification.entityType) {
    case WebNotifEntityType.THREAD:
      switch (notification.type) {
        case WebNotifType.COMMENT:
          return i18n._('Thread was commented');
      }
      break;
    case WebNotifEntityType.CAMPAIGN:
      switch (notification.type) {
        case WebNotifType.NEW:
          return i18n._('New campaign awaits');
      }
      break;
  }

  return i18n._('Notification');
}
