import React from 'react';

//styles
import { options } from 'toastr';
import 'toastr/build/toastr.css';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/main.scss';

//redux
import { Provider } from 'react-redux';
import store from './store';

import RootContainer from './router/RootContainer';
import LocaleProvider from './router/LocaleProvider';

options.timeOut = 2000;
options.positionClass = 'toast-bottom-right';

export const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <LocaleProvider>
          <RootContainer />
        </LocaleProvider>
      </Provider>
    );
  }
}
export default App;
