import React from 'react';
import { Input } from 'reactstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { i18n } from '../locales';
import classnames from 'classnames';

interface IProps {
  id?: string;
  value?: string;
  placeholder?: string;
  onChange?: any;
  onBlur?: any;
  invalid?: boolean;
}

class PasswordInput extends React.Component<IProps, {}> {
  public readonly state = {
    isPasswordVisible: false,
  };

  toggleVisible = () => {
    this.setState((prevState: any) => ({
      isPasswordVisible: !prevState.isPasswordVisible,
    }));
  };

  render() {
    const { id, value, placeholder, onChange, onBlur, invalid } = this.props;
    const { isPasswordVisible } = this.state;

    const FaIcon = isPasswordVisible ? FaEyeSlash : FaEye;
    const faTitle = isPasswordVisible ? i18n._('Hide password') : i18n._('Show password');

    return (
      <div className={classnames([
        'position-relative',
        invalid && 'is-invalid',
      ])}>
        <Input
          className="has-input-right-icon"
          type={isPasswordVisible ? 'text' : 'password'}
          id={id || 'password'}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          invalid={invalid}
        />
        <FaIcon
          className="input-right-icon can-click"
          title={faTitle}
          onClick={this.toggleVisible}
        />
      </div>
    );
  }
}

export default PasswordInput;
