import { i18n } from '../locales';
import { t } from '@lingui/macro';
import { ApiResponse } from 'apisauce';
import { call, put, select } from 'redux-saga/effects';
import toastr from 'toastr';
import API from '../api';
import { createPickFail, createPickSuccess, createPickFinish } from '../store/pickCreation/actions';
import { getStep1Data, getStep2Data, getStep3Data } from '../store/pickCreation/selectors';
import { IStep1Data, IStep2Data, IStep3Data } from '../store/pickCreation/types';
import { toUtcTime } from '../utils/dateTime';

export function* publishPick(api: typeof API) {

  let pickPostData: any = {};

  let step1Data: IStep1Data = yield select(getStep1Data);

  if (!step1Data.isManual) {
    pickPostData.eventId = step1Data.event.id;
  } else {
    pickPostData.regionId = step1Data.region.id;
    pickPostData.sportId = step1Data.sport.id;
    pickPostData.competitionName = step1Data.competition.value;
    pickPostData.eventName = step1Data.eventName;
    pickPostData.startTime = toUtcTime(step1Data.startTime);
  }

  let step2Data: IStep2Data = yield select(getStep2Data);

  pickPostData = { ...pickPostData, ...step2Data.values };
  pickPostData.pickObject = step2Data.values.pickObject?.value;
  pickPostData.pickValue = step2Data.values.pickValue?.value;
  pickPostData.line = step2Data.values.line?.value;
  pickPostData.operatorId = step2Data.values.operator.id;
  pickPostData.pickType = step2Data.values.pickType.id;

  let step3Data: IStep3Data = yield select(getStep3Data);

  pickPostData = { ...pickPostData, ...step3Data }

  let pickResponse: ApiResponse<{ slug: string }> = yield call(api.postPick, pickPostData);

  if (pickResponse.ok) {
    yield put(createPickSuccess(pickResponse.data.slug));
    yield put(createPickFinish());
  } else {
    yield put(createPickFail(pickResponse.problem));
  }
}

export function* savePick(api: typeof API, action: any) {
  let slug = action.payload;

  let pickPostData: any = { slug };
  let step3Data: IStep3Data = yield select(getStep3Data);

  pickPostData = { ...pickPostData, ...step3Data }

  let pickResponse: ApiResponse<any> = yield call(api.postPickEdit, pickPostData);

  if (pickResponse.ok) {
    yield put(createPickSuccess(slug));
    yield put(createPickFinish());
  } else {
    yield put(createPickFail(pickResponse.problem));
    toastr.error(i18n._(t`Failed to save a pick`));
  }
}
