import React from 'react';
import { i18n } from '../locales';
import { FaChevronLeft } from 'react-icons/fa';

interface IProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

class BackBtn extends React.Component<IProps> {
  render() {
    return (
      <button className="back-btn" type="button" onClick={this.props.onClick}>
        <FaChevronLeft />
        <span className="ml-1 align-middle">{i18n._('Back')}</span>
      </button>
    );
  }
}

export default BackBtn;
