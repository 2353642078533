import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { MdSettings } from "react-icons/all";
import withLocale from "../../hocs/withLocale";
import { ButtonGroup, Button, Popover, PopoverBody } from "reactstrap";
import { getWeatherForecast } from "../../utils/localStorage";
import Constants from "../../config/Constants";
import { SelectTimezone } from '@capaj/react-select-timezone';
import { i18n } from '../../locales';
import { IApplicationState } from '../../store/rootReducer';
import { setTimeZone, setOddsFormat } from '../../store/clientSettings/actions';

interface IProps {
  timeZone: string;
  setTimeZone: typeof setTimeZone;
  oddsFormat: string;
  setOddsFormat: typeof setOddsFormat;
}

class SettingsButton extends Component<IProps> {
  public readonly state = {
    active: false,
    weatherForecast: getWeatherForecast(),
  };

  toggleActive = () => {
    this.setState((prevState: any) => ({
      active: !prevState.active,
    }));
  };

  onClickOddsFormatButtonHandler = (oddsFormat: string) => {
    if (this.props.oddsFormat !== oddsFormat) {
      localStorage.setItem('oddsFormat', oddsFormat);
      this.props.setOddsFormat(oddsFormat);
    }
  };

  onClickWeatherForecastButtonHandler = (weatherForecast: string) => {
    if (localStorage.getItem('weatherForecast') !== weatherForecast) {
      localStorage.setItem('weatherForecast', weatherForecast);
      this.setState({
        weatherForecast
      })
    }
  };

  onChangeTimeZoneHandler = timeZone => {
    localStorage.setItem('timeZone', timeZone);
    this.props.setTimeZone(timeZone);
  };

  render() {
    const { timeZone, oddsFormat } = this.props;
    const { active, weatherForecast } = this.state;

    return (
      <Fragment>
        <button id="settingsPopover" className={`d-flex grey-button ${active ? 'active' : ''}`} onClick={this.toggleActive}>
          <MdSettings size={20} />
          <span className="ml-1">{active ? 'Save and Close' : 'Settings'}</span>
        </button>
        <Popover target="settingsPopover" placement="bottom" isOpen={active} className="settings">
          <PopoverBody className="py-3 px-2">
            <p className="muted mb-1">{i18n._('Odds Format')}</p>
            <ButtonGroup size="sm" className="mb-3 w-100">
              <Button
                color="primary"
                outline={oddsFormat !== Constants.ODDS_FORMAT_DECIMAL}
                className="w-50"
                onClick={() => this.onClickOddsFormatButtonHandler(Constants.ODDS_FORMAT_DECIMAL)}
              >
                {i18n._('decimal')}
              </Button>
              <Button
                color="primary"
                outline={oddsFormat !== Constants.ODDS_FORMAT_FRACTIONAL}
                className="w-50"
                onClick={() => this.onClickOddsFormatButtonHandler(Constants.ODDS_FORMAT_FRACTIONAL)}
              >
                {i18n._('fractional')}
              </Button>
            </ButtonGroup>
            <p className="muted mb-1">{i18n._('Weather Forecast')}</p>
            <ButtonGroup size="sm" className="mb-3 w-100">
              <Button
                color="primary"
                outline={weatherForecast !== Constants.WEATHER_FORECAST_CELSIUS}
                className="w-50"
                onClick={() => this.onClickWeatherForecastButtonHandler(Constants.WEATHER_FORECAST_CELSIUS)}
              >
                {i18n._('Celsius')}
              </Button>
              <Button
                color="primary"
                outline={weatherForecast !== Constants.WEATHER_FORECAST_FAHRENHEIT}
                className="w-50"
                onClick={() => this.onClickWeatherForecastButtonHandler(Constants.WEATHER_FORECAST_FAHRENHEIT)}
              >
                {i18n._('Fahrenheit')}
              </Button>
            </ButtonGroup>
            <p className="muted mb-1">{i18n._('Time Zone')}</p>
            <div className="select-wrapper">
              <SelectTimezone
                value={timeZone}
                onChange={val => this.onChangeTimeZoneHandler(val)}
              />
            </div>
          </PopoverBody>
        </Popover>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  const { clientSettings } = state;

  return {
    timeZone: clientSettings.timeZone,
    oddsFormat: clientSettings.oddsFormat,
  };
};

const mapDispatchToProps = (dispatch: (arg0: any) => any) => {
  return {
    setTimeZone: (timeZone: string) => dispatch(setTimeZone(timeZone)),
    setOddsFormat: (oddsFormat: string) => dispatch(setOddsFormat(oddsFormat)),
  };
};

export default (withLocale(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SettingsButton)
));
