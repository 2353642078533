import React from 'react';
import { i18n } from '../../locales';
import { FaQuoteRight } from 'react-icons/fa';
import bn from '../../utils/bemnames';

const bem = bn.create('richtexteditor');

interface IProps {
  username: string;
  n: number;
  children: any;
}

const Quote: React.FC<IProps> = ({ username, n, children }) => {
  return (
    <div className={bem.e('quote')}>
      <div className={bem.e('quote-title')}>
        {i18n._('{username} wrote in the post #{n}', { username, n })}
      </div>
      <div className={bem.e('quote-text')}>
        {children}
      </div>
      <FaQuoteRight className={bem.e('quote-icon')} />
    </div>
  );
};

export default Quote;
