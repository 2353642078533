import { i18n } from "../locales";
import { capitalize } from "./string";
import { translatePath } from './routing';
import { PickType, Sports } from '../config/Constants';

export const pickShowTitle = (title: string) => {
  const titleArr = title.split(':');
  const appendix = titleArr[1].trim();
  let returnObj = {
    title: '',
    appendix: '',
  };
  if (appendix.length > 2) {
    returnObj.title = titleArr[0];
    returnObj.appendix = appendix;
  } else {
    returnObj.title = title;
    returnObj.appendix = null;
  }

  return returnObj;
};

export const pickShowChances = (chances: object) => {
  let pickType = '';
  let chanceStr = '';
  Object.keys(chances).forEach(key => {
    pickType += pickType !== '' ? '-' : '';
    chanceStr += chanceStr !== '' ? '-' : '';
    pickType += capitalize(i18n._(chances[key].chanceName.replace('chance_', '')));
    chanceStr += chances[key].chanceValue;
  });

  return {
    pickType,
    chanceStr
  }
};

export function getPickUrl(slug: string): string {
  return translatePath('/picks') + '/' + slug;
}

export function getPickEditUrl(slug: string): string {
  return translatePath('/pick/edit') + '/' + slug;
}

export function getPickImageSrc(imageHash: string): string {
  return `/uploads/picks/600x400/${imageHash}.jpg`;
}

export enum PickStatus {
  PENDING = 'pending',
  INACTIVE = 'inactive',
  WIN = 'win',
  LOSS = 'loss',
  HALFLOSS = 'halfloss',
  HALFWIN = 'halfwin',
  VOID = 'void',
  CANCEL = 'cancel',
  N_A = 'N/A',
}

export enum UserType {
  EXPERT = 'expert',
  USER = 'user',
}

export enum PickAdminApproval {
  VALID = 'valid',
  HALF_VALID = 'half-valid',
  INVALID = 'invalid',
  SUPPLEMENTAL = 'supplemental',
  NOT_VALIDATED = 'not_validated',
}

const expertPickTypesView = [
  { id: PickType.TYPE_1X2, trName: i18n._('1X2') },
  { id: PickType.TYPE_OU, trName: i18n._('Over/Under') },
  { id: PickType.TYPE_AH, trName: i18n._('Asian Handicap') },
  { id: PickType.TYPE_EH, trName: i18n._('European Handicap') },
  { id: PickType.TYPE_DC, trName: i18n._('Double Chance') },
  { id: PickType.TYPE_DNB, trName: i18n._('Draw No Bet') },
  { id: PickType.TYPE_BTS, trName: i18n._('Both Teams to Score') },
  { id: PickType.TYPE_HT_FT, trName: i18n._('Half Time/Full Time') },
  { id: PickType.TYPE_OE, trName: i18n._('Odd/Even') },
  { id: PickType.TYPE_CS, trName: i18n._('Correct Score') },
  { id: PickType.TYPE_E_W, trName: i18n._('Each-way') },
  { id: PickType.TYPE_ANY, trName: i18n._('Anytime Goalscorer') },
  { id: PickType.TYPE_1ST, trName: i18n._('First Goalscorer') },
  { id: PickType.TYPE_LAST, trName: i18n._('Last Goalscorer') },
  { id: PickType.TYPE_12, trName: i18n._('12') },
  { id: PickType.TYPE_OTHER, trName: i18n._('Other') },
  { id: PickType.TYPE_INFO, trName: i18n._('Info') },
  { id: PickType.TYPE_HNB, trName: i18n._('Home No Bet') },
  { id: PickType.TYPE_ANB, trName: i18n._('Away No Bet') },
];

// Users can't create 'Info' picks.
const userPickTypesView = expertPickTypesView.filter(item => item.id !== PickType.TYPE_INFO);

export function getPickTypesView(isExpert: boolean) {
  return isExpert ? expertPickTypesView : userPickTypesView;
}

export function getPickSportBgSrc(sportId : number | null) : string {
  let file;
  switch (sportId) {
    case Sports.AmericanFootball:
      file = 'american-football';
      break;
    case Sports.Athletics:
      file = 'athletics';
      break;
    case Sports.Baseball:
      file = 'baseball';
      break;
    case Sports.Basketball:
      file = 'basketball';
      break;
    case Sports.Cycling:
      file = 'cycling';
      break;
    case Sports.Entertainment:
      file = 'entertainment';
      break;
    case Sports.eSports:
    case Sports.Dota2:
    case Sports.CounterStrike:
    case Sports.Overwatch:
      file = 'esports';
      break;
    case Sports.Football:
      file = 'football';
      break;
    case Sports.Golf:
      file = 'golf';
      break;
    case Sports.Handball:
      file = 'handball';
      break;
    case Sports.IceHockey:
      file = 'ice-hockey';
      break;
    case Sports.Politics:
      file = 'politics';
      break;
    case Sports.Biathlon:
      file = 'skiing';
      break;
    case Sports.Tennis:
      file = 'tennis';
      break;
    case Sports.Swimming:
    case Sports.WaterPolo:
      file = 'water-sport';
      break;
    default:
      file = 'other-generic';
      break;
  }

  return '/images/min/pick-sports/' + file + '.jpg';
}

export function getTrPickTypeByPickType(pickTYpe: string) : string {
  const pickTypeArr = expertPickTypesView.filter(item => item.id === pickTYpe);
  return pickTypeArr.length ? pickTypeArr[0].trName : i18n._('Unknown type');
}