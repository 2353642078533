import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { i18n } from '../locales';
import RequireAuth from '../components/RequireAuth';

interface ITranslatedRouteAdditionalProps {
  pathKey: string;
  paths?: string | string[];
  authRequired?: boolean;
}

export const translatedRoute = ({ component, pathKey, paths, authRequired }: RouteProps & ITranslatedRouteAdditionalProps) => {
  if (!component) {
    return null
  }
  if (typeof paths === 'string') {
    paths = [paths];
  }
  let currentLanguage = i18n.language;
  let mainRedirect = translatePath(pathKey);
  let routes = [];

  if (authRequired) {
    routes.push(
      <Route key={pathKey} path={mainRedirect} exact render={() => (
        <RequireAuth>
          <Route path={mainRedirect} exact component={component} />
        </RequireAuth>
      )} />

    );
  } else {
    routes.push(<Route key={pathKey} path={mainRedirect} exact component={component} />)
  }

  let keyRedirect = `/${currentLanguage}${pathKey}`;
  if (keyRedirect !== mainRedirect) {
    routes.push(<Redirect exact key={pathKey + 'O'} path={keyRedirect} to={mainRedirect} />)
  }

  // aliases redirects to main route for correct language and for original
  if (paths)
    for (let additionalRedirect of paths) {
      let additionalRedirectTranslated = '';
      additionalRedirect.split('/').map(part => additionalRedirectTranslated += i18n._(part) + '/');
      routes.push(<Redirect exact key={additionalRedirect + 'AliasT'} path={`/${currentLanguage}${additionalRedirectTranslated}`} to={mainRedirect} />);
      routes.push(<Redirect exact key={additionalRedirect + 'AliasO'} path={`/${currentLanguage}${additionalRedirect}`} to={mainRedirect} />);
    }
  return routes;
}

export const translatePath = (path: string): string => {
  let output = `/${i18n.language}`;
  path.split('/').map(part => output += i18n._(part) + '/');
  output = output.slice(0, output.length - 1);
  return output;
}