import { Action } from 'redux';
import { ISport } from '../../utils/types';
import { EventStatus } from '../../utils/event';

export interface IPickCreateState {
  step1?: IStep1Data;
  step2?: IStep2Data;
  step3?: IStep3Data;
  pickSlug?: string;
  error?: string;
  redirect?: boolean;
}

export interface IStep1Data {
  isManual: boolean;
  event?: IEvent;
  eventName?: string;
  competition?: { value, label };
  region?: IRegion;
  sport?: ISport;
  startTime?: string;
}

export interface IStep2Data {
  isManual: boolean;
  values: any;
}

export interface IStep3Data {
  eventNote: string;
  bulletsFor: string[];
  bulletsAgainst: string[];
  content: string;
}

export interface IEvent {
  id: number;
  name: string;
  startTime: string;
  status: EventStatus;
  tournamentStageId: number;
  tournamentStage: ITournamentStage;
  trName: string;
}

export interface ITournamentStage {
  endTime: string;
  gender: 'm' | 'f';
  id: number;
  name: string;
  regionId: number;
  startTime: string;
  tournamentId: number;
  tournament: ITournament;
}

export interface ITournament {
  id: number;
  name: string;
  competitionId: number;
  competition: ICompetition;
}

export interface ICompetition {
  id: number;
  name: string;
  gender?: 'm' | 'f';
  parentId?: number | null;
  regionId?: number;
  region?: IRegion;
  sportId: number;
  sport?: ISport;
  trName: string
}

export interface IRegion {
  id: number;
  name: string;
  trName: string;
}

export interface IOperator {
  id: number;
  name: string;
  trName: string;
  slug: string;
  priority: number;
}

export enum PickCreateTypes {
  STEP1_COMPLETE = 'STEP1_COMPLETE',
  STEP2_COMPLETE = 'STEP2_COMPLETE',
  STEP3_COMPLETE = 'STEP3_COMPLETE',
  STEP1_REDIRECT = 'STEP1_REDIRECT',
  PICK_CREATE_REQUEST = 'PICK_CREATE_REQUEST',
  PICK_SAVE_REQUEST = 'PICK_SAVE_REQUEST',
  PICK_CREATE_SUCCESS = 'PICK_CREATE_SUCCESS',
  PICK_CREATE_FINISH = 'PICK_CREATE_FINISH',
  PICK_CREATE_FAIL = 'PICK_CREATE_FAIL',
}

export interface ICreatePickStep1 extends Action {
  type: PickCreateTypes.STEP1_COMPLETE,
  payload: IStep1Data
}
export interface ICreatePickStep2 extends Action {
  type: PickCreateTypes.STEP2_COMPLETE,
  payload: IStep2Data
}
export interface ICreatePickStep3 extends Action {
  type: PickCreateTypes.STEP3_COMPLETE,
  payload: IStep3Data
}
export interface ICreatePickSetRedirect extends Action {
  type: PickCreateTypes.STEP1_REDIRECT,
  payload: boolean
}
export interface ICreatePickRequest extends Action {
  type: PickCreateTypes.PICK_CREATE_REQUEST,
}
export interface ISavePickRequest extends Action {
  type: PickCreateTypes.PICK_SAVE_REQUEST,
  payload: string,
}
export interface ICreatePickSuccess extends Action {
  type: PickCreateTypes.PICK_CREATE_SUCCESS,
  payload: string,
}
export interface ICreatePickFinish extends Action {
  type: PickCreateTypes.PICK_CREATE_FINISH,
}
export interface ICreatePickFail extends Action {
  type: PickCreateTypes.PICK_CREATE_FAIL,
  payload: string,
}

export type CreatePickActions =
  | ICreatePickFinish
  | ICreatePickRequest
  | ISavePickRequest
  | ICreatePickSuccess
  | ICreatePickFail
  | ICreatePickStep3
  | ICreatePickStep2
  | ICreatePickStep1
  | ICreatePickSetRedirect;
