import React from 'react';
import { i18n } from '../locales';
import { Alert } from 'reactstrap';

interface IProps {
  className?: string;
}

const FailedToLoadData: React.FC<IProps> = ({ className }) => (
  <Alert className={className || 'mt-3'} color="danger">
    {i18n._('Failed to load data.')}
  </Alert>
);

export default FailedToLoadData;
