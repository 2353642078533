import React from 'react';
import { connect } from 'react-redux';
import { i18n } from '../locales';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Form, FormGroup, Label, Input, CustomInput, FormFeedback, Button, Row, Col } from 'reactstrap';
import PasswordInput from './PasswordInput';
import { userFetchStart } from '../store/user/actions';
import { toggleLoginModal } from '../store/clientSettings/actions';
import api from '../api';
import { ApiResponse } from 'apisauce';
import { getErrorsFromApiResp } from '../utils/apiUtils';
import ForgotPasswordForm from './ForgotPasswordForm';
import { FaFacebookF, FaApple, FaTwitter } from 'react-icons/fa';
import googleIcon from '../assets/img/google.svg';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import AppleSignin from 'react-apple-signin-auth';
import TwitterLogin from 'react-twitter-auth';
import AppConfig from '../config/AppConfig';
import RegisterForm from './RegisterForm';
import BackBtn from './BackBtn';
import { IApplicationState } from '../store/rootReducer';
import NativeCommandManager from '../utils/nativeAppCommands';
import { INativeCommandResponceData, NativeCommandType } from '../store/clientSettings/types';
import toastr from 'toastr';

const schema = yup.object({
  email: yup.string().required().max(199).email().label(i18n._('Email')),
  password: yup.string().required().max(50).label(i18n._('Password')),
  keepMeLoggedIn: yup.bool(),
});

const linkSocialSchema = yup.object({
  email: yup.string().required().max(199).email().label(i18n._('Email')),
  password: yup.string().required().max(50).label(i18n._('Password')),
});

interface IProps {
  isModal?: boolean;
  isNativeApp: boolean;
  nativeCommandResponceData?: INativeCommandResponceData;
  userFetchStart: typeof userFetchStart;
  toggleLoginModal: typeof toggleLoginModal;
}

class LoginForm extends React.Component<IProps> {
  public readonly state = {
    showForgotPassword: false,
    showSocialRegisterForm: false,
    socialService: '',
    socialServiceLabel: '',
    socialEmail: '',
    accessToken: '',
  };

  componentDidUpdate = (oldProps: IProps) => {
    const props = this.props;
    if (props.nativeCommandResponceData && oldProps.nativeCommandResponceData !== props.nativeCommandResponceData) {
      let commandData = props.nativeCommandResponceData;
      switch (commandData.type) {
        case NativeCommandType.FBlogin:
          if (commandData.responceType === 'success') {
            this.facebookResponse(commandData.responce);
          } else if (commandData.responceType === 'error') {
            toastr.error(i18n._('Login failed'));
          }
          break;
        case NativeCommandType.GoogleLogin:
          if (commandData.responceType === 'success') {
            this.googleResponse(commandData.responce);
          } else if (commandData.responceType === 'error') {
            toastr.error(i18n._('Login failed'));
          }
          break;
        case NativeCommandType.TwitterLogin:
          if (commandData.responceType === 'success') {
            const { oauth_token, oauth_token_secret } = commandData.responce;
            const accessToken = oauth_token + ' ' + oauth_token_secret;
            this.socialServiceResponse(accessToken, 'twitter', 'Twitter');
          } else if (commandData.responceType === 'error') {
            this.twitterFailure();
            toastr.error(i18n._('Login failed'));
          }
          break;
        case NativeCommandType.AppleLogin:
          if (commandData.responceType === 'success') {
            this.appleResponse(commandData.responce);
          } else if (commandData.responceType === 'error') {
            toastr.error(i18n._('Login failed'));
          }
          break;
        case NativeCommandType.BiometricsAuth:
          if (commandData.responceType === 'success') {
            // remember me autologin should be here
          } else if (commandData.responceType === 'error') {
            // fail or retry should be here
          }
          break;
        default:
        //command type was not recognized
      }
    }
  }

  handleSubmit = async (values: any, { setErrors }) => {
    await api.csrfCookie();
    const loginResp: ApiResponse<any> = await api.login({
      email: values.email,
      password: values.password,
      rememberMe: values.keepMeLoggedIn,
    });
    if (loginResp.ok) {
      this.handleLoginSuccess();
    } else {
      setErrors(getErrorsFromApiResp(loginResp, schema));
    }
  };

  handleLoginSuccess = () => {
    this.props.userFetchStart();
    if (this.props.isModal) {
      this.props.toggleLoginModal();
    }
  };

  handleForgotPasswordClick = () => {
    this.setState({
      showForgotPassword: true,
    });
  };

  handleForgotPasswordBackClick = () => {
    this.setState({
      showForgotPassword: false,
    });
  };

  facebookResponse = (facebookResp) => {
    const { accessToken } = facebookResp;
    this.socialServiceResponse(accessToken, 'facebook', 'Facebook');
  };

  googleResponse = (googleResp) => {
    const { accessToken } = googleResp;
    this.socialServiceResponse(accessToken, 'google', 'Google');
  };

  appleResponse = (appleResp) => {
    this.socialServiceResponse(appleResp.authorization.id_token, 'apple', 'Apple');
  };

  twitterResponse = async (response) => {
    const { oauth_token, oauth_token_secret } = await response.json();
    const accessToken = oauth_token + ' ' + oauth_token_secret;
    this.socialServiceResponse(accessToken, 'twitter', 'Twitter');
  };

  twitterFailure = () => { };

  socialServiceResponse = async (accessToken, socialService, socialServiceLabel) => {
    if (accessToken) {
      await api.csrfCookie();
      const socialLoginResp: ApiResponse<any> = await api.socialLogin({
        accessToken,
        type: socialService,
      });
      if (socialLoginResp.ok) {
        const respData = socialLoginResp.data;
        if (respData.userFound) {
          this.handleLoginSuccess();
        } else {
          // A User with such social account does not exist in Betxpert DB.
          // The user needs to fill out the registration form.
          this.setState({
            showSocialRegisterForm: true,
            socialService,
            socialServiceLabel,
            socialEmail: respData.socialUserData.email,
            accessToken,
          });
        }
      } else {
        toastr.error(i18n._('Login failed'));
      }
    }
  };

  handleSocialRegisterSuccess = async () => {
    const { socialService, accessToken } = this.state;

    await api.socialLogin({
      accessToken,
      type: socialService,
    });

    this.handleLoginSuccess();
  };

  handleSocialRegisterBackBtnClick = () => {
    this.setState({
      showSocialRegisterForm: false,
    });
  };

  handleLinkSocialSubmit = async (values, { setErrors }) => {
    const { socialService, accessToken } = this.state;

    await api.csrfCookie();
    const linkSocialResp: ApiResponse<any> = await api.linkSocial({
      ...values,
      socialService,
      accessToken,
    });
    if (linkSocialResp.ok) {
      this.handleLoginSuccess();
    } else {
      setErrors(getErrorsFromApiResp(linkSocialResp, linkSocialSchema));
    }
  };

  render() {
    const { isModal, toggleLoginModal, isNativeApp } = this.props;
    const { showForgotPassword, showSocialRegisterForm, socialService, socialServiceLabel, socialEmail, accessToken } = this.state;

    if (showForgotPassword) {
      return (
        <ForgotPasswordForm
          toggleLoginModal={isModal && toggleLoginModal}
          onBackClick={this.handleForgotPasswordBackClick}
        />
      );
    }

    if (showSocialRegisterForm) {
      return (
        <div className="mx-auto mw-500px">
          <div className="mb-3">
            <BackBtn onClick={this.handleSocialRegisterBackBtnClick} />
          </div>
          <div className="h2">{i18n._('Create BetXpert profile via {service} account', { service: socialServiceLabel })}</div>
          <p>
            {i18n._('Please choose fill out the form for your BetXpert user profile, which will be used when you participate in debates, competitions etc here at BetXpert.')}
          </p>
          <RegisterForm
            socialService={socialService}
            socialEmail={socialEmail}
            accessToken={accessToken}
            onSuccessSubmit={this.handleSocialRegisterSuccess}
          />
          <div className="h2 mt-4">{i18n._('Do you have a BeXpert account already?')}</div>
          <p>
            {i18n._('If you already have a BetXpert user profile, you can link your {service} account to it.', { service: socialServiceLabel })}
            {' '}
            {i18n._('Enter username and password for of your BetXpert profile, and then we will be able to link it with your {service} account.', { service: socialServiceLabel })}
            {' '}
            {i18n._("After that, you can log in directly to your BetXpert profile via the 'Log in using {service}' feature.", { service: socialServiceLabel })}
          </p>
          <Formik
            validationSchema={linkSocialSchema}
            onSubmit={this.handleLinkSocialSubmit}
            initialValues={{
              email: '',
              password: '',
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label for="email">{i18n._('Email')}</Label>
                    <Input
                      type="text"
                      id="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.email && !!errors.email}
                    />
                    <FormFeedback>{errors.email}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="password">{i18n._('Password')}</Label>
                    <PasswordInput
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.password && !!errors.password}
                    />
                    <FormFeedback>{errors.password}</FormFeedback>
                  </FormGroup>
                  <Button type="submit" color="primary" block>
                    {i18n._('Link profile with {service}', { service: socialServiceLabel })}
                  </Button>
                </Form>
              )}
          </Formik>
        </div>
      );
    }

    return (
      <div className="mx-auto mw-500px">
          <>
            <div className="label mb-1">{i18n._('You can also login with')}</div>
            <Row>
              <Col>
              {!isNativeApp ?
                  <FacebookLogin
                    appId={AppConfig.facebookClientId}
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={this.facebookResponse}
                    cssClass="social-login-btn fb-login-btn"
                    icon={<FaFacebookF className="social-login-icon" />}
                    textButton={false}
                    disableMobileRedirect={true}
                    isMobile={false}
                  />
                :
                <Button
                  className="social-login-btn fb-login-btn"
                  color="primary"
                  onClick={() => { NativeCommandManager.onFbLoginPressed() }}>
                  <FaFacebookF className="social-login-icon" />
                </Button>
              }
              </Col>
              <Col>
              {!isNativeApp ?
                <GoogleLogin
                  clientId={AppConfig.googleClientId}
                  render={renderProps => (
                    <button className="social-login-btn g-login-btn" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                      <img className="social-login-icon" src={googleIcon} alt="Google icon" />
                    </button>
                  )}
                  buttonText="Login"
                  onSuccess={this.googleResponse}
                  onFailure={this.googleResponse}
                  cookiePolicy={'single_host_origin'}
                /> :
                <Button
                  className="social-login-btn g-login-btn"
                  color="primary"
                  onClick={() => { NativeCommandManager.onGoogleLoginPressed() }}>
                  <img className="social-login-icon" src={googleIcon} alt="Google icon" />
                </Button>
              }
              </Col>
              <Col>
                {!isNativeApp ? (
                  <AppleSignin
                    authOptions={{
                      clientId: AppConfig.appleClientId,
                      scope: 'email name',
                      redirectURI: location.origin,
                      state: '',
                      nonce: 'nonce',
                      usePopup: true,
                    }}
                    render={props => (
                      <button className="social-login-btn apple-login-btn" {...props}>
                        <FaApple className="social-login-icon" />
                      </button>
                    )}
                    onSuccess={this.appleResponse}
                  />
                ) : (
                  <Button
                    className="social-login-btn apple-login-btn"
                    color="primary"
                    onClick={() => { NativeCommandManager.onAppleLoginPressed() }}
                  >
                    <FaApple className="social-login-icon" />
                  </Button>
                )}
              </Col>
              <Col>
              {!isNativeApp ?
                <TwitterLogin
                  className="social-login-btn tw-login-btn"
                  loginUrl="/api/auth/twitter"
                  requestTokenUrl="/api/auth/twitter/request-token"
                  onSuccess={this.twitterResponse}
                  onFailure={this.twitterFailure}
                >
                  <FaTwitter className="social-login-icon" />
                </TwitterLogin>
                :
                <Button
                  className="social-login-btn tw-login-btn"
                  color="primary"
                  onClick={() => { NativeCommandManager.onTwitterLoginPressed() }}>
                  <FaTwitter className="social-login-icon" />
                </Button>
              }
              </Col>
            </Row>
            <hr data-content={i18n._('Or fill the quick details below')} />
        </>
        <Formik
          validationSchema={schema}
          onSubmit={this.handleSubmit}
          initialValues={{
            email: '',
            password: '',
            keepMeLoggedIn: false,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="email">{i18n._('Email')}</Label>
                  <Input
                    type="text"
                    id="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={touched.email && !!errors.email}
                  />
                  <FormFeedback>{errors.email}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="password">{i18n._('Password')}</Label>
                  <PasswordInput
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={touched.password && !!errors.password}
                  />
                  <FormFeedback>{errors.password}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <CustomInput
                    id="keepMeLoggedIn"
                    type="checkbox"
                    label={i18n._('Keep me logged in')}
                    value={values.keepMeLoggedIn}
                    onChange={handleChange}
                  />
                </FormGroup>
                <Button type="submit" color="primary" block>{i18n._('Log in')}</Button>
                <div className="text-right mt-4">
                  <u className="small can-click" onClick={this.handleForgotPasswordClick}>
                    {i18n._('Forgot password?')}
                  </u>
                </div>
              </Form>
            )}
        </Formik>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: (arg0: any) => any) => {
  return {
    userFetchStart: () => dispatch(userFetchStart()),
    toggleLoginModal: () => dispatch(toggleLoginModal()),
  };
};

const mapStateToProps = (state: IApplicationState) => {
  const { clientSettings } = state;
  return {
    isNativeApp: clientSettings.isNativeApp,
    nativeCommandResponceData: clientSettings.nativeCommandResponceData
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);
