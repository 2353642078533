export enum ROUTES {
  GENERAL_ORIGIN = '/',
  GENERAL_NOTFOUND = '/404',
  GENERAL_ARTICLE = '/article/:slug',
  GENERAL_LOGIN = '/login',
  GENERAL_MATCHCENTER = '/matchcenter/:sportSlug?',
  GENERAL_MATCHCENTER_EVENT = '/matchcenter/:sportSlug/:slug/:eventId',
  GENERAL_REDIRECT_OPERATOR = '/redirect/operator/:slug/:operatorUrlSlug?',
  CREATE_PICK = '/pick/new',
  GENERAL_PICKS_ALL = '/picks/all',
  GENERAL_PICK_SHOW = '/picks/:slug',
  GENERAL_PICK_EDIT = '/pick/edit/:slug',
  GENERAL_PROFILE = '/profile',
  GENERAL_USER_PROFILE = '/user/:slug',
  GENERAL_BONUSES = '/bonuses',
  GENERAL_CAMPAIGNS = '/campaigns',
  GENERAL_CAMPAIGN = '/campaign/:slug',
  GENERAL_ARTICLES = '/articles',
  GENERAL_TIPSTERS = '/tipsters',
  GENERAL_OPERATOR_REVIEW = '/operators/:slug',
  GENERAL_USER_SETTINGS = '/user/settings',
  GENERAL_USER_CONFIRM_SUBSCRIPTION = '/user/confirm-subscription/:token',
  GENERAL_USER_PASSWORD_RESET_CHANGE_PASSWORD = '/user/password-reset/change-password/:userId/:token',
  GENERAL_USER_VERIFY = '/user/verify/:userId/:verificationToken',
  GENERAL_USER_VERIFY_NEW_EMAIL = '/user/verify-new-email/:userId/:verificationToken',
}
