import { Trans } from '@lingui/macro';
import { ApiResponse } from 'apisauce';
import { Formik } from 'formik';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, CustomInput, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import * as yup from 'yup';
import api from '../api';
import { i18n } from '../locales';
import { countriesDataRequest } from '../store/clientSettings/actions';
import { ICountryData } from "../store/clientSettings/types";
import { IApplicationState } from '../store/rootReducer';
import { userRegisterSuccess } from '../store/user/actions';
import { getErrorsFromApiResp } from '../utils/apiUtils';
import PasswordInput from './PasswordInput';

const schema = yup.object({
  username: yup.string().required().min(2).max(50).label(i18n._('Username')),
  email: yup.string().required().max(199).email().label(i18n._('Email')),
  password: yup.string().when('socialService', {
    is: socialService => !socialService,
    then: yup.string().required().min(6).max(50).label(i18n._('Password')),
  }),
  dateOfBirth: yup.date().required().label(i18n._('DOB')),
  countryId: yup.number().required().label(i18n._('Country')),
  newsletter: yup.bool(),
  terms: yup.bool().oneOf([true], i18n._('Terms & conditions must be accepted.')),
  socialService: yup.string(),
});

interface IProps {
  countries: ICountryData[];
  fetchCountries: typeof countriesDataRequest;
  userRegisterSuccess: typeof userRegisterSuccess;
  socialService?: string; // e.g. 'google'
  socialEmail?: string;
  accessToken?: string;
  onSuccessSubmit?: () => void;
}

class RegistrationForm extends React.Component<IProps, {}> {
  componentDidMount() {
    this.props.fetchCountries();
  }

  handleSubmit = async (values, { setErrors }) => {
    const response: ApiResponse<any> = await api.submitRegisterForm({
      ...values,
      accessToken: this.props.accessToken,
    });
    if (response.ok) {
      if (this.props.onSuccessSubmit) {
        // For registration using social networks.
        this.props.onSuccessSubmit();
      } else {
        // For regular registration form.
        this.props.userRegisterSuccess();
      }
    } else {
      setErrors(getErrorsFromApiResp(response, schema));
    }
  }

  render() {
    const {
      countries,
      socialService,
      socialEmail
    } = this.props;

    return (
      <Formik
        validationSchema={schema}
        onSubmit={this.handleSubmit}
        initialValues={{
          username: '',
          password: '',
          email: socialEmail || '',
          dateOfBirth: '',
          countryId: '',
          newsletter: false,
          terms: false,
          socialService,
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
        }) => (
            <Form noValidate onSubmit={handleSubmit} className="mx-auto mw-500px">
              <FormGroup>
                <Label for="username">{i18n._('Username')}</Label>
                <Input
                  type="text"
                  id="username"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.username && !!errors.username}
                />
                <FormFeedback>{errors.username}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="email">{i18n._('Email')}</Label>
                <Input
                  type="text"
                  id="email"
                  value={values.email}
                  readOnly={!!socialEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.email && !!errors.email}
                />
                <FormFeedback>{errors.email}</FormFeedback>
              </FormGroup>
              {!socialService && (
                <FormGroup>
                  <Label for="password">{i18n._('Password')}</Label>
                  <PasswordInput
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={touched.password && !!errors.password}
                  />
                  <FormFeedback>{errors.password}</FormFeedback>
                </FormGroup>
              )}
              <FormGroup>
                <Label for="dateOfBirth">{i18n._('DOB')}</Label>
                <Input
                  type="date"
                  id="dateOfBirth"
                  value={values.dateOfBirth}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.dateOfBirth && !!errors.dateOfBirth}
                  max={moment().add(-2, 'days').format('YYYY-MM-DD')}
                />
                <FormFeedback>{errors.dateOfBirth}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="countryId">{i18n._('Country')}</Label>
                <Input
                  type="select"
                  id="countryId"
                  value={values.countryId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.countryId && !!errors.countryId}
                >
                  <option value=""></option>
                  {countries.map(country => (
                    <option key={country.id} value={country.id}>{country.trName}</option>
                  ))}
                </Input>
                <FormFeedback>{errors.countryId}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <CustomInput
                  id="terms"
                  type="checkbox"
                  label={<Trans>I accept <Link to="#">Terms & Conditions</Link></Trans>}
                  value={values.terms}
                  onChange={handleChange}
                  invalid={touched.terms && !!errors.terms}
                />
                <FormFeedback>{errors.terms}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <CustomInput
                  id="newsletter"
                  type="checkbox"
                  label={i18n._('Newsletter')}
                  value={values.newsletter}
                  onChange={handleChange}
                />
              </FormGroup>
              <Button type="submit" color="primary" block>{i18n._('Register')}</Button>
            </Form>
          )}
      </Formik>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  const { clientSettings } = state;
  return {
    countries: clientSettings.countriesData,
  };
};

const mapDispatchToProps = (dispatch: (arg0: any) => any) => {
  return {
    fetchCountries: () => dispatch(countriesDataRequest()),
    userRegisterSuccess: () => dispatch(userRegisterSuccess()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationForm);
