import React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store/rootReducer';
import { IUserState } from '../../store/user/types';
import bn from '../../utils/bemnames';
import SideMenu from './SideMenu';
import { toggleUserMenu } from '../../store/clientSettings/actions';
import { Nav, NavItem, NavLink as BSnavLink, Button } from 'reactstrap';
import { i18n } from '../../locales';
import { NavLink } from 'react-router-dom';
import { userLogout } from '../../store/user/actions';
import { ROUTES } from '../../router/routes';

const bem = bn.create('side-menu');

interface IDispatchProps {
  menuOpen: boolean;
  user: IUserState;
  toggleUserMenu: typeof toggleUserMenu;
  logout: typeof userLogout;
}

class UserMenu extends React.Component<IDispatchProps> {
  render() {
    const { menuOpen } = this.props;
    const userProfileLink = ROUTES.GENERAL_USER_PROFILE.replace(':slug', this.props.user.userData?.slug);
    return (
      <SideMenu menuOpen={menuOpen} toggleMenu={this.props.toggleUserMenu}>
        <Nav>
          <NavItem className={bem.e('nav-item can-click')}>
            <BSnavLink
              id={`navItem-profile`}
              tag={NavLink}
              activeClassName="active"
              to={userProfileLink}
              onClick={() => this.props.toggleUserMenu()}
              exact={true}
            >
              <span className="">{i18n._('My Profile')}</span>
            </BSnavLink>
          </NavItem>
          <NavItem className={bem.e('nav-item can-click')}>
            <BSnavLink
              id={`navItem-settings`}
              tag={NavLink}
              activeClassName="active"
              to={ROUTES.GENERAL_USER_SETTINGS}
              onClick={() => this.props.toggleUserMenu()}
              exact={true}
            >
              <span className="">{i18n._('Settings')}</span>
            </BSnavLink>
          </NavItem>
        </Nav>
        <Button outline color="secondary" className={bem.e('bottom-button upper-case')} onClick={this.props.logout}>
          {i18n._('log out')}
        </Button>
      </SideMenu>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  const { user, clientSettings } = state;
  return {
    user,
    menuOpen: clientSettings.userMenuOpen,
  };
};

const mapDispatchToProps = (dispatch: (arg0: any) => any) => {
  return {
    toggleUserMenu: () => dispatch(toggleUserMenu()),
    logout: () => dispatch(userLogout())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserMenu);
