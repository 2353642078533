import { takeLatest, all } from 'redux-saga/effects';
import api from '../api';

/* ------------- Types ------------- */

import { UserActionTypes } from '../store/user/types';
import { ClientSettingsActionTypes } from "../store/clientSettings/types";
import { PickCreateTypes } from '../store/pickCreation/types';
import { MatchCenterActionTypes } from '../store/matchcenter/types';

/* ------------- Sagas ------------- */
import { startup } from './startup';
import { getUserData, logout } from './account'
import { getMasterData } from "./masterPage";
import { getCountriesData } from './generalData';
import { publishPick, savePick } from './pick';
import { getMatchCenterData } from './matchcenter';

/* ------------- API ------------- */

/* ------------- Connect Types To Sagas ------------- */

export default function* rootSaga() {
  yield all([
    //StartupSaga
    startup(),

    //All other sagas
    takeLatest(UserActionTypes.USER_REQUEST_START, getUserData, api),
    takeLatest(UserActionTypes.USER_LOGOUT, logout, api),
    takeLatest(ClientSettingsActionTypes.MASTER_DATA_REQUEST, getMasterData, api),
    takeLatest(ClientSettingsActionTypes.COUNTRIES_DATA_REQUEST, getCountriesData, api),
    takeLatest(PickCreateTypes.PICK_CREATE_REQUEST, publishPick, api),
    takeLatest(PickCreateTypes.PICK_SAVE_REQUEST, savePick, api),
    takeLatest(MatchCenterActionTypes.MATCHCENTER_DATA_REQUEST, getMatchCenterData, api),
  ]);
}
