import { i18n } from '../locales';
import { translatePath } from './routing';
import { ICompetition } from '../store/pickCreation/types';

export function getMatchcenterUrl(sportSlug?: string) {
  let url = translatePath('/matchcenter');
  if (sportSlug) {
    url += '/' + sportSlug;
  }

  return url;
}

export function getEventUrl(sportSlug: string, eventSlug: string, eventId: number): string {
  return translatePath('/matchcenter') + '/' + sportSlug + '/' + eventSlug + '/' + eventId;
}

export enum EventStatus {
  NOTSTARTED = 'notstarted',
  INPROGRESS = 'inprogress',
  FINISHED = 'finished',
  INTERRUPTED = 'interrupted',
  POSTPONED = 'postponed',
  CANCELLED = 'cancelled',
}

export function showLiveOdds(status: EventStatus): boolean {
  return (status === EventStatus.INPROGRESS) || (status === EventStatus.FINISHED) || (status === EventStatus.INTERRUPTED);
}

export function getCompetitorTrNameByPosition(event: { eventCompetitors: { position: number; competitor: { trName: string } }[] }, position: number): string | null {
  for (const eventCompetitor of event.eventCompetitors) {
    if (eventCompetitor.position === position) {
      return eventCompetitor.competitor.trName;
    }
  }

  return null;
}

export function getTranslatedRegionNameCompetitionNameInitials(competition: ICompetition): string {
  const wordArr = [];

  const competitionName = competition.trName;
  if (!competitionName || competitionName === competitionName.toUpperCase()) {
    return competitionName;
  }

  const regionName = competition.region.trName;
  if (regionName) {
    wordArr.push(regionName);
  }

  const name = competitionName.replace(/[\s()]+/g, ' ');
  if (name) {
    wordArr.push(...name.split(' '));
  }

  return wordArr.map(word => word.charAt(0)).join('').toUpperCase();
}

export function getCompetitorIdsOrderedByPosition(event: { eventCompetitors: { position: number; competitorId: number | null }[] }): number[] {
  const competitorIds = [];
  const { eventCompetitors } = event;
  for (let position = 1; position <= eventCompetitors.length; position++) {
    const competitorId = eventCompetitors.find(eventCompetitor => eventCompetitor.position === position)?.competitorId;
    if (competitorId && competitorIds.indexOf(competitorId) < 0) {
      competitorIds.push(competitorId);
    }
  }

  return competitorIds;
}

export const suspensionChargeTranslated = (value: string): string => {
  let output = '';
  switch (value) {
    case "Red card (direct)":
      output = i18n._("Red card (direct)");
      break;
    case "Red card (2nd yellow)":
      output = i18n._("Red card (2nd yellow)");
      break;
    case "2. gule kort":
    case "3. gule kort":
    case "4. gule kort":
    case "5. gule kort":
      output = i18n._('{num}. yellow card', { num: value.split('.')[0] });
      break;
    case "Disciplinary reasons":
      output = i18n._("Disciplinary reasons");
      break;
    case "Disciplinary points":
      output = i18n._("Disciplinary points");
      break;
  }
  return output;
}

export const expectedReturnTranslated = (value: string): string => {
  let output = '';
  value = value.toLowerCase();
  const regX1 = new RegExp(/^(early|mid|late) (\w+) (\d{4})$/i);
  const regX2 = new RegExp(/^(starten|midten|slutningen) af (\S+) (\d{4})$#u/i);

  // Prefix, month, year.
  if (value && (value.match(regX1) || value.match(regX2))) {
    let month = '';
    let match = value.match(regX1) ?? value.match(regX2);
    switch (match[2]) {
      case 'january':
      case 'januar':
        month = i18n._('January');
        break;

      case 'february':
      case 'februar':
        month = i18n._('February');
        break;

      case 'march':
      case 'marts':
        month = i18n._('March');
        break;

      case 'april':
        month = i18n._('April');
        break;

      case 'may':
        month = i18n._('May');
        break;

      case 'june':
      case 'juni':
        month = i18n._('June');
        break;

      case 'july':
      case 'juli':
        month = i18n._('July');
        break;

      case 'august':
        month = i18n._('August');
        break;

      case 'september':
        month = i18n._('September');
        break;

      case 'october':
      case 'oktober':
        month = i18n._('October');
        break;

      case 'november':
        month = i18n._('November');
        break;

      case 'december':
        month = i18n._('December');
        break;
    }
    if (!month) {
      return value;
    }

    switch (match[1]) {
      case 'early':
      case 'starten':
        output = i18n._('Early {month} {year}', { month: month, year: match[3] });
        break;

      case 'mid':
      case 'midten':
        output = i18n._('Mid {month} {year}', { month: month, year: match[3] });
        break;

      case 'late':
      case 'slutningen':
        output = i18n._('Late {month} {year}', { month: month, year: match[3] });
        break;
    }

    return output;
  }

  // Simple phrases.
  switch (value) {
    case 'back in training':
    case 'tilbage i træning':
      output = i18n._('Back in training');
      break;

    case '':
    case 'unknown':
    case 'ukendt':
      output = i18n._('Unknown');
      break;

    case 'day to day':
    case 'fra dag til dag':
      output = i18n._('Day to day');
      break;

    case 'et par dage':
      output = i18n._('A couple of days');
      break;

    case 'out for season':
    case 'ude resten af sæsonen':
      output = i18n._('Out for season');
      break;

    case 'doubtful':
    case 'tvivlsom':
      output = i18n._('Doubtful');
      break;

    case 'a few weeks':
    case 'et par uger':
      output = i18n._('A few weeks');
      break;

    case 'few days':
      output = i18n._('Few Days');
      break;
  }

  return output;
}

export const getPositionNameTranslated = (code): string => {
  let output: string;
  switch (code) {
    case 'promotion':
      output = i18n._('Promotion');
      break;
    case 'relegation':
      output = i18n._('Relegation');
      break;
    case 'championsleague':
      output = i18n._('Champions League');
      break;
    case 'championsleaguequal':
      output = i18n._('Champions League qualification');
      break;
    case 'uefacup':
      output = i18n._('Europa League');
      break;
    case 'uefaqual':
      output = i18n._('Europa League qualification');
      break;
    case 'playoff':
      output = i18n._('Playoff');
      break;
    case 'relegationplayoff':
      output = i18n._('Relegation Playoff');
      break;
    case 'promotionplayoff':
      output = i18n._('Promotion Playoff');
      break;
    case 'qualification_to_next_stage':
      output = i18n._('Qualification to next stage');
      break;
    case 'possible_qualification_to_next_stage':
      output = i18n._('Possible qualification to next stage');
      break;
    default:
      output = code;
      break;
  }
  return output;
}

