import { IImage } from "./types";

export enum ImageSize {
  Thumbnail = 150,
  Small = 600,
  Large = 1024,
}

export function getImageSrc(image: IImage, imageSize: ImageSize = ImageSize.Large) : string | null {
  if (image.id) {
    const nameParts = image.name.split('.');
    if (nameParts.length === 2) {
      const nameSuff = (imageSize < image.width)
        ? imageSize + 'x' + Math.round(imageSize / image.width * image.height)
        : 'opt';

      return `/uploads/images/${image.year}/${image.month}/${nameParts[0]}_${nameSuff}.${nameParts[1]}?v=${image.ts}`;
    }
  }

  return null;
}
