import { ActionCreator } from 'redux';
import {
  PickCreateTypes as action,
  ICreatePickStep1,
  IStep1Data,
  IStep2Data,
  ICreatePickStep2,
  ICreatePickStep3,
  ICreatePickSetRedirect,
  IStep3Data,
  ICreatePickSuccess,
  ICreatePickFail,
  ICreatePickRequest,
  ICreatePickFinish,
  ISavePickRequest
} from './types';

export const createPickStep1Complete: ActionCreator<ICreatePickStep1> = (data: IStep1Data) => ({
  type: action.STEP1_COMPLETE,
  payload: data
});

export const createPickStep2Complete: ActionCreator<ICreatePickStep2> = (data: IStep2Data) => ({
  type: action.STEP2_COMPLETE,
  payload: data
});
export const createPickStep3Complete: ActionCreator<ICreatePickStep3> = (data: IStep3Data) => ({
  type: action.STEP3_COMPLETE,
  payload: data
});
export const createPickSetRedirect: ActionCreator<ICreatePickSetRedirect> = (data: boolean) => ({
  type: action.STEP1_REDIRECT,
  payload: data
});
export const createPickPublish: ActionCreator<ICreatePickRequest> = () => ({
  type: action.PICK_CREATE_REQUEST,
});
export const editPickPublish: ActionCreator<ISavePickRequest> = (slug: string) => ({
  type: action.PICK_SAVE_REQUEST,
  payload: slug,
});
export const createPickSuccess: ActionCreator<ICreatePickSuccess> = (slug: string) => ({
  type: action.PICK_CREATE_SUCCESS,
  payload: slug,
});
export const createPickFinish: ActionCreator<ICreatePickFinish> = () => ({
  type: action.PICK_CREATE_FINISH,
});
export const createPickFail: ActionCreator<ICreatePickFail> = (error: string) => ({
  type: action.PICK_CREATE_FAIL,
  payload: error,
});
