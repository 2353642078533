import { NativeCommandType } from "../store/clientSettings/types";


class NativeCommandManager {
  private postMessage = (type: NativeCommandType, data?: any) => {
    (window as any).webkit.messageHandlers.reactNative.postMessage({ type, data });
  }

  public onFbLoginPressed = () => {
    this.postMessage(NativeCommandType.FBlogin);
  }

  public onGoogleLoginPressed = () => {
    this.postMessage(NativeCommandType.GoogleLogin);
  }

  public onAppleLoginPressed = () => {
    this.postMessage(NativeCommandType.AppleLogin);
  }

  public onTwitterLoginPressed = () => {
    this.postMessage(NativeCommandType.TwitterLogin);
  }
  public onAskOwnerBionetricsCheck = () => {
    this.postMessage(NativeCommandType.BiometricsAuth);
  }
}

export default new NativeCommandManager()