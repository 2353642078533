import React from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { IApplicationState } from '../store/rootReducer';
import Constants from "../config/Constants";

interface IProps {
  time: string | null,
  format?: string,
}

interface IStoreProps {
  timeZone: string;
}

class FormatTime extends React.Component<IProps & IStoreProps> {
  render() {
    const { time, timeZone, format } = this.props;

    if (!time) {
      return null;
    }

    return moment.utc(time).tz(timeZone).format(format || Constants.displayDateTimeFormat);
  }
}

const mapStateToProps = (state: IApplicationState) => {
  const { clientSettings } = state;

  return {
    timeZone: clientSettings.timeZone,
  };
};

export default connect(
  mapStateToProps,
)(FormatTime);
