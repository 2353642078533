import API from '../api';
import { call, put } from 'redux-saga/effects';
import { ApiResponse } from 'apisauce';
import { LinkArea } from '../config/Constants';
import { matchCenterDataFail, matchCenterDataSuccess } from '../store/matchcenter/actions';
import { ISport } from '../utils/types';

export function* getMatchCenterData(api: typeof API) {

  const resp: ApiResponse<ISport[]> = yield call(api.getLinkedSports, LinkArea.MC);

  if (resp.ok) {
    yield put(matchCenterDataSuccess(resp.data))
  } else {
    yield put(matchCenterDataFail());
  }
}
