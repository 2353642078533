import { Action } from 'redux';
import { IOperator } from '../../utils/types';

export enum NativeCommandType {
  FBlogin = 'FB_LOGIN_COMMAND',
  GoogleLogin = 'GOOGLE_LOGIN_COMMAND',
  AppleLogin = 'APPLE_LOGIN_COMMAND',
  TwitterLogin = 'TWITTER_LOGIN_COMMAND',
  BiometricsAuth = 'DEVICE_AUTH_BIOMETRICS'
}

export interface IClientSettingsState {
  languageId: number | null;
  countryId: number | null;
  locale: string,
  timeZone: string;
  oddsFormat: string;
  footerItems: IFooterItem[],
  socialLinks: ISocialLink[],
  languages: ILanguage[],
  gamblingAddictionLink: string | null,
  footerItemsFail: boolean,
  socialLinksFail: boolean,
  countriesData: ICountryData[],
  modalOpen: boolean; // true when any modal is open
  loginModalOpen: boolean;
  burgerMenuOpen: boolean;
  userMenuOpen: boolean;
  searchMenuOpen: boolean;
  isNativeApp: boolean;
  nativeCommandResponceData?: INativeCommandResponceData;
  bannerReloads: number;
  bannerSource: string | null;
}

export interface INativeCommandResponceData {
  responce: any,
  responceType: 'success' | 'canceled' | 'error',
  type: NativeCommandType,
}

export interface IFooterItem {
  url: string,
  name: string,
  title: string | null
  target: string,
  trName: string,
  trUrl: string,
}

export interface ISocialLink {
  id: bigint,
  name: string,
  value: string,
}

export interface ILanguage {
  id: number,
  name: string,
  iso: string,
  sportsdataLanguageId: number,
}

export interface ICountryData {
  id: number,
  name: string,
  trName: string,
}

export enum ClientSettingsActionTypes {
  LANGUAGE_SET = 'LANGUAGE_SET',
  MASTER_DATA_SUCCESS = 'MASTER_DATA_SUCCESS',
  MASTER_DATA_REQUEST = 'MASTER_DATA_REQUEST',
  MASTER_DATA_FAIL = 'MASTER_DATA_REQUEST',
  COUNTRIES_DATA_REQUEST = 'COUNTRIES_DATA_REQUEST',
  COUNTRIES_DATA_SUCCESS = 'COUNTRIES_DATA_SUCCESS',
  TOGGLE_LOGIN_MODAL = 'TOGGLE_LOGIN_MODAL',
  TOGGLE_BURGER_MENU = 'TOGGLE_BURGER_MENU',
  TOGGLE_USER_MENU = 'TOGGLE_USER_MENU',
  TOGGLE_SEARCH_MENU = 'TOGGLE_SEARCH_MENU',
  CLOSE_MENUS = 'CLOSE_MENUS',
  SET_TIME_ZONE = 'SET_TIME_ZONE',
  SET_ODDS_FORMAT = 'SET_ODDS_FORMAT',
  SET_IS_NATIVE_APP = 'SET_IS_NATIVE_APP',
  NATIVE_COMMAND = 'NATIVE_COMMAND',
  RELOAD_BANNER = 'RELOAD_BANNER',
}

export interface ILanguageSet extends Action {
  type: ClientSettingsActionTypes.LANGUAGE_SET;
  payload: {
    locale: string;
  }
}

export interface IMasterPageSuccess extends Action {
  type: ClientSettingsActionTypes.MASTER_DATA_SUCCESS;
  payload: {
    footerItems: IFooterItem[],
    socialLinks: ISocialLink[],
    languages: ILanguage[],
    gamblingAddictionLink: string | null,
  }
}

export interface IMasterPageRequest extends Action {
  type: ClientSettingsActionTypes.MASTER_DATA_REQUEST;
}

export interface IMasterPageFail extends Action {
  type: ClientSettingsActionTypes.MASTER_DATA_FAIL;
}

export interface ICountriesDataSuccess extends Action {
  type: ClientSettingsActionTypes.COUNTRIES_DATA_SUCCESS;
  payload: {
    countriesData: ICountryData[],
  }
}
export interface ICountriesDataRequest extends Action {
  type: ClientSettingsActionTypes.COUNTRIES_DATA_REQUEST;
}

export interface IToggleLoginModal extends Action {
  type: ClientSettingsActionTypes.TOGGLE_LOGIN_MODAL;
}
export interface IToggleBurgerMenu extends Action {
  type: ClientSettingsActionTypes.TOGGLE_BURGER_MENU;
}
export interface IToggleUserMenu extends Action {
  type: ClientSettingsActionTypes.TOGGLE_USER_MENU;
}
export interface IToggleSearchMenu extends Action {
  type: ClientSettingsActionTypes.TOGGLE_SEARCH_MENU;
}

export interface ICloseMenus extends Action {
  type: ClientSettingsActionTypes.CLOSE_MENUS;
}

export interface ISetTimeZone extends Action {
  type: ClientSettingsActionTypes.SET_TIME_ZONE;
}

export interface ISetOddsFormat extends Action {
  type: ClientSettingsActionTypes.SET_ODDS_FORMAT;
}

export interface ISetIsNative extends Action {
  type: ClientSettingsActionTypes.SET_IS_NATIVE_APP;
}
export interface INativeCommandResponce extends Action {
  type: ClientSettingsActionTypes.NATIVE_COMMAND;
  payload: {
    responce: INativeCommandResponceData,
  }
}

export interface IReloadBanner extends Action {
  type: ClientSettingsActionTypes.RELOAD_BANNER;
  payload: {
    operator?: IOperator;
  };
}

export type ClientSettingsActions =
  | ILanguageSet
  | IMasterPageRequest
  | IMasterPageFail
  | IMasterPageSuccess
  | ICountriesDataRequest
  | ICountriesDataSuccess
  | IToggleLoginModal
  | IToggleBurgerMenu
  | IToggleUserMenu
  | IToggleSearchMenu
  | ICloseMenus
  | ISetTimeZone
  | ISetOddsFormat
  | INativeCommandResponce
  | ISetIsNative
  | IReloadBanner;
