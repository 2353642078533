import React, { Component, Fragment } from "react";
import { withRouter, Route } from "react-router-dom";
import { ROUTES } from "../router/routes";
import { connect } from "react-redux";
import { IApplicationState } from "../store/rootReducer";
import { IUserState } from "../store/user/types";
import LoginPage from "../pages/Login";
import PageSpinner from "./PageSpinner";

//const LoginPage = React.lazy(() => import('../pages/Login'));

interface IProps {
  user: IUserState;
  history: any;
  location: any;
}

class RequireAuth extends Component<IProps> {
  public readonly state = { isAuthenticated: false };

  componentDidMount = () => {
    if (this.props.user.isLoggedIn) {
      this.isAuthed();
    }
  };

  componentDidUpdate = (prevProps: IProps) => {

    if (this.props.user !== prevProps.user) {
      if (this.props.user.isLoggedIn) {
        this.isAuthed();
      } else {
        this.unAuth();
      }
    }
  };

  isAuthed = () => this.setState({ isAuthenticated: true });

  unAuth = () => this.setState({ isAuthenticated: false });

  render = () =>
    this.props.user.userLoading ? (
      <PageSpinner></PageSpinner>
    ) : (
        !this.props.user.isLoggedIn ? (
          <Route key={ROUTES.GENERAL_LOGIN} component={LoginPage} />
        ) : (
            <Fragment>
              {this.props.children}
            </Fragment>
          ));
}

const mapStateToProps = (state: IApplicationState) => {
  const { user } = state;
  return {
    user,
  };
};

export default withRouter(connect(
  mapStateToProps,
)(RequireAuth));