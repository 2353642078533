export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const replaceDotsWithCommas = (str: string) => str.replace('.', ',');

export const trimStringWith3Dots = (str: string, length: number) => (str.length > length ? `${str.substring(0, length - 3)}...` : str);

let lastId = 0;

export function newId(pref: string): string {
  return pref + (++lastId);
}

export function makeNameAdjective(name: string, locale: string): string {
  if (name) {
    const lastLetter = name.charAt(name.length - 1);
    switch (locale) {
      case 'en':
        return (lastLetter === 's') ? "'" : "'s";

      case 'da':
        if (lastLetter !== 's' && lastLetter !== 'z') {
          return name + 's';
        }
        break;
    }
  }

  return name;
}
