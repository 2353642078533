// a library to wrap and simplify api calls
import apisauce, { ApiResponse } from 'apisauce';
import { AxiosRequestConfig } from 'axios';
import Constants, { LinkArea } from '../config/Constants';
import { formDataConfig, generalDataConfig, formFormData, camelcaseResponce, convertModelToFormData } from '../utils/apiUtils';
import { ISuccessResp } from '../utils/types';

// our "constructor"
const create = (baseURL: string) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // 10 second timeout...
    timeout: 10000,
    // Laravel Sanctum requires for API authentication
    withCredentials: true,
  });

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //

  const get = async <T>(url: string, params: any, axiosConfig?: AxiosRequestConfig): Promise<ApiResponse<T>> => {
    return camelcaseResponce(await api.get(url, params, axiosConfig));
  }
  const post = async <T>(url: string, data: any, axiosConfig?: AxiosRequestConfig): Promise<ApiResponse<T>> => {
    return camelcaseResponce(await api.post(url, data, axiosConfig));
  }
  const put = async <T>(url: string, data: any, axiosConfig?: AxiosRequestConfig): Promise<ApiResponse<T>> => {
    data.append('_method', 'PUT');
    return camelcaseResponce(await api.post(url, data, { ...axiosConfig }));
  }
  const del = async (url: string): Promise<ApiResponse<ISuccessResp>> => {
    return await api.delete(url, undefined, generalDataConfig());
  }

  const csrfCookie = () =>
    get(Constants.API_CSRF_COOKIE, {}, generalDataConfig());
  const getMarkets = (query: any) =>
    get(Constants.API_MARKETS, query, generalDataConfig());
  const getMarketCompetitors = (query: any) =>
    get(Constants.API_MARKET_COMPETITORS, query, generalDataConfig());
  const getOdds = (query: any) =>
    get(Constants.API_ODDS, query, generalDataConfig());
  const login = (data: any) =>
    post(Constants.API_ACCOUNT_LOGIN, formFormData(data), formDataConfig());
  const socialLogin = (data: any) =>
    post(Constants.API_USER_SOCIAL_LOGIN, formFormData(data), formDataConfig());
  const linkSocial = (data: any) =>
    post(Constants.API_USER_LINK_SOCIAL, formFormData(data), formDataConfig());
  const userVerify = (data: any) =>
    post(Constants.API_USER_VERIFY, formFormData(data), formDataConfig());
  const passwordResetRequest = (data: any) =>
    post(Constants.API_USER_PASSWORD_RESET_REQUEST, formFormData(data), formDataConfig());
  const passwordResetChangePass = (data: any) =>
    post(Constants.API_USER_PASSWORD_RESET_CHANGE_PASSWORD, formFormData(data), formDataConfig());
  const getUserInfo = () =>
    get(Constants.API_USER_INFO, {}, generalDataConfig());
  const changePassword = (data: any) =>
    put(Constants.API_USER_CHANGE_PASSWORD, formFormData(data), formDataConfig());
  const requestNewEmail = (data: any) =>
    post(Constants.API_USER_REQUEST_NEW_EMAIL, formFormData(data), formDataConfig());
  const verifyNewEmail = (data: any) =>
    post(Constants.API_USER_VERIFY_NEW_EMAIL, formFormData(data), formDataConfig());

  const logout = () =>
    post(Constants.API_ACCOUNT_LOGOUT, null, generalDataConfig());

  const getMasterPageData = () =>
    get(Constants.API_MASTER_PAGE_DATA, {}, generalDataConfig())

  const getCountriesData = () =>
    get(Constants.API_COUNTRIES_DATA, {}, generalDataConfig())

  const submitRegisterForm = (data: any) =>
    post(Constants.API_USER_REGISTER, formFormData(data), formDataConfig());

  const getUserOptions = (query: object = {}) =>
    get(Constants.API_USERS_OPTIONS, query, generalDataConfig());

  const getMyOperatorsTabData = () =>
    get(Constants.API_USER_MY_OPERATORS, {}, generalDataConfig());

  const saveMyOperators = (data: any) =>
    post(Constants.API_USER_MY_OPERATORS, formFormData(data), formDataConfig());

  const getNotificationSettingsTabData = () =>
    get(Constants.API_USER_NOTIFICATION_SETTINGS, {}, generalDataConfig());

  const saveNotificationSettings = (data: any) =>
    put(Constants.API_USER_NOTIFICATION_SETTINGS, formFormData(data), formDataConfig());

  const getEventOptions = (query: object = {}) =>
    get(Constants.API_EVENT_OPTIONS, query, generalDataConfig());

  const getSportsOptions = (query: object = {}) =>
    get(Constants.API_SPORTS_OPTIONS, query, generalDataConfig());

  const getRegionsOptions = (query: object = {}) =>
    get(Constants.API_REGIONS_OPTIONS, query, generalDataConfig());

  const getCompetitionsOptions = (query: object = {}) =>
    get(Constants.API_COMPETITIONS_OPTIONS, query, generalDataConfig());

  const getOperatorsOptions = (query: object = {}) =>
    get(Constants.API_OPERATORS_OPTIONS, query, generalDataConfig());

  const getBetValue = (query: object = {}) =>
    get(Constants.API_BETVALUE, query, generalDataConfig());

  const getUserProfilePageData = (slug: string) =>
    get(Constants.API_USER_USER_PROFILE + '/' + slug, generalDataConfig());

  const getUserProfileTabData = (userId: number) =>
    get(Constants.API_USER_PROFILE + '/' + userId, generalDataConfig());

  const getUserSettings = () =>
    get(Constants.API_USER_SETTINGS, {}, generalDataConfig());

  const putUserSettings = (data: any = {}) =>
    put(Constants.API_USER_SETTINGS, formFormData(data), formDataConfig());

  const getUserPickStatsTabData = (userId: number) =>
    get(Constants.API_USER_PICK_STATS + '/' + userId, generalDataConfig());

  const putUserFolow = (userId: number, data: any = {}) =>
    put(Constants.API_USER_FOLLOW + '/' + userId, formFormData(data), generalDataConfig());

  const postPick = (data: any = {}) =>
    post(Constants.API_PICKS, convertModelToFormData(data), formDataConfig());

  const getDataForPicksAll = () =>
    get(Constants.API_PICKS_ALL_PAGE, null, generalDataConfig());

  const getPickShow = (query: object = {}) =>
    get(Constants.API_PICK_SHOW, query, generalDataConfig());

  const getPickEdit = (query: object = {}) =>
    get(Constants.API_PICK_EDIT, query, generalDataConfig());

  const postPickEdit = (data: any = {}) =>
    post(Constants.API_PICK_EDIT, convertModelToFormData(data), formDataConfig());

  const postFCMtoken = (data: any = {}) =>
    post(Constants.API_USER_FCM_TOKEN, convertModelToFormData(data), formDataConfig());

  const deleteUserAvatar = () =>
    del(Constants.API_USER_DELETE_AVATAR);

  const putUserAvatar = (data: any = {}) =>
    put(Constants.API_USER_UPLOAD_AVATAR, convertModelToFormData(data), formDataConfig());

  const newsletterSubscribe = () =>
    post(Constants.API_USER_NEWSLETTER_SUBSCRIBE, null, formDataConfig());

  const newsletterSubscribeRequest = (data: any) =>
    post(Constants.API_USER_NEWSLETTER_SUBSCRIBE_REQUEST, convertModelToFormData(data), formDataConfig());

  const newsletterSubscribeConfirm = (data: any) =>
    post(Constants.API_USER_NEWSLETTER_SUBSCRIBE_CONFIRM, convertModelToFormData(data), formDataConfig());

  const getOperatorsBonuses = (query: object = {}) =>
    get(Constants.API_OPERATORS_BONUSES, query, generalDataConfig());

  const getRedirectOperatorData = (query: object = {}) =>
    get(Constants.API_OPERATORS_REDIRECT_OPERATOR_DATA, query, generalDataConfig());

  const getLinkedSports = (linkArea: LinkArea) =>
    get(Constants.API_SPORTS_GET_LINKED, { linkArea }, generalDataConfig());

  const getMatchcenterCompetitions = (query: object = {}) =>
    get(Constants.API_MATCHCENTER_COMPETITIONS, query, generalDataConfig());

  const getMatchcenterEventData = (query: object = {}) =>
    get(Constants.API_MATCHCENTER_EVENT, query, generalDataConfig());

  const getMatchcenterCompetitionEvents = (query: object = {}) =>
    get(Constants.API_MATCHCENTER_COMPETITION_EVENTS, query, generalDataConfig());

  const getMatchcenterLineupsTabData = (query: object = {}) =>
    get(Constants.API_MATCHCENTER_LINEUPS, query, generalDataConfig());

  const getMatchcenterTeamNewsTabData = (query: object = {}) =>
    get(Constants.API_MATCHCENTER_TEAMNEWS, query, generalDataConfig());

  const getMatchcenterStandingsRelatedStats = (query: object = {}) =>
    get(Constants.API_STANDINGS_RELATED, query, generalDataConfig());

  const getMatchcenterStatisticsTabData = (query: object = {}) =>
    get(Constants.API_MATCHCENTER_STATISTICS, query, generalDataConfig());

  const getMatchcenterMatchDataTabData = (query: object = {}) =>
    get(Constants.API_MATCHCENTER_MATCH_DATA, query, generalDataConfig());

  const getMatchcenterMatchDataTabComments = (query: object = {}) =>
    get(Constants.API_MATCHCENTER_MATCH_COMMENTARIES, query, generalDataConfig());
  const getMatchcenterTrendsTabData = (query: object = {}) =>
    get(Constants.API_MATCHCENTER_TRENDS, query, generalDataConfig());

  const getMatchcenterFixturesEvents = (query: object = {}) =>
    get(Constants.API_MATCHCENTER_FIXTURES_EVENTS, query, generalDataConfig());

  const getMatchcenterPrevMeetings = (query: object = {}) =>
    get(Constants.API_MATCHCENTER_PREV_MEETINGS, query, generalDataConfig());

  const getCampaigns = (query: object = {}) =>
    get(Constants.API_CAMPAIGNS, query, generalDataConfig());

  const getCampaign = (slug: string, query: object = {}) =>
    get(Constants.API_CAMPAIGN + '/' + slug, query, generalDataConfig());

  const getBettingPicksTabData = (eventId: number, query: object = {}) =>
    get(Constants.API_MACTHCENTER_MATCH_BETTING_PICKS_TAB + '/' + eventId, query, generalDataConfig());

  const voteForMatch = (data: any = {}) =>
    post(Constants.API_MATCH_VOTE, formFormData(data), generalDataConfig());

  const getArticles = (query: object = {}) =>
    get(Constants.API_ARTICLES, query, generalDataConfig());

  const getArticle = (slug: string, query: object = {}) =>
    get(Constants.API_ARTICLE + '/' + slug, query, generalDataConfig());

  const getPage = (slug: string, query: object = {}) =>
    get(Constants.API_PAGE + '/' + slug, query, generalDataConfig());

  const getMainPage = (query: object = {}) =>
    get(Constants.API_MAIN_PAGE, query, generalDataConfig());

  const createPost = (data: any = {}) =>
    post(Constants.API_POSTS, convertModelToFormData(data), formDataConfig());

  const getPosts = (query: object = {}) =>
    get(Constants.API_POSTS, query, generalDataConfig());

    const getPostEditHistory = (id: number) =>
    get(Constants.API_POSTS + '/' + id + '/edit-history', {}, generalDataConfig());

  const editPost = (id: number, data: any = {}) =>
    put(Constants.API_POSTS + '/' + id, convertModelToFormData(data), formDataConfig());

  const deletePost = (id: number) =>
    del(Constants.API_POSTS + '/' + id);

  const changeThreadStatus = (entityType: string, entityId: number, status: string) =>
    put(Constants.API_THREADS + '/' + entityType + '/' + entityId, convertModelToFormData({ status }), formDataConfig());

  const getThreadFollowStatus = (entityType: string, entityId: number) =>
    get(Constants.API_THREADS_FOLLOW_STATUS, { entityType, entityId }, generalDataConfig());

  const changeThreadFollow = (entityType: string, entityId: number, status: string) =>
    put(Constants.API_THREADS_CHANGE_FOLLOW, convertModelToFormData({ entityType, entityId, status }), formDataConfig());

  const postUploadImage = (data: any = {}) =>
    post(Constants.API_POSTS_UPLOAD_IMAGE, convertModelToFormData(data), formDataConfig());

  const getOperatorReview = (slug: string, query: object = {}) =>
    get(Constants.API_OPERATOR_REVIEW + '/' + slug, query, generalDataConfig());

  const getWebNotifications = (query: object = {}) =>
    get(Constants.API_WEB_NOTIFICATIONS, query, generalDataConfig());

  const getNumOfUnreadWebNotif = () =>
    get(Constants.API_WEB_NOTIFICATIONS_NUM_OF_UNREAD, null, generalDataConfig());

  const webNotifResetUnread = () =>
    post(Constants.API_WEB_NOTIFICATIONS_RESET_UNREAD, null, formDataConfig());

  const delWebNotification = (id: number) =>
    del(Constants.API_WEB_NOTIFICATIONS + '/' + id);

  const getSearchResults = (query: object = {}) =>
    get(Constants.API_SEARCH, query, generalDataConfig());

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2
    csrfCookie,
    getMarkets,
    getMarketCompetitors,
    getOdds,
    login,
    socialLogin,
    linkSocial,
    userVerify,
    passwordResetRequest,
    passwordResetChangePass,
    getUserInfo,
    getUserSettings,
    putUserSettings,
    changePassword,
    requestNewEmail,
    verifyNewEmail,
    submitRegisterForm,
    getUserOptions,
    getMyOperatorsTabData,
    saveMyOperators,
    getNotificationSettingsTabData,
    saveNotificationSettings,
    logout,
    getMasterPageData,
    getCountriesData,
    getEventOptions,
    getSportsOptions,
    getCompetitionsOptions,
    getRegionsOptions,
    getOperatorsOptions,
    getBetValue,
    getUserProfilePageData,
    getUserProfileTabData,
    getUserPickStatsTabData,
    putUserFolow,
    postPick,
    getDataForPicksAll,
    getPickShow,
    getPickEdit,
    postPickEdit,
    postFCMtoken,
    deleteUserAvatar,
    putUserAvatar,
    newsletterSubscribe,
    newsletterSubscribeRequest,
    newsletterSubscribeConfirm,
    getOperatorsBonuses,
    getRedirectOperatorData,
    getLinkedSports,
    getMatchcenterCompetitions,
    getMatchcenterEventData,
    getMatchcenterCompetitionEvents,
    getMatchcenterLineupsTabData,
    getMatchcenterTeamNewsTabData,
    getMatchcenterMatchDataTabData,
    getMatchcenterTrendsTabData,
    getMatchcenterFixturesEvents,
    getMatchcenterPrevMeetings,
    getMatchcenterStatisticsTabData,
    getMatchcenterStandingsRelatedStats,
    getCampaigns,
    getCampaign,
    getBettingPicksTabData,
    voteForMatch,
    getMatchcenterMatchDataTabComments,
    getArticles,
    getArticle,
    getPage,
    getMainPage,
    createPost,
    getPosts,
    getPostEditHistory,
    editPost,
    deletePost,
    changeThreadStatus,
    getThreadFollowStatus,
    changeThreadFollow,
    postUploadImage,
    getOperatorReview,
    getWebNotifications,
    getNumOfUnreadWebNotif,
    webNotifResetUnread,
    delWebNotification,
    getSearchResults,
    //general
    api,
  };
};

const api = create(window.location.origin);

// let's return back our create method as the default.
export default api;
