import React from 'react';
import { i18n } from '../../locales';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { resetSwUpdated } from '../../store/sw/actions';
import { ISwState } from '../../store/sw/types';
import { IApplicationState } from '../../store/rootReducer';

interface IStoreProps {
  sw: ISwState;
  resetSwUpdated: typeof resetSwUpdated;
}

const NewVersionModal: React.FC<IStoreProps> = ({ sw, resetSwUpdated }) => {
  const updateServiceWorker = () => {
    const registrationWaiting = sw.swRegistration?.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', (e: any) => {
        if (e.target.state === 'activated') {
          location.reload();
        }
      });
    }
  };

  return (
    <Modal isOpen={sw.swUpdated} toggle={resetSwUpdated} centered>
      <ModalBody>
        {i18n._('There is a new version available.')}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={updateServiceWorker}>
          {i18n._('Update')}
        </Button>
        {' '}
        <Button color="secondary" onClick={resetSwUpdated}>{i18n._('Cancel')}</Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  const { sw } = state;
  return {
    sw,
  };
};

const mapDispatchToProps = (dispatch: (arg0: any) => any) => {
  return {
    resetSwUpdated: () => dispatch(resetSwUpdated()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewVersionModal);
