import { ActionCreator } from 'redux';

import {
  SwTypes as action,
  ISwUpdated,
  IResetSwUpdated,
} from './types';

export const swUpdated: ActionCreator<ISwUpdated> = (registration: ServiceWorkerRegistration) => ({
  type: action.SW_UPDATED,
  payload: registration,
});

export const resetSwUpdated: ActionCreator<IResetSwUpdated> = () => ({
  type: action.RESET_SW_UPDATED,
});
