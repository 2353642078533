import React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store/rootReducer';
import SideMenu from './SideMenu';
import { i18n } from "../../locales";
import bn from '../../utils/bemnames';
import { toggleSearchMenu } from '../../store/clientSettings/actions';
import { MdSearch, MdClose} from "react-icons/md";
import { Col, Nav, NavItem, NavLink, Row } from "reactstrap";
import { ApiResponse } from "apisauce";
import api from "../../api";
import LoadingSpinner from "../LoadingSpinner";
import FailedToLoadData from "../FailedToLoadData";
import { getImageSrc, ImageSize } from "../../utils/image";
import { Link } from "react-router-dom";
import { getArticleUrl } from "../../utils/article";
import { getPageUrl } from "../../utils/page";
import SportIcon from "../SportIcon";
import FormatTime from "../FormatTime";
import { getEventUrl } from "../../utils/event";
import { OperatorLogoType } from "../../utils/operator";
import OperatorLogo from "../OperatorLogo";
import { getCampaignUrl } from "../../utils/campaign";
import { getPickUrl, pickShowTitle} from "../../utils/pick";
import moment from "moment-timezone";
import Constants from "../../config/Constants";
import userIcon from "../../assets/img/user.svg";
import { getUserUrl } from "../../utils/user";
import { formatDecimalNum } from "../../utils/number";
import classnames from "classnames";
import NoDataWarning from "../NoDataWarning";

const bem = bn.create('search-menu');

enum Tabs {
  event = 'event',
  pick = 'pick',
  user = 'user',
  article = 'article',
  campaign = 'campaign'
}

const TabsOptions = [
  {
    key: Tabs.event,
    label: i18n._('Match')
  },
  {
    key: Tabs.pick,
    label: i18n._('Pick')
  },
  {
    key: Tabs.user,
    label: i18n._('User')
  },
  {
    key: Tabs.article,
    label: i18n._('Article')
  },
  {
    key: Tabs.campaign,
    label: i18n._('Campaign')
  },
]

interface IDispatchProps {
  menuOpen: boolean;
  timeZone: string;
  locale: string;
  toggleSearchMenu: typeof toggleSearchMenu;
}

interface IState {
  searchText: string;
  activeTab: Tabs,
  loading: boolean,
  failed: boolean,
  data: any,
}

interface IResponseData {
  data: any,
}

class SearchMenu extends React.Component<IDispatchProps> {
  public readonly state: IState = {
    searchText: '',
    activeTab: Tabs.event,
    loading: false,
    failed: false,
    data: [],
  };

  onInputChange = (value) => {
    this.setState({
      searchText: value
    });
    if (value.length > 2) {
      this.getData();
    }
  };

  setActiveTab = (tab) => {
    this.setState({
      activeTab: tab,
      data: [],
    });
    this.getData();
  };

  closeModal = () => {
    this.props.toggleSearchMenu();
  }

  getData = () => {
    setTimeout(async () => {
      const { searchText, activeTab } = this.state;
      if (searchText) {
        this.setState({
          loading: true
        })
        let response: ApiResponse<IResponseData> = await api.getSearchResults({q: searchText, tab: activeTab}) as ApiResponse<IResponseData>;
        if (response.ok) {
          this.setState({
            data: response.data,
            loading: false,
            failed: false,
          });
        } else {
          this.setState({
            failed: true,
            loading: false,
          });
        }
      }
    })
  }

  renderTable = () => {
    const { activeTab } = this.state;
    switch (activeTab) {
      case Tabs.event:
        return this.renderEventsTable();
      case Tabs.pick:
        return this.renderPicksTable();
      case Tabs.article:
        return this.renderArticlesTable();
      case Tabs.campaign:
        return this.renderCampaignsTable();
      case Tabs.user:
        return this.renderUsersTable();
    }
  };

  renderArticlesTable = () => {
    const { data } = this.state;
    if (!data.length) {
      return <NoDataWarning text={i18n._('No articles matches your search. Please try another search query.')} />
    }
    return (
      <>
        {data.map((item, i) => (
          <Row key={i} className="align-items-center bg-white m-3 border-top">
            {item.image && (
              <div className={bem.e('article-image')} style={{
                backgroundImage: 'url("' + getImageSrc(item.image, ImageSize.Thumbnail) + '")',
              }} />
            )}
            <div className="flex-grow-1 flex-basis-0 pl-2">
              <Link
                className="f-size-12px font-weight-bold text-decoration-none grey-2"
                to={item.entity === 'article' ? getArticleUrl(item.slug) : getPageUrl(item.slug)}
                onClick={this.closeModal}
              >
                {item.title}
              </Link>
            </div>
          </Row>
        ))}
      </>
    )
  }

  renderEventsTable = () => {
    const { data } = this.state;
    if (!data.length) {
      return <NoDataWarning text={i18n._('No events matches your search. Please try another search query.')} />
    }
    return (
      <>
        {data.map((event, i) => (
          <Row key={i} className="bg-white px-3 py-2 border-top">
            <Col xs="auto" className={bem.e('event-card-icon')}>
              <SportIcon sport={event.tournamentStage.tournament.competition.sportId} />
            </Col>
            <Col>
              <Link
                className="f-size-12px font-weight-bold text-decoration-none grey-2"
                to={getEventUrl(event.tournamentStage.tournament.competition.sport.trSlug, event.trSlug, event.id)}
                onClick={this.closeModal}
              >
                {event.trName} {event.tournamentStage.tournament.competition.gender === 'f' ? '(f)' : null}
              </Link>
            </Col>
            <Col xs="auto" className="f-size-12px font-weight-bold grey-2">
              <FormatTime time={event.startTime} />
            </Col>
          </Row>
        ))}
      </>
    )
  }

  renderPicksTable = () => {
    const { data } = this.state;
    const { timeZone } = this.props;
    if (!data.length) {
      return <NoDataWarning text={i18n._('No picks matches your search. Please try another search query.')} />
    }
    return (
      <>
        {data.map((pick) => {
          const { title, appendix } = pickShowTitle(pick.pickData.title);
          return (
            <Row key={pick.id} className="d-flex border-top border-bottom bg-white px-4 py-2 align-items-center">
              <div className="text-center">
                <div className="small font-weight-medium grey-2">
                  {moment.utc(pick.time).tz(timeZone).format(Constants.displayTimeFormat)}
                </div>
                <SportIcon className={bem.e('sport-icon')} sport={pick.sportId} title={pick.sportName} />
              </div>
              <div className="flex-grow-1 px-2 pr-md-3">
                <div className="mb-1">
                  <Link
                    className="font-weight-bold grey-2"
                    to={getPickUrl(pick.pickData.slug)}
                    onClick={this.closeModal}
                  >
                    {title}
                  </Link>
                </div>
                <div className="d-md-flex">
                  {appendix && (
                    <div className="small font-weight-medium mb-1 mb-md-0 pr-md-2 grey-2">{appendix}</div>
                  )}
                </div>
              </div>
            </Row>
          )
        })}
      </>
    )
  }

  renderUsersTable = () => {
    const { data } = this.state;
    const { locale } = this.props;
    if (!data.length) {
      return <NoDataWarning text={i18n._('No users matches your search. Please try another search query.')} />
    }
    return (
      <>
        {data.map(({ user, balance, payback, picksCnt, hits }) => (
          <Row key={user.id} className="d-flex border-top border-bottom bg-white px-3 py-2 align-items-center f-size-12px">
             <Col xs={4}>
               <div className="d-flex align-items-center">
                 <img
                   className={bem.e('avatar')}
                   src={user.avatarPath || userIcon}
                   alt={user.username}
                 />
                 <div className="flex-grow-1 pl-2">
                   <Link className="text-dark" to={getUserUrl(user.slug)} onClick={this.closeModal}>
                     {user.username}
                   </Link>
                 </div>
               </div>
             </Col>
            <Col xs={2} className="text-dark">
              <div>{i18n._('Tips')}</div>
              <div>{picksCnt}</div>
            </Col>
            <Col xs={2} className="text-dark">
              <div>{i18n._('ROI')}</div>
              <div>{payback}%</div>
            </Col>
            <Col xs={2}>
              <div className="text-dark">{i18n._('Balance')}</div>
              <div className={classnames({
                'text-nowrap text-center': true,
                'text-danger': balance < 0,
                'text-success': balance > 0,
              })}>
                {formatDecimalNum(balance, locale)}
              </div>
            </Col>
            <Col xs={2}>
              <div className="text-dark text-nowrap">{i18n._('Hit Rate')}</div>
              <div className="text-dark">{hits}/{picksCnt}</div>
            </Col>
          </Row>
        ))}
      </>
    )
  }

  renderCampaignsTable = () => {
    const { data } = this.state;
    if (!data.length) {
      return <NoDataWarning text={i18n._('No campaigns matches your search. Please try another search query.')} />
    }
    return (
      <>
        {data.map((campaign, i) => (
          <Row key={i} className="align-items-center bg-white px-3 py-2 border-top">
            <OperatorLogo
              className={bem.e('operator-logo', 'd-block')}
              operator={data.operator}
              logoType={OperatorLogoType.Normal}
            />
            <div className="flex-grow-1 flex-basis-0 pl-3">
              <Link
                className="f-size-12px font-weight-bold text-decoration-none grey-2"
                to={getCampaignUrl(campaign.data.slug)}
                onClick={this.closeModal}
              >
                {campaign.data.name}
              </Link>
            </div>
          </Row>
        ))}
      </>
    )
  }

  render() {
    const { menuOpen } = this.props;
    const { searchText, activeTab, loading, failed } = this.state;
    return (
      <SideMenu menuOpen={menuOpen} toggleMenu={this.props.toggleSearchMenu}>
        <div className={bem.e('search-menu', 'w-100 px-3')}>
          <div className="position-relative">
            <input
              type="text"
              id="search"
              name="search"
              value={searchText}
              className={bem.e('search')}
              placeholder={i18n._('Search here')}
              onChange={e => this.onInputChange(e.target.value)}
            />
            {searchText ?
              <MdClose
                fontSize={24}
                className={bem.e('search-icon')}
                onClick={() => this.onInputChange('')}
              />
              :
              <MdSearch fontSize={24} className={bem.e('close-icon')} />
            }
          </div>
            <Nav tabs className="my-3 flex-nowrap">
              {TabsOptions.map(option => (
                <NavItem key={option.key}>
                  <NavLink
                    className="can-click"
                    active={activeTab === option.key}
                    onClick={() => { this.setActiveTab(option.key)}}
                  >
                    {option.label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </div>
        {loading && <LoadingSpinner />}
        {!loading && failed && (
          <FailedToLoadData />
        )}
        {!loading && !failed && searchText.length > 2 && this.renderTable()}
      </SideMenu>
    )
  }
}

const mapStateToProps = (state: IApplicationState) => {
  const { clientSettings } = state;
  return {
    menuOpen: clientSettings.searchMenuOpen,
    timeZone: clientSettings.timeZone,
    locale: clientSettings.locale,
  };
};

const mapDispatchToProps = (dispatch: (arg0: any) => any) => {
  return {
    toggleSearchMenu: () => dispatch(toggleSearchMenu()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchMenu);
