import React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store/rootReducer';
import classnames from 'classnames';

interface IProps {
  id: string;
  zoneId: number;
  className?: string;
}

interface IStoreProps {
  reloads: number;
  source: string | null;
}

const BannerZone = React.forwardRef<HTMLIFrameElement, IProps & IStoreProps>(({ id, zoneId, className, reloads, source }, ref) => {
  return (
    <iframe
      key={reloads}
      ref={ref}
      className={classnames([
        'overflow-hidden border-0',
        className,
      ])}
      id={id}
      name={id}
      src={'https://www.mymediaindex.com/d3d3/ZGVsaXZlcnk=/YWZy.php?em9uZWlk=' + zoneId + (source ? '&source=' + source : '') + '&cb=1549'}
      style={{
        maxWidth: 'none',
      }}
      scrolling="no"
    >
      <a href={'https://www.mymediaindex.com/d3d3/ZGVsaXZlcnk=/Y2s=.php?n=' + id + '&cb=13'}
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={'https://www.mymediaindex.com/d3d3/ZGVsaXZlcnk=/YXZ3.php?em9uZWlk=' + zoneId + (source ? '&source=' + source : '') + '&cb=2&n=' + id}
          alt=""
        />
      </a>
    </iframe>
  );
});

const mapStateToProps = (state: IApplicationState) => {
  const { clientSettings } = state;

  return {
    reloads: clientSettings.bannerReloads,
    source: clientSettings.bannerSource,
  };
};

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true },
)(BannerZone);
