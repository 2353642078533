import { ActionCreator } from 'redux';
import {
  MatchCenterActionTypes as action,
  IMatchCenterDataRequest,
  IMatchCenterDataSuccess,
  IMatchCenterDataFail,
} from './types';

export const getMatchCenterData: ActionCreator<IMatchCenterDataRequest> = () => ({
  type: action.MATCHCENTER_DATA_REQUEST,
});

export const matchCenterDataSuccess: ActionCreator<IMatchCenterDataSuccess> = (data: any) => ({
  type: action.MATCHCENTER_DATA_SUCCESS,
  payload: data
});

export const matchCenterDataFail: ActionCreator<IMatchCenterDataFail> = () => ({
  type: action.MATCHCENTER_DATA_FAIL,
});
