import { i18n } from '../locales';
import React from 'react';
import { Redirect, Route, RouteProps, Switch, useLocation } from 'react-router-dom';
import { nativeActionResponce, setIsNative, setLanguage } from '../store/clientSettings/actions';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { userSetFCMToken } from '../store/user/actions';
import toastr from 'toastr';

interface IDispatchProps {
  setLanguage: typeof setLanguage;
  userSetFCMToken: typeof userSetFCMToken;
  setIsNative: typeof setIsNative;
  nativeActionResponce: typeof nativeActionResponce;
}

export const PrefixedRoute: React.FC<RouteProps & IDispatchProps> = ({ location, userSetFCMToken, setIsNative, nativeActionResponce, ...rest }) => {
  let currentLocation = location.pathname;
  const fcmtoken = queryString.parse(useLocation().search)?.fcmtoken;
  if (fcmtoken) {
    userSetFCMToken(fcmtoken);
    toastr.info(i18n._('Notifications enabled'));
  }

  const isNative = queryString.parse(useLocation().search)?.isNative;
  if (isNative || (window as any).isNative) {
    setIsNative();
    (window as any).nativeMessage = function (event) {
      nativeActionResponce(JSON.parse(event));
    }
  }


  let routes = [];
  let localeAlias = currentLocation.split('/')[1];
  const currentLanguage = i18n.language;

  if (localeAlias && i18n.availableLanguages.filter(l => l === localeAlias).length > 0) {
    if (localeAlias !== currentLanguage) {

      //translating url to origin keys
      let correctUrl = `/${currentLanguage}`;
      let currentLocationLanguageDictionary = i18n.use(localeAlias).messages
      currentLocation.substring(3).split('/').map(part => {
        for (let key in currentLocationLanguageDictionary) {
          if (currentLocationLanguageDictionary[key] === part) {
            correctUrl += '/' + key;
            return null;
          }
        }
        correctUrl += part ? '/' + part : '';
        return null;
      })
      routes = [
        <Route key={'mainLocalized'} path={correctUrl} {...rest}>
          {rest.children}
        </Route>,
        <Redirect key={'mainLocalizedRedirect'} path={currentLocation} to={correctUrl} />
      ];
    } else {
      routes = [
        <Route key={'mainLocalized'} path={currentLocation} {...rest}>
          {rest.children}
        </Route>
      ];
    }
  } else {
    routes = [
      <Route key={'mainLocalized'} path={`/${currentLanguage}${currentLocation}`} {...rest}>
        {rest.children}
      </Route>,
      <Redirect key={'mainLocalizedRedirect'} path={currentLocation} to={`/${currentLanguage}${currentLocation}`} />];
  }
  return (
    <Route>
      <Switch>
        {routes.map(r => r)}
      </Switch>
    </Route>)
}


const mapStateToProps = () => ({ });

const mapDispatchToProps = (dispatch: (arg0: any) => any) => {
  return {
    setLanguage: (locale) => dispatch(setLanguage(locale)),
    userSetFCMToken: (token) => dispatch(userSetFCMToken(token)),
    setIsNative: () => dispatch(setIsNative()),
    nativeActionResponce: (event) => dispatch(nativeActionResponce(event))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrefixedRoute);