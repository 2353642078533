import React from 'react';
import { Spinner } from 'reactstrap';

const PageSpinner = () => {
  return (
    <div className="cr-page-spinner">
      <Spinner></Spinner>
    </div>
  );
};

export default PageSpinner;
