import moment from 'moment-timezone';
import Constants from "../config/Constants";

export const toUtcTime = (time: string | null): string | null => {
  return time
    ? moment.tz(time, Constants.datetimeFieldDateTimeFormat, getTimeZone()).utc().format(Constants.serverDateTimeFormat)
    : null;
}

export function getTimeZone(): string {
  let timeZone = localStorage.getItem('timeZone');
  if (!timeZone) {
    timeZone = moment.tz.guess();
    localStorage.setItem('timeZone', timeZone);
  }

  return timeZone;
}
