import React from 'react';
import bn from '../../utils/bemnames';
import BannerZone from './BannerZone';

const bem = bn.create('skyscraper');

const HEADER_HEIGHT = 56;
const BANNER_HEIGHT = 600;
const CONTAINER_HORIZ_PADDING = 15;

class Skyscraper extends React.Component {
  private wrpRef = React.createRef<HTMLIFrameElement>();

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleScroll);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll);
  }

  handleScroll = () => {
    const node = this.wrpRef.current;
    const container = node.parentNode as HTMLDivElement;
    const containerParent = container.parentNode as HTMLDivElement;
    const fixed = (window.scrollY > HEADER_HEIGHT && containerParent.clientHeight > BANNER_HEIGHT);
    const { style } = node;
    style.position = fixed ? 'fixed' : null;
    style.left = fixed ? 'auto' : null;
    style.marginLeft = fixed ? container.clientWidth - CONTAINER_HORIZ_PADDING + 'px' : null;
    let top = 0;
    if (fixed) {
      top = Math.min(HEADER_HEIGHT, containerParent.clientHeight - window.scrollY - BANNER_HEIGHT + HEADER_HEIGHT);
    }
    style.top = top + 'px';
  };

  render() {
    return (
      <BannerZone
        ref={this.wrpRef}
        id="ae29e363"
        zoneId={289}
        className={bem.e('wrp', 'overflow-hidden d-none d-lg-block')}
      />
    );
  }
}

export default Skyscraper;
