import React, { useEffect } from 'react';
import withScreenSizeListener from '../../hocs/withScreenSizeListener';
import Content from './Content';
import Header from './Header';
import Footer from "./Footer";
import LoginModal from '../LoginModal';
import BurgerMenu from './BurgerMenu';
import UserMenu from './UserMenu';
import SearchMenu from "./SearchMenu";
import { useRouteMatch } from 'react-router';
import NewVersionModal from './NewVersionModal';
import MobileBottomBanner from '../banners/MobileBottomBanner';

interface IProps {
  breakpoint: string;
}

const MainLayout: React.FC<IProps> = ({ children }) => {
  let { url } = useRouteMatch();
  useEffect(() => { window.scrollTo(0, 0) }, [url]);
  return (
    <main className="cr-app">
      <Content className={''} fluid>
        <Header />
        <BurgerMenu />
        <UserMenu />
        <SearchMenu />
        {children}
        <Footer />
      </Content>
      <LoginModal />
      <NewVersionModal />
      <MobileBottomBanner />
    </main>
  );
}

export default withScreenSizeListener(MainLayout);
