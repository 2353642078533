import React from 'react';
import bn from '../../utils/bemnames';
import BannerZone from './BannerZone';

const bem = bn.create('mobile-bottom-banner');

class MobileBottomBanner extends React.Component {
  private wrpRef = React.createRef<HTMLIFrameElement>();

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const show = (window.scrollY + window.innerHeight + 100 < document.documentElement.scrollHeight);
    this.wrpRef.current.style.display = show ? null : 'none';
  };

  render() {
    return (
      <div
        ref={this.wrpRef}
        className={bem.e('wrp', 'position-fixed overflow-hidden text-center d-lg-none')}
      >
        <BannerZone
          className={bem.e('iframe', 'overflow-hidden')}
          id="a6238275"
          zoneId={290}
        />
      </div>
    );
  }
}

export default MobileBottomBanner;
