import classNames from 'classnames';
import React from 'react';

export enum tagMap {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  'display-1' = 'h1',
  'display-2' = 'h1',
  'display-3' = 'h1',
  'display-4' = 'h1',
  p = 'p',
  lead = 'p',
  blockquote = 'blockquote',
}

interface IProps {
  tag?: React.Component,
  className?: string,
  type: tagMap,
  children: any,
}

const Typography = ({ tag: Tag, className, type, ...restProps }: IProps) => {
  const classes = classNames({ [type]: !!type }, className);
  let TypoComp: any;

  if (Tag) {
    TypoComp = Tag;
  } else if (!Tag && tagMap[type]) {
    TypoComp = tagMap[type];
  } else {
    TypoComp = 'p';
  }

  return <TypoComp {...restProps} className={classes} />;
};

export default Typography;
