import { Action } from 'redux';
import { ISport } from '../../utils/types';

export interface IMatchCenterState {
  sports: ISport[];
  loading: boolean;
  failed: boolean;
}

export enum MatchCenterActionTypes {
  MATCHCENTER_DATA_REQUEST = 'MATCHCENTER_DATA_REQUEST',
  MATCHCENTER_DATA_SUCCESS = 'MATCHCENTER_DATA_SUCCESS',
  MATCHCENTER_DATA_FAIL = 'MATCHCENTER_DATA_FAIL',
}

export interface IMatchCenterDataRequest extends Action {
  type: MatchCenterActionTypes.MATCHCENTER_DATA_REQUEST,
}

export interface IMatchCenterDataSuccess extends Action {
  type: MatchCenterActionTypes.MATCHCENTER_DATA_SUCCESS,
  payload: any;
}

export interface IMatchCenterDataFail extends Action {
  type: MatchCenterActionTypes.MATCHCENTER_DATA_FAIL,
}

export type MatchCenterActions =
  | IMatchCenterDataRequest
  | IMatchCenterDataSuccess
  | IMatchCenterDataFail;
