import React from 'react';
import bn from '../../utils/bemnames';

const bem = bn.create('side-menu');

interface IDispatchProps {
  menuOpen: boolean;
  toggleMenu?: () => void;
}

const sidebarBackground = {
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

class SideMenu extends React.Component<IDispatchProps> {
  render() {
    return (
      <aside className={this.props.menuOpen ? bem.b(bem.m('open')) : bem.b()}>
        {this.props.menuOpen ?
          <div className={bem.e('background')} style={sidebarBackground} onClick={this.props.toggleMenu} />
          : null}

        <div className={bem.e('content')}>
          {this.props.children}
        </div>
      </aside>
    );
  }
}

export default SideMenu;
