import React from 'react';
import { Sports } from '../config/Constants';
import SportIcons from '../assets/img/sports';
import { i18n } from '../locales';

interface IProps {
  sport: Sports;
  className?: string;
  title?: string;
}

const SportIcon: React.FC<IProps> = ({ sport, ...rest }: IProps) => {
  let icon = '';
  let sportName = Sports[sport];
  if (SportIcons[sportName]) {
    icon = SportIcons[sportName];
  } else {
    icon = SportIcons.Olympics;
  }
  return (
    <img
      {...rest}
      src={icon}
      alt={i18n._(sportName)}
    />
  )
}

export default SportIcon;