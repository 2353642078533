import React from 'react';

import { I18nProvider } from '@lingui/react';
import { i18n } from '../../src/locales';
import { IClientSettingsState, ILanguageSet } from '../store/clientSettings/types';
import { connect } from 'react-redux';
import { IApplicationState } from '../store/rootReducer';
import { setLanguage } from '../store/clientSettings/actions';
import AppConfig from '../config/AppConfig';
import { setDefaultLocale } from  'react-datepicker';

interface IProps {
  clientSettings: IClientSettingsState;
  setLanguage: typeof setLanguage;
}

class LocaleProvider extends React.Component<IProps & any> {

  componentDidMount = () => {
    let userLocale = localStorage.getItem('userLocale');
    if (userLocale) {
      i18n.activate(userLocale);
      setDefaultLocale(userLocale);
      this.props.setLanguage(userLocale);
    } else {
      userLocale = AppConfig.defaultLanguage;
      localStorage.setItem('userLocale', userLocale);
    }
  }


  render() {
    return (
      <I18nProvider i18n={i18n} language={this.props.clientSettings.locale}>
        {this.props.children}
      </I18nProvider>
    )
  }
}

const mapStateToProps = (state: IApplicationState) => {
  const { clientSettings } = state;
  return {
    clientSettings,
  };
};

const mapDispatchToProps = (dispatch: (arg0: ILanguageSet) => any) => {
  return {
    setLanguage: (locale: string) => dispatch(setLanguage(locale)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocaleProvider);

