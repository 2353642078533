import { Reducer } from 'redux';
import { IUserState, UserActions, UserActionTypes as actions } from './types';
import { ClientSettingsActions, ClientSettingsActionTypes } from '../clientSettings/types';

const initialState: IUserState = {
  userPermissions: [],
  userLoading: true,
  userFail: false,
  isLoggedIn: false,
  userData: undefined,
  registerSuccess: false,
};

const userReducer: Reducer<IUserState> = (
  state: IUserState = initialState,
  action
) => {
  switch ((action as UserActions | ClientSettingsActions).type) {
    case actions.USER_REQUEST_START:
      return {
        ...state,
        userLoading: true,
        userFail: false,
        isLoggedIn: false,
      };
    case actions.USER_REQUEST_SUCCESS:
      return {
        ...state,
        userLoading: false,
        userFail: false,
        isLoggedIn: true,
        userData: action.payload.userData,
        userPermissions: action.payload.userPermissions,
      };
    case actions.USER_REQUEST_FAIL:
      return {
        ...state,
        userFail: true,
        isLoggedIn: false,
        userLoading: false,
      };
    case actions.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        userFail: false,
        isLoggedIn: false,
        userLoading: false,
        userData: undefined,
        userPermissions: [],
      };
    case actions.USER_LOGOUT:
      return {
        ...state,
        userLoading: true,
      };
    case ClientSettingsActionTypes.TOGGLE_LOGIN_MODAL:
      return {
        ...state,
        registerSuccess: false, // we need to hide 'Verification email sent' alert on next modal open
      };
    case actions.USER_REGISTER_SUCCESS:
      return {
        ...state,
        registerSuccess: true,
      };
    case actions.USER_SET_FCM_TOKEN: {
      return {
        ...state,
        userFCMToken: action.payload.token,
      }
    }
    case actions.USER_FCM_TOKEN_SAVED: {
      return {
        ...state,
        userFCMTokenSaved: action.payload.success,
      }
    }
    case actions.USER_NEWSLETTER_SUBSCRIBED: {
      let { userData } = state;
      if (userData && !userData.newsletter) {
        userData = {
          ...userData,
          newsletter: true,
        };
      }

      return {
        ...state,
        userData,
      }
    }
    case actions.SET_NUM_OF_UNREAD_WEB_NOTIF: {
      const { userData } = state;
      const { num } = action.payload;
      if (userData && num !== userData.numOfUnreadWebNotif) {
        return {
          ...state,
          userData: {
            ...userData,
            numOfUnreadWebNotif: num,
          },
        };
      }

      return state;
    }
    default:
      return state;
  }
};

export default userReducer;
