import { Reducer } from 'redux';
import { ClientSettingsActions, ClientSettingsActionTypes as actions, IClientSettingsState } from './types';
import AppConfig from '../../config/AppConfig';
import Constants from '../../config/Constants';
import { getTimeZone } from '../../utils/dateTime';

const initialState: IClientSettingsState = {
  languageId: null,
  countryId: null,
  locale: AppConfig.defaultLanguage,
  timeZone: getTimeZone(),
  oddsFormat: localStorage.getItem('oddsFormat') || Constants.ODDS_FORMAT_DECIMAL,
  footerItems: [],
  socialLinks: [],
  languages: [],
  gamblingAddictionLink: null,
  footerItemsFail: false,
  socialLinksFail: false,
  countriesData: [],
  modalOpen: false,
  loginModalOpen: false,
  burgerMenuOpen: false,
  userMenuOpen: false,
  searchMenuOpen: false,
  isNativeApp: false,
  nativeCommandResponceData: null,
  bannerReloads: 0,
  bannerSource: null,
};

const clientSettingsReducer: Reducer<IClientSettingsState> = (
  state: IClientSettingsState = initialState,
  action
) => {
  switch ((action as ClientSettingsActions).type) {
    case actions.LANGUAGE_SET:
      return {
        ...state,
        locale: action.payload.locale,
      };
    case actions.MASTER_DATA_REQUEST:
      return {
        ...state,
      };
    case actions.MASTER_DATA_SUCCESS:
      return {
        ...state,
        languageId: action.payload.languageId,
        countryId: action.payload.countryId,
        footerItems: action.payload.footerItems,
        socialLinks: action.payload.socialLinks,
        languages: action.payload.languages,
        gamblingAddictionLink: action.payload.gamblingAddictionLink
      };
    case actions.MASTER_DATA_FAIL:
      return {
        ...state,
        footerItemsFail: true,
        socialLinksFail: true
      };
    case actions.COUNTRIES_DATA_REQUEST:
      return {
        ...state,
      };
    case actions.COUNTRIES_DATA_SUCCESS:
      return {
        ...state,
        countriesData: action.payload.countriesData,
      };
    case actions.TOGGLE_LOGIN_MODAL:
      return {
        ...state,
        loginModalOpen: !state.loginModalOpen,
        modalOpen: !state.loginModalOpen,
        burgerMenuOpen: false,
        userMenuOpen: false,
        searchMenuOpen: false,
      };
    case actions.CLOSE_MENUS:
      return {
        ...state,
        loginModalOpen: false,
        userMenuOpen: false,
        searchMenuOpen: false,
        burgerMenuOpen: false,
        modalOpen: false,
      };
    case actions.SET_TIME_ZONE:
      return {
        ...state,
        timeZone: action.payload.timeZone,
      };
    case actions.SET_ODDS_FORMAT:
      return {
        ...state,
        oddsFormat: action.payload.oddsFormat,
      };
    case actions.TOGGLE_BURGER_MENU:
      return {
        ...state,
        modalOpen: !state.burgerMenuOpen,
        burgerMenuOpen: !state.burgerMenuOpen,
        loginModalOpen: false,
        userMenuOpen: false,
        searchMenuOpen: false,
      };
    case actions.TOGGLE_USER_MENU:
      return {
        ...state,
        modalOpen: !state.userMenuOpen,
        burgerMenuOpen: false,
        searchMenuOpen: false,
        loginModalOpen: false,
        userMenuOpen: !state.userMenuOpen,
      };
    case actions.TOGGLE_SEARCH_MENU:
      return {
        ...state,
        modalOpen: !state.searchMenuOpen,
        burgerMenuOpen: false,
        loginModalOpen: false,
        userMenuOpen: false,
        searchMenuOpen: !state.searchMenuOpen,
      }
    case actions.SET_IS_NATIVE_APP:
      return {
        ...state,
        isNativeApp: true,
      };
    case actions.NATIVE_COMMAND:
      return {
        ...state,
        nativeCommandResponceData: action.payload.responce,
      };
    case actions.RELOAD_BANNER: {
      const { operator } = action.payload;
      const bannerSource = (operator && operator.active) ? operator.slug : null;

      return {
        ...state,
        bannerReloads: state.bannerReloads + 1,
        bannerSource,
      };
    }
    default:
      return state;
  }
};

export default clientSettingsReducer;
