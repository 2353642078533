import React from 'react';
import { i18n } from '../locales';
import ReactHtmlParser, { processNodes } from 'react-html-parser';
import { IPostImage, IThread, ThreadEntityType, IArticle, IPick, IEvent } from './types';
import { getArticleUrl } from './article';
import { getPickUrl, getPickImageSrc } from '../utils/pick';
import { getEventUrl } from '../utils/event';
import { getImageSrc, ImageSize } from "./image";
import Quote from '../components/discussions/Quote';

function transformCommentText(node, index) {
  if (node.type === 'tag') {
    switch (node.name) {
      case 'x-img': {
        const postImage = {
          id: parseInt(node.attribs['data-id']),
          ext: node.attribs['data-ext'],
          width: parseInt(node.attribs['data-width']),
          height: parseInt(node.attribs['data-height']),
        };

        return (
          <div key={index} className="mb-3">
            <a href={getPostImageSrc(postImage, true)} target="_blank" rel="noopener noreferrer">
              {node.parent ? (
                i18n._('Image')
              ) : (
                <img src={getPostImageSrc(postImage)} alt={`${postImage.id}`} />
              )}
            </a>
          </div>
        );
      }

      case 'quote':
        return (
          <Quote
            key={index}
            username={node.attribs['data-username']}
            n={parseInt(node.attribs['data-n'])}
          >
            {processNodes(node.children, transformCommentText)}
          </Quote>
        );
    }
  }
}

export function parseCommentText(html: any) : any {
  return ReactHtmlParser(
    html,
    {
      transform: transformCommentText,
    }
  );
}

export function getPostImageSrc(postImage: IPostImage, forceFullSize?: boolean) {
  return '/uploads/post-images/' + postImage.id + (!forceFullSize && postImage.width > 300 ? '_thumbnail' : '') +
    '.' + postImage.ext;
}

export function getThreadEntityUrl(thread: IThread) : string {
  const { entity } = thread;
  if (entity) {
    switch (thread.entityType) {
      case ThreadEntityType.ARTICLE: {
        const article: IArticle = entity;
        return getArticleUrl(article.slug);
      }

      case ThreadEntityType.PICK: {
        const pick: IPick = entity;
        return getPickUrl(pick.pickData.slug);
      }

      case ThreadEntityType.EVENT: {
        const event: IEvent = entity;
        return getEventUrl(event.tournamentStage.tournament.competition.sport.trSlug, event.trSlug, event.id) + '#discussions';
      }
    }
  }

  return '';
}

export function getThreadEntityImageSrc(thread: IThread) : string {
  const { entity } = thread;
  if (entity) {
    switch (thread.entityType) {
      case ThreadEntityType.ARTICLE: {
        const article: IArticle = entity;
        return getImageSrc(article.image, ImageSize.Thumbnail);
      }

      case ThreadEntityType.PICK: {
        const pick: IPick = entity;
        const { imageHash } = pick;
        return imageHash ? getPickImageSrc(imageHash) : null;
      }
    }
  }

  return '';
}

export function getThreadEntityTitle(thread: IThread) : string {
  const { entity } = thread;
  if (entity) {
    switch (thread.entityType) {
      case ThreadEntityType.ARTICLE: {
        const article: IArticle = entity;
        return article.title;
      }

      case ThreadEntityType.PICK: {
        const pick: IPick = entity;
        return pick.pickData.title;
      }

      case ThreadEntityType.EVENT: {
        const event: IEvent = entity;
        return event.trName;
      }
    }
  }

  return '';
}
