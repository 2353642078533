import * as React from 'react';

const query = ({ width }: { width: number }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

interface IState {
  width: number,
  height: number,
  breakpoint: string,
}

const withScreenSizeListener = (WrappedComponent: any) =>
  class extends React.Component<{}, IState> {
    public readonly state: IState = {
      width: window.innerWidth,
      height: window.innerHeight,
      breakpoint: query({ width: window.innerWidth }).breakpoint,
    };
    constructor(props: Readonly<{}>) {
      super(props);
      this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
      let width = window.innerWidth;
      let height = window.innerHeight;
      let breakpoint = query({ width }).breakpoint;
      this.setState({ width, height, breakpoint });
    }

    render() {
      return <WrappedComponent breakpoint={this.state.breakpoint} {...this.props} />;
    }
  };

export default withScreenSizeListener;